import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import Recaptcha from "../../components/Recaptcha/Recaptchav2";

import Header from "../../views_components/Component/Header";
import { view_collection } from "../../stores/Collections/view";
import { view_user } from "../../stores/Users/view";

export default function Contents(Contact) {
  const { propsContact, set_propsContact, dispatch_post_contact } = Contact;
  const props = propsContact;
  const set_props = set_propsContact;

  // const [recaptcha, set_recaptcha] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();
  const { user_id } = useParams();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_users = useSelector((state) => state.reduce_get_users);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  // const collection = useSelector((state) => state.reduce_get_items.collection);
  const collection = useSelector((state) => state.reduce_get_collections.collection);
  const v_collection = view_collection(collection);

  const user = reduce_get_users.user;
  const v_user = view_user(user);

  return (
    <>
      <section className="py-5 bg-white">
        <div className="container py-sm-4">
          <div className="mb-5 mb-sm-3 pb-sm-1">
            <h3 className="h1 text-center">{t("スペシャリストに相談")}</h3>
            <div className="d-grid col-sm-10 col-lg-8 col-xl-6 mx-auto">
              <div className="position-relative m-4 mb-0">
                <div
                  className="progress"
                  style={{
                    height: "1px",
                  }}
                >
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{
                      width: "100%",
                    }}
                    aria-valuenow={100}
                    aria-valuemin={0}
                    aria-valuemax={100}
                  />
                </div>
                <span
                  className="position-absolute top-0 start-0 translate-middle rounded-pill btn btn-sm btn-primary"
                  style={{
                    cursor: "unset",
                  }}
                >
                  {t("入力")}
                </span>
                <span
                  className="position-absolute top-0 start-50 translate-middle rounded-pill btn btn-sm btn-primary"
                  style={{
                    cursor: "unset",
                  }}
                >
                  {t("確認")}
                </span>
                <span
                  className="position-absolute top-0 start-100 translate-middle rounded-pill btn btn-sm btn-light text-secondary"
                  style={{
                    cursor: "unset",
                  }}
                >
                  <i className="fa-solid fa-paper-plane" />
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* <aside className="bg-light">
          <div className="container py-4">
            <div className="d-grid col-sm-10 col-lg-8 col-xl-6 mx-auto">
              <div className="list-group list-group-flush">
                <h4 className="list-group-item h5 pb-3 mb-0">
                  “ブロックチェーン”で
                  <br />
                  よく見られているトピックは参考になりませんか？
                </h4>
                <a className="list-group-item" href="#">
                  ソラナとは何ですか？
                </a>
                <a className="list-group-item" href="#">
                  ソラナを使って購入するにはどうすればよいですか？
                </a>
                <a className="list-group-item" href="#">
                  SOLを購入するにはどうすればよいですか?
                </a>
                <a className="list-group-item" href="#">
                  tomonityでソラナコレクションを入手する:メタプレックス認定コレクションの基準を理解する
                </a>
                <a className="list-group-item" href="#">
                  ソラナで資金を見つけるにはどうすればよいですか?
                </a>
              </div>
            </div>
          </div>
        </aside> */}
        <div className="container pb-5">
          <div action="contact-form-success.html">
            <div className="d-grid col-sm-10 col-lg-8 col-xl-6 my-4 mx-auto">
              <div className="row g-3">
                <div className="col-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      id="inputNameLast"
                      className="form-control"
                      placeholder="\u7530\u4E2D"
                      readOnly
                      defaultValue=""
                      value={props.last_name}
                    />
                    <label htmlFor="inputNameLast">{t("姓")}</label>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-floating mb-3">
                    <input
                      type="text"
                      id="inputNameFirst"
                      className="form-control"
                      placeholder="\u592A\u90CE"
                      readOnly
                      defaultValue=""
                      value={props.first_name}
                    />
                    <label htmlFor="inputNameFirst">{t("名")}</label>
                  </div>
                </div>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="email"
                  id="inputEmail"
                  className="form-control"
                  placeholder="name@example.com"
                  readOnly
                  defaultValue=""
                  value={props.mail_address}
                />
                <label htmlFor="inputEmail">{t("メールアドレス")}</label>
              </div>
              <div className="form-floating mb-3">
                <select id="inputCountry" className="form-select" disabled value={props.country}>
                  <option>{t("選択してください")}</option>
                  <option selected>{t("日本")}</option>
                </select>
                <label htmlFor="inputCountry">{t("ご利用している国")}</label>
              </div>
              <div className="form-floating mb-3">
                <select id="inputDevice" className="form-select" disabled value={props.device}>
                  <option>{t("選択してください")}</option>
                  <option selected>PC</option>
                  <option>Mobile</option>
                </select>
                <label htmlFor="inputDevice">{t("ご利用端末")}</label>
              </div>
              <div className="row g-3">
                <div className="col-sm">
                  <div className="form-floating mb-3">
                    <select id="inputOS" className="form-select" disabled value={props.os}>
                      <option>{t("選択してください")}</option>
                      <option>Windows</option>
                      <option selected>Apple macOS</option>
                      <option>Ubuntu</option>
                      <option>Fedora</option>
                      <option>Solaris</option>
                      <option>Free BSD</option>
                      <option>Chrome OS</option>
                      <option>CentOS</option>
                      <option>Debian</option>
                      <option>Deepin</option>
                      <option>その他</option>
                    </select>
                    <label htmlFor="inputOS">OS</label>
                  </div>
                </div>
                <div className="col-sm">
                  <div className="form-floating mb-5">
                    <select
                      id="inputWebbrowser"
                      className="form-select"
                      disabled
                      value={props.browser}
                    >
                      <option>{t("選択してください")}</option>
                      <option selected>Google Chrome</option>
                      <option>Internet Explorer</option>
                      <option>Microsoft Edge</option>
                      <option>Safari</option>
                      <option>Mozilla Firefox</option>
                      <option>{t("その他")}</option>
                    </select>
                    <label htmlFor="inputWebbrowser">{t("ブラウザ")}</label>
                  </div>
                </div>
              </div>
              <div className="form-floating mb-3">
                <select className="form-select" id="inputFeedback" disabled value={props.category}>
                  <option>{t("選択してください")}</option>
                  <optgroup label="\u30D7\u30E9\u30C3\u30C8\u30D5\u30A9\u30FC\u30E0">
                    <option selected>{t("ブロックチェーン")}</option>
                    <option>{t("ウォレット")}</option>
                    <option>{t("サーバー")}</option>
                    <option>{t("セキュリティ")}</option>
                    <option>{t("サービス手数料")}</option>
                    <option>{t("アカウント")}</option>
                  </optgroup>
                  <optgroup label="\u30D1\u30FC\u30C8\u30CA\u30FC\u30D7\u30ED\u30B0\u30E9\u30E0">
                    <option>Value</option>
                    <option>Value</option>
                  </optgroup>
                  <optgroup label="\u30C7\u30D9\u30ED\u30C3\u30D1\u30FC">
                    <option>Value</option>
                    <option>Value</option>
                  </optgroup>
                </select>
                <label htmlFor="inputFeedback">{t("フィードバックの種類")}</label>
              </div>
              <div className="form-floating mb-5">
                <textarea
                  id="inputMessage"
                  className="form-control"
                  placeholder="\u5177\u4F53\u7684\u306B\u4E14\u3064\u7406\u89E3\u3057\u3084\u3059\u3044\u5185\u5BB9\u3067\u304A\u9858\u3044\u3057\u307E\u3059\u3002"
                  style={{
                    height: "300px",
                  }}
                  readOnly
                  defaultValue={""}
                  value={props.message}
                />
                <label htmlFor="inputMessage">{t("ご相談内容")}</label>
              </div>
              <div className="form-check mb-3">
                <div className="d-flex">
                  <input
                    type="checkbox"
                    id="inputConsent"
                    className="form-check-input flex-shrink-0 me-2"
                    required
                    disabled
                    defaultChecked
                    checked={props.consent}
                  />
                  <label className="form-check-label" htmlFor="inputConsent">
                    {t("tomonityの「")}
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(`https://help.tmty.io/tos/`, "_blank", "noreferrer");
                      }}
                    >
                      {t("利用規約")}
                    </a>
                    {t("」と「")}
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(`https://help.tmty.io/tos/`, "_blank", "noreferrer");
                      }}
                    >
                      {t("プライバシーポリシー")}
                    </a>
                    {t(" 」に同意しますか？")}
                  </label>
                </div>
              </div>
            </div>
            <div className="text-center mt-4">
              <Recaptcha
                set_recaptcha={(rec) => {
                  set_propsContact({
                    ...propsContact,
                    recaptcha: 1,
                  });
                }}
                action="login"
              />
            </div>
            <div className="d-grid col-sm-10 col-lg-8 col-xl-6 my-4 mx-auto d-flex justify-content-between align-items-center">
              <a
                className="btn btn-outline-secondary rounded-pill px-4 btn-lg d-flex align-items-center"
                href="#"
                onClick={() => {
                  set_propsContact({
                    ...propsContact,
                    type: 1,
                  });
                }}
              >
                <i className="fa-solid fa-angle-left me-3" />
                {t("キャンセル")}
              </a>
              <button
                type="button"
                className="btn btn-primary rounded-pill px-4 btn-lg d-flex align-items-center"
                onClick={() => {
                  dispatch_post_contact(
                    {
                      ...propsContact,
                      full_name: propsContact.last_name + propsContact.first_name,
                      // user_name,
                      // phone_number,
                      // message,
                    },
                    () => {
                      set_propsContact({
                        ...propsContact,
                        type: 3,
                      });
                      // window.scroll(0, 0);
                    }
                  );
                }}
              >
                {t("この内容で送信")}
                <i className="fa-solid fa-angle-right ms-3" />
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
