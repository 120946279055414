import React, { useState, useEffect, Component } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { toMomentString } from "config/util";
import { getMessage } from "config/util";

import { view_top_collection } from "../../stores/Top/view";
import { put_collections_id_favorite } from "stores/Collections/actions";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";

export default function Dashboard(TopCollections) {
  const { propsTopCollections } = TopCollections;

  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();
  const maps = useSelector((state) => state.reduce_get_maps);

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const datalist1 = useSelector((state) => state.reduce_get_top_collections.collections);
  const datalist2 = useSelector((state) => state.reduce_get_top_collections.collections2);

  const datalist = propsTopCollections?.search?.priority == "sum" ? datalist1 : datalist2;

  const loading_collections = useSelector((state) => state.reduce_get_collections.isLoading);
  const loadingId = useSelector((state) => state.reduce_get_collections.isLoadingId);

  let loading = loading_collections;

  if (loading)
    return (
      <>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-2 g-xl-5 mb-3">
          <CustomLinearProgress color="primary" />
        </div>
      </>
    );
  if (!datalist) return <></>;
  if (datalist.length == 0) return <></>;

  const List = ({ v_collection, idx }) => {
    return (
      <>
        {/*       
collection_id:'5245fa3c-0e6c-46c1-b0f8-ab2b4130c5d6'
collection_name:'わんわんコレクション'
distinct_user:2
min_price_usd:'0.000000000000'
sum_amount:'4.000000000000'
sum_price:'0.800000000000'
sum_price_usd:'0.000000000000' */}

        <dl className="row g-2 g-md-3 py-3 mb-1 align-items-md-center border-bottom list-group-item-action position-relative text-body">
          <dd className="col overflow-hidden">
            <div className="row g-2 align-items-center">
              <div className="col-2 col-md-1 text-end">{idx}</div>
              <div className="col media overflow-hidden">
                <div className="media-object">
                  {/* <div className="object-item icon icon-square verified"> */}
                  <div className="object-item icon icon-square">
                    <img
                      src={v_collection.collection_icon_uri}
                      className="icon-item"
                      width="100%"
                      height="auto"
                      alt=""
                    />
                  </div>
                </div>
                <div className="media-body overflow-hidden">
                  <h3 className="media-title fw-normal">
                    <a
                      className="stretched-link"
                      title=""
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        // if (type == "collections") {
                        //   dispatch_type("collection-items", v_collection.collection_id);
                        // } else {
                        history.push(`/collections/${v_collection.collection_id}/items`);
                        // }
                      }}
                    >
                      {/* <div className="d-flex verified-text"> */}
                      <span className="text-truncate">{v_collection.collection_name}</span>
                      {/* </div> */}
                    </a>
                    <span className="text-truncate">@{v_collection.collection_id}</span>
                  </h3>
                </div>
              </div>
            </div>
          </dd>
          <dd className="col-6 col-md">
            <dl className="row g-0">
              <dd className="col-12 col-md-4 text-end mb-1 mb-md-0 fw-bold">
                <sub>$</sub>
                {v_collection.sum_price_usd_label}
              </dd>
              <dd className="col-6 col-md-4 text-end">
                <span className={v_collection.percent_sign ? "text-success" : "text-danger"}>
                  {v_collection.percent_sign ? <sub>+</sub> : <sub>−</sub>}
                  {v_collection.percent_label}%
                </span>
              </dd>
              {/* <dd className="col-6 col-md-4 text-end">
                <span className="text-danger">
                  <sub>−</sub>41.20%
                </span>
              </dd> */}
              <dd className="col-6 col-md-4 text-end">
                <span className="text-danger">
                  <sub></sub>
                </span>
              </dd>
            </dl>
          </dd>
          <dd className="col-12 col-md">
            <dl className="row g-0 justify-content-end">
              <dd className="col-3 col-md-4 text-end">
                <sub>$</sub>
                {v_collection.min_price_usd_label}
              </dd>
              <dd className="col-3 col-md-4 text-end">{v_collection.distinct_user}</dd>
              <dd className="col-3 col-md-4 text-end">{Number(v_collection.sum_amount)}</dd>
            </dl>
          </dd>
        </dl>
      </>
    );
  };

  return (
    <>
      <dl className="row g-2 g-md-3 border-bottom border-dark border-2 py-3 pb-md-2 mb-1">
        <dt className="col">
          <button className="btn w-100 fw-bold px-0 text-start btn-white">{t("コレクション")}</button>
        </dt>
        <dt className="col-6 col-md">
          <dl className="row g-0">
            <dt className="col-12 col-md-4">
              <button className="btn w-100 fw-bold px-0 text-end btn-link">
                {t("取引量")}
                <i className="fa-solid fa-caret-down ms-2" />
              </button>
            </dt>
            <dt className="col-6 col-md-4 text-end">
              <button className="btn w-100 fw-bold px-0 text-end btn-white">{t("24時間%")}</button>
            </dt>
            <dt className="col-6 col-md-4 text-end">
              {/* <button className="btn w-100 fw-bold px-0 text-end btn-white">7日間%</button> */}
              <button className="btn w-100 fw-bold px-0 text-end btn-white"></button>
            </dt>
          </dl>
        </dt>
        <dt className="col-12 col-md">
          <dl className="row g-0 justify-content-end">
            <dt className="col-3 col-md-4 text-end">
              <button className="btn w-100 fw-bold px-0 text-end btn-white">{t("最低価格")}</button>
            </dt>
            <dt className="col-3 col-md-4 text-end">
              <button className="btn w-100 fw-bold px-0 text-end btn-white">{t("取引者数")}</button>
            </dt>
            <dt className="col-3 col-md-4 text-end">
              <button className="btn w-100 fw-bold px-0 text-end btn-white">{t("取引数")}</button>
            </dt>
          </dl>
        </dt>
      </dl>
      {datalist.map((c, idx) => {
        const v_collection = view_top_collection(c, maps, t);
        return <List v_collection={v_collection} idx={idx + 1} />;
      })}
    </>
  );
}
