import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

// import { view_user } from "../../stores/Users/view";
import { view_user } from "../../stores/Accounts/view";
import { view_wallets } from "../../stores/Wallets/view";
import { toNumber } from "config/util";

export default function Contents({ myhome, suffix }) {
  const dispatch = useDispatch();
  const history2 = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_wallets = useSelector((state) => state.reduce_get_wallets);
  const maps = useSelector((state) => state.reduce_get_maps);

  const user = reduce_get_accounts.user;
  const v_user = view_user(user);

  const wallets = reduce_get_wallets?.wallets;
  const v_wallets = view_wallets(wallets, maps, t);

  useEffect(() => {
    window.Zeynep1();
    window.Zeynep2();
  }, []);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts?.user?.mail_address;

  const history = {
    push: (arg) => {
      if (!myhome) {
        const accountsNav = document.getElementById("accountsNav");
        const modal = window.bootstrap.Offcanvas.getInstance(accountsNav);
        modal.hide();
      }

      history2.push(arg);
    },
  };

  return (
    <>
      <div className="zeynep opened zeynep-light" data-menu-name="navbar">
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link"
              href="javascript:void(0)"
              role="button"
              data-bs-toggle="modal"
              data-bs-target="#signOut"
            >
              {t("ログアウト")}
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}

// <li className="nav-item has-submenu">
// <a
//   className="nav-link"
//   href="javascript:void(0)"
//   data-submenu={"profile" + suffix}
// >
//   プロフィール変更
// </a>
// <div id={"profile" + suffix} className="submenu">
//   <div className="submenu-header">
//     <a href="javascript:void(0)" data-submenu-close={"profile" + suffix}>
//       プロフィール変更
//     </a>
//   </div>
//   <ul className="navbar-nav">
//     {/* <li className="nav-item">
//       <a
//         className="nav-link"
//         href="#"
//         onClick={(e) => {
//           e.preventDefault();
//           history.push({
//             pathname: `/account-extra`,
//           });
//         }}
//       >
//         ニックネームとプロフィール
//       </a>
//     </li>
//     <li className="nav-item">
//       <a
//         className="nav-link"
//         href="#"
//         onClick={(e) => {
//           e.preventDefault();
//           history.push({
//             pathname: `/account-image`,
//           });
//         }}
//       >
//         プロフィール変更
//       </a>
//     </li>
//     <li className="nav-item">
//       <a
//         className="nav-link"
//         href="#"
//         onClick={(e) => {
//           e.preventDefault();
//           history.push({
//             pathname: `/account-kyc`,
//           });
//         }}
//       >
//         KYC申請
//       </a>
//     </li> */}
//     {/* <li className="nav-item">
//       <a
//         className="nav-link"
//         href="#"
//         onClick={(e) => {
//           e.preventDefault();
//           history.push({
//             pathname: `/biolinks`,
//           });
//         }}
//       >
//         ソーシャルリンク
//       </a>
//     </li> */}
//   </ul>
// </div>
// </li>
