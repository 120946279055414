import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import MenuAdmin from "./MenuAdmin";
import Logout from "./Logout";
import { view_user } from "../../stores/Users/view";
import LiLanguage from "components/Li/LiLanguage";
import _nav_global_admin from "./_nav_global_admin";
import _search from "./_search";

import { home, login } from "../../Navigator/Router_material";

export default function Contents(Page) {
  const { type, dispatch_type, word, set_word } = Page ?? {};
  // const [inp, set_inp] = React.useState("");
  const ref_search = useRef();

  // const inp = sessionStorage.getItem("SearchWord");

  const history = useHistory();

  const location = useLocation();
  const focus = location.focus;

  const ref_id = useRef();

  if (focus) {
    if (ref_id) {
      if (ref_id.current) {
        ref_id.current.focus();
      }
    }
  }

  const dispatch = useDispatch();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  const user = reduce_get_accounts.user;
  const v_user = view_user(user);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts?.user?.mail_address;
  const user_name = reduce_get_accounts?.user?.username;
  const display_name = reduce_get_accounts?.user?.display_name;
  return (
    <>
      <header className="navbar navbar-light navbar-expand-md align-items-center sticky-top">
        <div className="container">
          <button
            type="button"
            className="navbar-toggler me-2"
            data-bs-toggle="offcanvas"
            data-bs-target="#globalNav"
            aria-controls="globalNav"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <h1 className="navbar-brand">
            <a
              className="tmty-brand"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/admin`,
                });
              }}
            >
              <img
                src="/dist/tmty/assets/brand/tomonity-typography.webp"
                width="auto"
                height="auto"
                alt="tomonity"
              />
            </a>
          </h1>
          <div className="flex-grow-1 px-3 d-none d-sm-block" action=""></div>
          {_nav_global_admin()}
          {!token && (
            <div className="nav-item dropdown d-none d-md-block">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarSignin"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fa-solid fa-circle-user lead" />
              </a>
              <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarSignin">
                <li>
                  <a
                    className="dropdown-item"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: login,
                      });
                    }}
                  >
                    <i className="fa-solid fa-arrow-right-to-bracket fa-fw me-3" />
                    {t("ログイン")}
                  </a>
                </li>
              </ul>
            </div>
          )}
          {token && (
            <>
              <div
                className="icon icon-pill"
                style={{
                  maxWidth: "110px",
                }}
              >
                <a
                  className="text-body d-flex align-items-center"
                  href="javascript:void(0)"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#accountsNav"
                  aria-controls="accountsNav"
                >
                  <img
                    src="/dist/tmty/assets/default/icon.webp"
                    className="icon-item"
                    alt=""
                    style={{
                      width: "30px",
                      height: "30px",
                    }}
                  />
                  <span className="d-none d-lg-block ms-2 text-truncate">Admin</span>
                </a>
              </div>
            </>
          )}
        </div>
      </header>
      <nav
        className="offcanvas offcanvas-end offcanvas-my"
        tabIndex={-1}
        id="accountsNav"
        aria-labelledby="accountsNavLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="accountsNavLabel">
            <a
              className="tmty-brand tmty-brand-full d-md-none"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: home,
                });
              }}
            >
              <img
                src="/dist/tmty/assets/brand/tomonity-typography.webp"
                width="auto"
                height="auto"
                alt="tomonity"
              />
            </a>
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body p-0">
          <MenuAdmin myhome={false} suffix={"-h"} />
        </div>
      </nav>
      <Logout />
      {_search({ ...Page, ref_search })}
    </>
  );
}
