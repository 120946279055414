import React, { FC, useEffect, useRef, useState, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { getMessage, toMomentString, toggleAry } from "config/util";
import { Type } from "./_type";

export const HeaderUser = (Activities) => {
  const t = Activities.t;

  return (
    <>
      <dl className="row g-1 g-md-2 border-bottom border-dark border-2 py-3 mb-1 d-none d-md-flex">
        <dt className="col-7 col-md-2">
          <span className="d-block fw-bold small-xs-only">{t("イベント")}</span>
        </dt>
        <dt className="col-5 col-md-2 pe-lg-3 text-end order-md-last">
          <span className="d-block fw-bold small-xs-only">{t("日時")}</span>
        </dt>
        <dt className="col-7 col-md ps-xl-4">
          <span className="d-block fw-bold small-xs-only">{t("アイテム")}</span>
        </dt>
        <dt className="col col-md-2 text-end pe-md-3">
          <span className="d-block fw-bold small-xs-only">{t("価格")}</span>
        </dt>
        <dt className="col-12 col-md-3 ps-3 ps-md-0">
          <dl className="row g-1 g-md-2 g-xl-3 mb-0 align-items-center">
            <dt className="mb-0 col offset-2 col-md offset-md-0">
              <span className="d-block fw-bold small-xs-only">{t("どこから")}</span>
            </dt>
            <dd className="mb-0 col-auto d-md-none">
              <i className="fa-solid fa-right-long small me-1" />
            </dd>
            <dt className="mb-0 col col-md">
              <span className="d-block fw-bold small-xs-only">{t("どこへ")}</span>
            </dt>
          </dl>
        </dt>
      </dl>
    </>
  );
};

export const RowUser = (Activities) => {
  const { v_activity, history, t } = Activities;

  return (
    <>
      <dl className="row g-1 g-md-2 border-bottom py-3 mb-1 align-items-md-center">
        <dd
          className="col-7 col-md-2 mb-2 mb-md-0 text-truncate"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title="\u30AA\u30D5\u30A1\u30FC\u30AD\u30E3\u30F3\u30BB\u30EB"
        >
          <Type type={v_activity.activity_type} name={v_activity.activity_name} />
        </dd>
        <dd className="col-5 col-md-2 pe-lg-3 text-end order-md-last">
          {v_activity.explorer_uri && (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                window.open(`${v_activity.explorer_uri}`, "_blank", "noreferrer");
              }}
            >
              {v_activity.created_at_countdown_label}
              {v_activity.explorer_uri && (
                <i className="fa-solid fa-arrow-up-right-from-square small ms-1" />
              )}
            </a>
          )}
          {!v_activity.explorer_uri && <>{v_activity.created_at_countdown_label}</>}
        </dd>
        <dd className="mb-0 col-7 col-md ps-xl-4 overflow-hidden">
          <div className="media mb-3 mb-md-0">
            <div className="media-object object-item position-relative">
              <a
                className="stretched-link"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  // history.push(`/items/${v_activity.item_id}`);
                  history.push(`${v_activity.activity_link}`);
                }}
              >
                <img
                  src={v_activity.activity_icon_uri}
                  className="rounded-1"
                  width="100%"
                  height="auto"
                  alt="Wiener Dog Animation GIF"
                />
              </a>
            </div>
            <div className="media-body overflow-hidden" style={{ maxWidth: 230 }}>
              <a
                className="d-block my-1 small-xs-only text-body text-truncate"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/collections/${v_activity.collection_id}/items`);
                }}
              >
                {v_activity.collection_name}
              </a>
              <a
                className="d-block text-truncate"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/items/${v_activity.item_id}`);
                }}
              >
                <span className="fw-bold">{v_activity.item_name}</span>
              </a>
            </div>
          </div>
        </dd>
        <dd className="col col-md-2 text-end pe-md-3">
          {v_activity.activity_price_has && (
            <>
              <span data-bs-toggle="tooltip" data-bs-placement="top" title={v_activity.coin_label}>
                <sub className="me-1">{v_activity.coin_icon_tag}</sub>
                {v_activity.activity_price_label}
              </span>
              <small className="d-block ps-2 ps-sm-0 ps-md-2">
                <sub>$</sub>
                {v_activity.activity_price_usd_label}
              </small>
            </>
          )}
        </dd>
        <dd className="col-12 col-md-3">
          <dl className="row g-1 g-md-2 g-xl-3 mb-0 align-items-center">
            <dd className="mb-0 col-6 offset-2 col-md offset-md-0 text-end text-md-start text-truncate overflow-hidden">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/users/${v_activity.user_id_by}`);
                }}
              >
                {v_activity.user_name_by}
              </a>
            </dd>
            <dd className="mb-0 col-auto d-md-none">
              <i className="fa-solid fa-right-long small mx-1" />
            </dd>
            <dd className="mb-0 col col-md text-truncate overflow-hidden">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/users/${v_activity.user_id}`);
                }}
              >
                {v_activity.user_name}
              </a>
            </dd>
          </dl>
        </dd>
      </dl>
    </>
  );
};

export const SearchUser = (Activities) => {
  const { propsActivities, set_propsActivities, dispatch_gets } = Activities;
  const history = useHistory();
  const dispatch = useDispatch();

  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };
  const [t, i18n] = useTranslation();
  const count = useSelector((state) => state.reduce_get_activities.count);

  const types = propsActivities.search.type ?? [];
  const platforms = propsActivities.search.platform ?? [];

  const dispatch_gets_type = (val) => {
    const search = {
      ...propsActivities.search,
      type: toggleAry(types, val),
      // type:[val],
    };
    dispatch_gets(search, 1);
  };

  const dispatch_gets_platform = (val) => {
    const search = {
      ...propsActivities.search,
      platform: toggleAry(platforms, val),
      // platform:[val],
    };
    dispatch_gets(search, 1);
  };

  const loading = useSelector((state) => state.reduce_get_activities.isLoading);
  const datalist = useSelector((state) => state.reduce_get_activities.activities);

  return (
    <>
      <div className="btn-toolbar ms-auto" role="toolbar" aria-label="\u691C\u7D22\u6761\u4EF6">
        <div className="dropdown" data-bs-toggle="tooltip" data-bs-placement="top" title="">
          <button
            type="button"
            className="btn btn-outline-secondary dropdown-toggle"
            id="filSort"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa-solid fa-cart-shopping" />
            {types.includes(11) && `${t("発行")}`}
            {types.includes(21) && `${t("出品")}`}
            {types.includes(22) && `${t("入札")}`}
            {types.includes(30) && `${t("転送")}`}
            {types.includes(31) && `${t("販売")}`}
          </button>
          <ul className="dropdown-menu dropdown-menu-end shadow-sm" aria-labelledby="filSort">
            <li>
              <button
                type="button"
                className={`dropdown-item ${types.includes(11) && "active"}`}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch_gets_type(11);
                }}
              >
                {t("発行")}
              </button>
            </li>

            <li>
              <button
                type="button"
                className={`dropdown-item ${types.includes(21) && "active"}`}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch_gets_type(21);
                }}
              >
                {t("出品")}
              </button>
            </li>
            <li>
              <button
                type="button"
                className={`dropdown-item ${types.includes(22) && "active"}`}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch_gets_type(22);
                }}
              >
                {t("入札")}
              </button>
            </li>

            <li>
              <button
                type="button"
                className={`dropdown-item ${types.includes(30) && "active"}`}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch_gets_type(30);
                }}
              >
                {t("転送")}
              </button>
            </li>
            <li>
              <button
                type="button"
                className={`dropdown-item ${types.includes(31) && "active"}`}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch_gets_type(31);
                }}
              >
                {t("販売")}
              </button>
            </li>
          </ul>
        </div>
        {/* <div
          className="dropdown"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          title=""
        >
          <button
            type="button"
            className="btn btn-outline-secondary dropdown-toggle ms-1"
            id="filSort"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa-solid fa-cubes" />
            {platforms.includes(4) && " IOST"}
            {platforms.includes(10) && " Polygon"}
          </button>
          <ul className="dropdown-menu dropdown-menu-end shadow-sm" aria-labelledby="filSort">
            <li>
              <button
                type="button"
                className={`dropdown-item ${platforms.includes(4) && "active"}`}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch_gets_platform(4);
                }}
              >
                IOST
              </button>
            </li>
            <li>
              <button
                type="button"
                className={`dropdown-item ${platforms.includes(10) && "active"}`}
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch_gets_platform(10);
                }}
              >
                Polygon
              </button>
            </li>
          </ul>
        </div> */}
      </div>
    </>
  );
};
