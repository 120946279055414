import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import Header from "../../views_components/Component/Header";
import CustomCircle from "components/CustomCircle/CustomCircle.js";
import { hideModal, showModal } from "config/util";

export default function Contents({ Account }) {
  const [t, i18n] = useTranslation();

  const {
    propsAccount,
    set_propsAccount,
    dispatch_post_accounts_auth_code,
    dispatch_type,
    page,
    branch,
  } = Account;
  const method = branch.method;

  let title = "";
  if (method == "mail") title = t("メールアドレスの変更");
  if (method == "phone") title = t("携帯電話番号の変更");
  if (method == "password") title = t("パスワードの変更");

  const props = propsAccount;
  const set_props = set_propsAccount;
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts.user);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const loading = useSelector((state) => state.reduce_post_accounts_auth_code.isLoading);

  const username_current = reduce_get_accounts.username;
  const mail_address_current = reduce_get_accounts.mail_address;
  const phone_number_current = reduce_get_accounts.phone_number;

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const maps = useSelector((state) => state.reduce_get_maps);

  return (
    <>
      <div>
        <Header />
        <div className="container container-main">
          <main className="main">
            <div action="created-collection-detail.html" entype="multpart/form-data">
              <div className="main-header pt-3 pb-2">
                <h2 className="h4 m-0 text-truncate">{title}</h2>
                {!page && (
                  <>
                    <div className="btn-group">
                      <a
                        className="btn btn-outline-secondary text-dark rounded-pill px-3 me-2 text-nowrap"
                        href="javascript:history.back()"
                      >
                        <i className="fa-solid fa-xmark" />
                        <span className="d-none d-sm-inline-block"> {t("キャンセル")}</span>
                      </a>
                      <button
                        type="button"
                        name="confirm"
                        className="btn btn-primary rounded-pill px-3 text-nowrap"
                        role="button"
                        // data-bs-toggle="modal"
                        // data-bs-target="#processDialog"
                        onClick={(e) => {
                          e.preventDefault();
                          if (method == "user") {
                            dispatch_post_accounts_auth_code(
                              {
                                type: 1,
                                code: props.code,
                              },
                              () => {
                                dispatch_type(method);
                              },
                              () => {}
                            );
                          }
                          if (method == "mail") {
                            dispatch_post_accounts_auth_code(
                              {
                                type: 2,
                                code: props.code,
                              },
                              () => {
                                dispatch_type(method);
                              },
                              () => {}
                            );
                          }
                          if (method == "phone") {
                            dispatch_post_accounts_auth_code(
                              {
                                type: 3,
                                code: props.code,
                              },
                              () => {
                                dispatch_type(method);
                              },
                              () => {}
                            );
                          }
                          if (method == "password") {
                            dispatch_post_accounts_auth_code(
                              {
                                type: 4,
                                code: props.code,
                              },
                              () => {
                                dispatch_type(method);
                              },
                              () => {}
                            );
                          }
                        }}
                      >
                        <CustomCircle title={t("実行")} loading={loading} size={16} />
                      </button>
                    </div>
                  </>
                )}
              </div>
              {!page && (
                <div className="row g-3 p-xl-3">
                  <div className="col-md-6 col-xl-8">
                    <h3 className="h5">{t("送信されたコードを入力してください")}</h3>
                    <div className="row row-cols-1 row-cols-xl-2 g-3">
                      <div className="col">
                        <div className="mb-4">
                          <div className="form-floating mb-2">
                            <input
                              type="text"
                              className="form-control"
                              id="collectionId"
                              placeholder="\u30B3\u30EC\u30AF\u30B7\u30E7\u30F3ID"
                              inputMode="email"
                              onChange={(e) => {
                                const val = e.target.value;
                                set_props({
                                  ...props,
                                  code: val,
                                });
                              }}
                              value={props.code}
                            />
                            <label htmlFor="collectionId">{t("確認コード")}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {page && method == "user" && (
                <div className="row g-3 p-xl-3">
                  <div className="col-md-6 col-xl-8">
                    <h3 className="h5">{t("ユーザーネームが変更されました")}</h3>
                    <div className="row row-cols-1 row-cols-xl-2 g-3">
                      <div className="col">
                        <div className="media mb-2 bg-light p-2">
                          <div className="media-body overflow-hidden">
                            <span className="d-block">{username_current}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {page && method == "mail" && (
                <div className="row g-3 p-xl-3">
                  <div className="col-md-6 col-xl-8">
                    <h3 className="h5">{t("メールアドレスが変更されました")}</h3>
                    <div className="row row-cols-1 row-cols-xl-2 g-3">
                      <div className="col">
                        <div className="media mb-2 bg-light p-2">
                          <div className="media-body overflow-hidden">
                            <span className="d-block">{mail_address_current}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {page && method == "phone" && (
                <div className="row g-3 p-xl-3">
                  <div className="col-md-6 col-xl-8">
                    <h3 className="h5">{t("携帯電話番号が変更されました")}</h3>
                    <div className="row row-cols-1 row-cols-xl-2 g-3">
                      <div className="col">
                        <div className="media mb-2 bg-light p-2">
                          <div className="media-body overflow-hidden">
                            <span className="d-block">{phone_number_current}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {page && method == "password" && (
                <div className="row g-3 p-xl-3">
                  <div className="col-md-6 col-xl-8">
                    <h3 className="h5">{t("パスワードが変更されました")}</h3>
                  </div>
                </div>
              )}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
