import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import { view_item } from "../../stores/Items/view";

export default function Contents(Item, ItemOffers, ItemBids) {
  const { propsItem, set_propsItem, dispatch_post_orders } = Item;
  const { propsItemOffers, set_propsItemOffers } = ItemOffers;
  const { propsItemBids, set_propsItemBids } = ItemBids;

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  // const collection = useSelector((state) => state.reduce_get_collections.collection);
  // const v_collection = view_collection(collection);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;
  const maps = useSelector((state) => state.reduce_get_maps);

  const item = useSelector((state) => state.reduce_get_items.item);
  const v_item = view_item(item, maps, t);

  return (
    <>
      {v_item.販売中 && (
        <>
          <div className="fixed-btn-push d-md-none">
            <button
              className="btn btn-primary"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseShopping"
              aria-expanded="false"
              aria-controls="collapseShopping"
            >
              <i className="fa-solid fa-cart-shopping" />
            </button>
          </div>
          <div
            id="collapseShopping"
            className="collapse fixed-bottom bg-brand-light shadow d-md-none show"
          >
            <div className="container py-3 position-relative">
              <div className="card card-body border-0 bg-white">
                <div className="row g-0 align-items-center">
                  <dl className="col d-sm-flex text-end mb-0 pe-3">
                    <dt className="mb-1">
                      <small className="d-block">{t("現在の価格")}</small>
                    </dt>
                    <dd className="text-end text-nowrap mb-0">
                      <span
                        className="lead lh-1 fw-bold"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={v_item.item_sale_coin}
                      >
                        <sub className="me-1">{v_item.item_sale_coin_tag_color}</sub>
                        {v_item.item_sale_price}
                      </span>
                      <small className="d-block">
                        <sub>$</sub>
                        {v_item.item_sale_price_usd_label}
                      </small>
                    </dd>
                  </dl>
                  <div className="col">
                    <div className="btn-group">
                      {!v_item.所有者 && v_item.通常販売中 && (
                        <button
                          type="button"
                          className="btn btn-primary rounded-pill w-100 text-nowrap"
                          data-bs-toggle="modal"
                          data-bs-target="#itemOrderModal"
                          onClick={(e) => {
                            e.preventDefault();
                            set_propsItem({
                              ...propsItem,
                              item_id: v_item.item_id,
                              item_detail_id: v_item.item_detail_id,
                              // coin: "ETH",
                              pay_id: 0,
                            });
                          }}
                        >
                          <i className="fa-solid fa-cart-shopping fa-fw me-2" />
                          {/* 購入<span className="d-none d-sm-inline">する</span> */}
                          <span className="d-sm-none">{t("購入")}</span>
                          <span className="d-none d-sm-inline-block">{t("購入する")}</span>
                        </button>
                      )}
                      {!v_item.所有者 && v_item.オークション販売中 && (
                        <button
                          type="button"
                          className="btn btn-primary rounded-pill w-100 text-nowrap"
                          data-bs-toggle="modal"
                          data-bs-target="#itemBidModal"
                          onClick={(e) => {
                            e.preventDefault();
                            set_propsItemBids({
                              ...propsItemBids,
                              item_id: v_item.item_id,
                              item_detail_id: v_item.item_detail_id,
                              coin: "ETH",
                            });
                          }}
                        >
                          <i className="fa-solid fa-cart-shopping fa-fw me-2" />
                          {/* 入札<span className="d-none d-sm-inline">する</span> */}
                          <span className="d-sm-none">{t("入札")}</span>
                          <span className="d-none d-sm-inline-block">{t("入札する")}</span>
                        </button>
                      )}
                      {!v_item.所有者 && !v_item.オークション販売中 && (
                        <button
                          type="button"
                          className="btn btn-link w-100 text-nowrap"
                          data-bs-toggle="modal"
                          data-bs-target="#itemOfferModal"
                          onClick={() => {
                            set_propsItemOffers({
                              ...propsItemOffers,
                              item_id: v_item.item_id,
                              item_detail_id: v_item.item_detail_id,
                              coin: "ETH",
                            });
                          }}
                        >
                          <i className="fa-solid fa-hand fa-fw me-1" />
                          <span className="d-sm-none">{t("オファー")}</span>
                          <span className="d-none d-sm-inline-block">{t("オファーする")}</span>
                          {/* オファー */}
                          {/* <span className="d-none d-sm-inline">する</span> */}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="position-absolute top-0 end-0 translate-middle-y">
                <button
                  className="btn bg-brand-light rounded-pill btn-sm me-2 mb-1"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseShopping"
                  aria-expanded="false"
                  aria-controls="collapseShopping"
                >
                  <i className="fa-solid fa-angle-down me-1" />
                  {t("閉じる")}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
