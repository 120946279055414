import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import { view_item } from "../../stores/Items/view";

export default function Contents(Item, Activities) {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const maps = useSelector((state) => state.reduce_get_maps);

  // const collection = useSelector((state) => state.reduce_get_collections.collection);
  // const v_collection = view_collection(collection);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const item = useSelector((state) => state.reduce_get_items.item);
  const v_item = view_item(item, maps, t);

  return (
    <>
      {v_item.販売中 && (
        <div
          className="bg-brand-light d-md-none mb-3"
          style={{
            marginTop: "-1rem",
          }}
        >
          <div className="container d-flex py-3">
            <span className="d-block mt-auto fw-bold fs-15">
              {v_item.通常販売中 && t("販売終了まで")}
              {v_item.オークション販売中 && t("オークション終了まで")}
            </span>
            <div className="ms-auto">
              <span className="lead fw-bold lh-1">
                {v_item.item_sale_closed_at_countdown_label}
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
