import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import LiLanguage from "components/Li/LiLanguage";
import LiAccount from "components/Li/LiAccount";
import { getMessage } from "config/util";

import { dashboard, holderadmin, holderuser } from "Navigator/Router_material";
import ReactWOW from "react-wow";
import { post_authout } from "../../stores/Auth/actions";
import Header from "../../views_components/Component/Header";
import Menu from "../../views_components/Component/Menu";

import CustomImage from "./CustomImage";
import CustomSelect from "components/CustomSelect/CustomSelect.js";
import CustomDate from "components/CustomDate/CustomDate.js";
import CustomSwitch from "components/CustomSwitch/CustomSwitch.js";

import { hideModal, showModal } from "config/util";

import _modalSuccessfully from "./_modalSuccessfully";
import _processDialog from "./_processDialog";

export default function Contents(Collections) {
  const {
    propsCollections,
    set_propsCollections,
    dispatch_post_collections,
    loadingId,
  } = Collections;

  const props = propsCollections;
  const set_props = set_propsCollections;
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const history_modalSuccessfully = {
    push: (arg) => {
      hideModal("modalSuccessfully");
      hideModal("processDialog");
      history.push(arg);
    },
  };

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const maps = useSelector((state) => state.reduce_get_maps);

  return (
    <>
      <div className="row g-3 p-xl-3">
        <div className="col-md-6 col-xl-8">
          <div className="row row-cols-1 row-cols-xl-2 g-3">
            <div className="col">
              <h3 className="h5">{t("基本設定")}</h3>
              <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-1 row-cols-xl-1 row-cols-xxl-2 g-2 mb-4">
                <div className="col">
                  <CustomImage
                    id="collectionLogo"
                    title={t("コレクションアイコン")}
                    image_path={props.collection_icon_uri}
                    image={props.collection_icon}
                    mime_type={props.collection_icon_mime}
                    set_image_path={(base64, file) => {
                      if (
                        ![
                          "image/jpeg",
                          "image/png",
                          "image/svg+xml",
                          "image/gif",
                          "image/webp",
                          // "video/mp4",
                        ].includes(file.type)
                      ) {
                        alert(t("対応するメディアではありません"));
                        return;
                      }

                      set_props({
                        ...props,
                        collection_icon: file,
                        collection_icon_mime: file.type,
                        collection_icon_uri: base64,
                      });
                    }}
                  />
                </div>
                <div className="col">
                  <CustomImage
                    id="collectionCover"
                    title={t("カバー画像")}
                    image_path={props.collection_cover_uri}
                    image={props.collection_cover}
                    mime_type={props.collection_cover_mime}
                    set_image_path={(base64, file) => {
                      if (
                        ![
                          "image/jpeg",
                          "image/png",
                          "image/svg+xml",
                          "image/gif",
                          "image/webp",
                          // "video/mp4",
                        ].includes(file.type)
                      ) {
                        alert(t("対応するメディアではありません"));
                        return;
                      }

                      set_props({
                        ...props,
                        collection_cover: file,
                        collection_cover_mime: file.type,
                        collection_cover_uri: base64,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="mb-4">
                <div className="form-floating mb-2">
                  <select
                    onChange={(e) => {
                      const val = e.target.value;
                      set_props({
                        ...props,
                        category_id: val,
                      });
                    }}
                    className="form-select"
                    id="collectionCategory"
                    aria-label="\u30AB\u30C6\u30B4\u30EA"
                    value={props.category_id}
                  >
                    {Object.keys(maps.nft_categories).map((k, idx) => {
                      return <option value={k}>{maps.nft_categories[k]}</option>;
                    })}
                  </select>
                  <label htmlFor="collectionCategory">{t("カテゴリ")}</label>
                </div>
                {/* <div className="form-floating mb-2">
                        <input
                          type="text"
                          className="form-control"
                          id="collectionId"
                          placeholder="\u30AB\u30B9\u30BF\u30E0URL"
                          inputMode="email"
                          onChange={(e) => {
                            const val = e.target.value;
                            set_props({
                              ...props,
                              collection_nmid: val,
                            });
                          }}
                          value={props.collection_nmid}
                        />
                        <label htmlFor="collectionId">カスタムURL</label>
                        <div id="emailHelp" className="form-text mb-3">
                          (例 : tmty.io/your_url/)
                        </div>
                      </div> */}
                <div className="form-floating mb-2">
                  <input
                    type="text"
                    className="form-control"
                    id="collectionName"
                    placeholder="\u30B3\u30EC\u30AF\u30B7\u30E7\u30F3\u540D"
                    maxLength={40}
                    onChange={(e) => {
                      const val = e.target.value;
                      set_props({
                        ...props,
                        collection_name: val,
                      });
                    }}
                    value={props.collection_name}
                  />
                  <label htmlFor="collectionName">{t("コレクション名")}</label>
                </div>
                <div className="form-floating mb-2">
                  <textarea
                    type="text"
                    className="form-control"
                    id="collectionDisc"
                    placeholder="\u30B3\u30EC\u30AF\u30B7\u30E7\u30F3\u306E\u8AAC\u660E"
                    maxLength={300}
                    style={{
                      height: "200px",
                    }}
                    defaultValue={""}
                    onChange={(e) => {
                      const val = e.target.value;
                      set_props({
                        ...props,
                        collection_description: val,
                      });
                    }}
                    value={props.collection_description}
                  />
                  <label htmlFor="collectionDisc">{t("コレクションの説明")}</label>
                </div>
              </div>
            </div>
            <div className="col mb-4">
              <h3 className="h5">{t("クリエイター報酬")}</h3>
              <p className="text-muted">{t("最大10％まで設定できます。")}</p>
              <div className="input-group mb-3">
                <select
                  className="form-select"
                  aria-label="Creator Rewards"
                  aria-describedby="rewards"
                  value={props.collection_creator_reward}
                  onChange={(e) => {
                    const val = e.target.value;
                    set_props({
                      ...props,
                      collection_creator_reward: val,
                    });
                  }}
                >
                  <option>—</option>
                  <option>0</option>
                  <option>0.5</option>
                  <option>1</option>
                  <option>1.5</option>
                  <option>2</option>
                  <option>2.5</option>
                  <option>3</option>
                  <option>3.5</option>
                  <option>4</option>
                  <option>4.5</option>
                  <option>5</option>
                  <option>5.5</option>
                  <option>6</option>
                  <option>6.5</option>
                  <option>7</option>
                  <option>7.5</option>
                  <option>8</option>
                  <option>8.5</option>
                  <option>9</option>
                  <option>9.5</option>
                  <option>10</option>
                  {/* <option value={k}>{maps.nfts[k]}</option>; */}
                </select>
                <span className="input-group-text" id="rewards">
                  <i className="fa-solid fa-percent" />
                </span>
              </div>
              {/* <h3 className="h5">クリエイター報酬(例 2.5)</h3>
              <div className="form-floating mb-4">
                <input
                  type="text"
                  className="form-control"
                  id="creator_reward"
                  placeholder="2.5"
                  onChange={(e) => {
                    const val = e.target.value;
                    set_props({
                      ...props,
                      collection_creator_reward: val,
                    });
                  }}
                  value={props.collection_creator_reward}
                />
                <label htmlFor="creator_reward">クリエイター報酬</label>
              </div> */}
              <h3 className="h5">{t("リンク設定")}</h3>
              <div className="row row-cols-1 row-cols-md-2 row-cols-lg-1 g-2">
                <div className="col">
                  <div className="form-floating">
                    <input
                      type="url"
                      className="form-control"
                      id="collectionLinkTwitter"
                      placeholder="Twitter"
                      onChange={(e) => {
                        const val = e.target.value;
                        set_props({
                          ...props,
                          link_twitter: val,
                        });
                      }}
                      value={props.link_twitter}
                    />
                    <label htmlFor="collectionLinkTwitter">Twitter</label>
                  </div>
                </div>
                <div className="col">
                  <div className="form-floating">
                    <input
                      type="url"
                      className="form-control"
                      id="collectionLinkFacebbok"
                      placeholder="Facebook"
                      onChange={(e) => {
                        const val = e.target.value;
                        set_props({
                          ...props,
                          link_facebook: val,
                        });
                      }}
                      value={props.link_facebook}
                    />
                    <label htmlFor="collectionLinkFacebbok">Facebook</label>
                  </div>
                </div>
                <div className="col">
                  <div className="form-floating">
                    <input
                      type="url"
                      className="form-control"
                      id="collectionLinkDiscord"
                      placeholder="Discord"
                      onChange={(e) => {
                        const val = e.target.value;
                        set_props({
                          ...props,
                          link_discord: val,
                        });
                      }}
                      value={props.link_discord}
                    />
                    <label htmlFor="collectionLinkDiscord">Discord</label>
                  </div>
                </div>
                <div className="col">
                  <div className="form-floating">
                    <input
                      type="url"
                      className="form-control"
                      id="collectionLinkInstagram"
                      placeholder="Instagram"
                      onChange={(e) => {
                        const val = e.target.value;
                        set_props({
                          ...props,
                          link_instagram: val,
                        });
                      }}
                      value={props.link_instagram}
                    />
                    <label htmlFor="collectionLinkInstagram">Instagram</label>
                  </div>
                </div>
                {/* <div className="col">
                  <div className="form-floating">
                    <input
                      type="url"
                      className="form-control"
                      id="collectionLinkWeb"
                      placeholder="\u305D\u306E\u4ED6URL"
                      onChange={(e) => {
                        const val = e.target.value;
                        set_props({
                          ...props,
                          link_other: val,
                        });
                      }}
                      value={props.link_other}
                    />
                    <label htmlFor="collectionLinkWeb">その他URL</label>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col">
                <div className="mb-4">
                  <h3 className="h5">
                    プロパティ
                    <span
                      className="ms-1"
                      data-bs-toggle="tooltip"
                      data-bs-html="true"
                      title=""
                    >
                      <i className="fa-solid fa-circle-question" />
                    </span>
                  </h3>
                  <div className="row g-1 mb-2">
                    <div className="col-4">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="propertyName"
                          placeholder="\u30D7\u30ED\u30D1\u30C6\u30A3\u540D"
                        />
                        <label htmlFor="propertyName">プロパティ名</label>
                      </div>
                    </div>
                    <div className="col">
                      <div className="form-floating mb-2">
                        <select
                          className="form-select"
                          id="propertyClass"
                          aria-label="\u30D7\u30ED\u30D1\u30C6\u30A3\u30BF\u30A4\u30D7"
                        >
                          <option>テキスト</option>
                          <option>数値</option>
                        </select>
                        <label htmlFor="propertyClass">プロパティタイプ</label>
                      </div>
                    </div>
                  </div>
                  <div className="text-end">
                    <button
                      type="button"
                      className="btn btn-outline-primary rounded-pill ms-auto"
                    >
                      <i className="fa-solid fa-plus" />
                      <span className="ps-1">追加</span>
                    </button>
                  </div>
                </div>
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-1 g-3 g-sm-4 mb-4">
                  <div className="col">
                    <h3 className="h5 mb-3">公開制限</h3>
                    <div className="form-check-reverse form-switch mb-3">
                      <label className="form-check-label" htmlFor="regSee-1">
                        ウォッチリスト登録者
                      </label>
                      <input className="form-check-input" type="checkbox" id="regSee-1" />
                    </div>
                    <div className="form-check-reverse form-switch mb-3">
                      <label className="form-check-label" htmlFor="regSee-2">
                        フォロワー
                      </label>
                      <input className="form-check-input" type="checkbox" id="regSee-2" />
                    </div>
                  </div>
                  <div className="col">
                    <h3 className="h5 mb-3">購入者制限</h3>
                    <div className="form-check-reverse form-switch mb-3">
                      <label className="form-check-label" htmlFor="regBuyer-1">
                        ウォッチリスト登録者
                      </label>
                      <input className="form-check-input" type="checkbox" id="regBuyer-1" />
                    </div>
                    <div className="form-check-reverse form-switch mb-3">
                      <label className="form-check-label" htmlFor="regBuyer-2">
                        フォロワー
                      </label>
                      <input className="form-check-input" type="checkbox" id="regBuyer-2" />
                    </div>
                  </div>
                </div>
                <div className="mb-4 mb-sm-4">
                  <h3 className="h5">予約公開</h3>
                  <p className="text-muted">予約公開の日時が未設定の場合すぐに公開されます。</p>
                  <div className="input-daterange hstack gap-2" id="datepicker">
                    <div className="form-floating w-100">
                      <input
                        type="text"
                        className="form-control"
                        id="itemDateStart"
                        name="start"
                        placeholder="\u516C\u958B\u65E5\u6642"
                      />
                      <label htmlFor="itemDateStart">公開日時</label>
                    </div>
                  </div>
                </div>
              </div> */}
      </div>
    </>
  );
}
