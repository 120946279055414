import React, { createContext, useEffect, useRef, useState } from "react";
import { fork } from "redux-saga/effects";

import I18nInit from "./I18nInit";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { useDispatch, useSelector } from "react-redux";

import { get_maps } from "stores/Auth/actions";
import { get_accounts } from "stores/Accounts/actions";
import { get_wallets } from "stores/Wallets/actions";

import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

import { combineReducers } from "redux";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import sessionStorage from "redux-persist/lib/storage/session";

import reducersAPI from "stores/Auth/reducers";
import reducersAccounts from "stores/Accounts/reducers";
import reducersAccountsCards from "stores/AccountsCards/reducers";
import reducersCategories from "stores/Categories/reducers";
import reducersNfts from "stores/Nfts/reducers";
import reducersItems from "stores/Items/reducers";
import reducersItemOffers from "stores/ItemOffers/reducers";
import reducersItemBids from "stores/ItemBids/reducers";
import reducersWallets from "stores/Wallets/reducers";
import reducersWithdraws from "stores/Withdraws/reducers";
import reducersUsers from "stores/Users/reducers";
import reducersUser from "stores/User/reducers";
import reducersActivities from "stores/Activities/reducers";
import reducersCollections from "stores/Collections/reducers";
import reducersTop from "stores/Top/reducers";
import reducersTransactions from "stores/Transactions/reducers";

import sagasAuth from "stores/Auth/sagas";
import sagasAccounts from "stores/Accounts/sagas";
import sagasAccountsCards from "stores/AccountsCards/sagas";
import sagasCategories from "stores/Categories/sagas";
import sagasNfts from "stores/Nfts/sagas";
import sagasItems from "stores/Items/sagas";
import sagasItemOffers from "stores/ItemOffers/sagas";
import sagasItemBids from "stores/ItemBids/sagas";
import sagasWallets from "stores/Wallets/sagas";
import sagasWithdraws from "stores/Withdraws/sagas";
import sagasUsers from "stores/Users/sagas";
import sagasUser from "stores/User/sagas";
import sagasActivities from "stores/Activities/sagas";
import sagasCollections from "stores/Collections/sagas";
import sagasTop from "stores/Top/sagas";
import sagasTransactions from "stores/Transactions/sagas";

function* rootSaga() {
  yield fork(sagasAuth);
  yield fork(sagasAccounts);
  yield fork(sagasAccountsCards);
  yield fork(sagasCollections);
  yield fork(sagasCategories);
  yield fork(sagasNfts);
  yield fork(sagasItems);
  yield fork(sagasItemOffers);
  yield fork(sagasItemBids);
  yield fork(sagasWallets);
  yield fork(sagasWithdraws);
  yield fork(sagasUsers);
  yield fork(sagasUser);
  yield fork(sagasActivities);
  yield fork(sagasTop);
  yield fork(sagasTransactions);
}

const rootReducer = combineReducers({
  ...reducersAPI,
  ...reducersAccounts,
  ...reducersAccountsCards,
  ...reducersCategories,
  ...reducersNfts,
  ...reducersItems,
  ...reducersItemOffers,
  ...reducersItemBids,
  ...reducersWallets,
  ...reducersWithdraws,
  ...reducersUsers,
  ...reducersUser,
  ...reducersActivities,
  ...reducersCollections,
  ...reducersTop,
  ...reducersTransactions,
});

// 永続化の設定
const persistConfig = {
  key: "redux", // Storageに保存されるキー名を指定する
  storage: sessionStorage, // 保存先としてlocalStorageがここで設定される
  // whitelist: ['reduce_get_maps', 'reduce_post_authin', 'reduce_post_notification'],

  // Stateは`todos`のみStorageに保存する
  //   blacklist: ['visibilityFilter'] // `visibilityFilter`は保存しない
};

// 永続化設定されたReducerとして定義
const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const store = createStore(persistedReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export const AppStore = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AppLoading />
      </PersistGate>
    </Provider>
  );
};

const AppLoading = () => {
  // const dispatch = useDispatch();

  return (
    <>
      <I18nInit />
    </>
  );
};
