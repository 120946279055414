import React, { FC, useEffect, useRef, useState, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { getMessage, toMomentString, toggleAry } from "config/util";
import { Type } from "./_type";

export const HeaderWallet = (Activities) => {
  const t = Activities.t;

  return (
    <>
      <div className="row g-1 g-sm-2 border-bottom border-dark border-2 py-3 py-sm-0">
        <div className="col-5 col-sm-2 py-sm-3">
          <span className="d-block fw-bold small-xs-only">{t("イベント")}</span>
        </div>
        <div className="col-7 col-sm-3 col-xl-2 py-sm-3 text-sm-end order-sm-last">
          <span className="d-block fw-bold small-xs-only">{t("日時")}</span>
        </div>
        <div className="col-5 col-sm-2 py-sm-3">
          <span className="d-block fw-bold small-xs-only">{t("金額")}</span>
        </div>
        <div className="col-7 offset-sm-0 col-sm py-sm-3 text-truncate overflow-hidden">
          <span className="d-block fw-bold small-xs-only">{t("どこから")}</span>
        </div>
        <div className="col offset-5 offset-sm-0 py-sm-3 text-truncate overflow-hidden">
          <span className="d-block fw-bold small-xs-only">{t("どこへ")}</span>
        </div>
      </div>
    </>
  );
};

export const RowWallet = (Activities) => {
  const { v_activity, t } = Activities;
  const history = useHistory();

  return (
    <>
      <div className="row g-2 border-bottom py-3 mb-sm-0 py-sm-0">
        <div className="col-5 col-sm-2 py-sm-3">
          <Type type={v_activity.activity_type} name={v_activity.activity_name} />
        </div>
        <div className="col-7 col-sm-3 col-xl-2 py-sm-3 text-sm-end order-sm-last">
          {/* {v_activity.created_at_countdown_label} */}
          {v_activity.explorer_uri && (
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                window.open(`${v_activity.explorer_uri}`, "_blank", "noreferrer");
              }}
            >
              {v_activity.created_at_countdown_label}
              {v_activity.explorer_uri && (
                <i className="fa-solid fa-arrow-up-right-from-square ms-2" />
              )}
            </a>
          )}
          {!v_activity.explorer_uri && v_activity.activity_type == 41 && <>{t("承認待ち")}</>}
          {!v_activity.explorer_uri && v_activity.activity_type != 41 && (
            <>{v_activity.created_at_countdown_label}</>
          )}
        </div>
        <div className="col-5 col-sm-2 py-sm-3">
          {v_activity.coin_icon_tag}
          {/* <i className="fa-brands fa-ethereum fa-fw me-1 text-muted" /> */}
          <span className="fw-bold"> {v_activity.activity_price_label}</span>
        </div>
        <div className="col-7 offset-sm-0 col-sm py-sm-3 text-truncate overflow-hidden">
          {v_activity.activity_from}
        </div>
        <div className="col offset-5 offset-sm-0 py-sm-3 text-truncate overflow-hidden">
          <small>
            <i
              className="fa-solid fa-turn-up fa-fw text-muted d-sm-none"
              style={{
                transform: "rotate(90deg)",
              }}
            />
          </small>
          {/* {v_activity.user_name} */}
          {v_activity.activity_to}
        </div>
      </div>
    </>
  );
};

export const SearchWallet = (Activities) => {
  const { propsActivities, set_propsActivities, dispatch_gets } = Activities;
  const history = useHistory();
  const dispatch = useDispatch();

  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };
  const [t, i18n] = useTranslation();
  const count = useSelector((state) => state.reduce_get_activities.count);

  const types = propsActivities.search.type ?? [];
  const sorts = propsActivities.search.sort ?? [];
  const sort_keys = propsActivities.search.sort_key ?? [];

  const platforms = propsActivities.search.platform ?? [];

  const dispatch_gets_type = (val) => {
    const search = {
      ...propsActivities.search,
      type: toggleAry(types, val),
      // type:[val],
    };
    dispatch_gets(search, 1);
  };
  const sort_key_maps = {
    0: ` ${t("新しい順")}`,
    1: ` ${t("古い順")}`,
  };

  const sort_maps = {
    0: ["created_at", "DESC"],
    1: ["created_at", "ASC"],
  };

  const dispatch_gets_sort = (val) => {
    // const sort_key = toggleAry(sort_keys, val);
    const sort_key = [val];
    const sort = [];

    for (const e_sort_keys of sort_keys) {
      sort.push(sort_maps[e_sort_keys]);
    }

    const search = {
      ...propsActivities.search,
      sort_key,
      sort,
    };
    dispatch_gets(search, 1);
  };

  // const dispatch_gets_platform = (val) => {
  //   const search = {
  //     ...propsActivities.search,
  //     platform: toggleAry(platforms, val),
  //     // platform:[val],
  //   };
  //   dispatch_gets(search, 1);
  // };

  const loading = useSelector((state) => state.reduce_get_activities.isLoading);
  const datalist = useSelector((state) => state.reduce_get_activities.activities);

  return (
    <>
      <div className="btn-toolbar ms-auto" role="toolbar" aria-label="\u691C\u7D22\u6761\u4EF6">
        <button
          type="button"
          className="btn btn-outline-secondary dropdown-toggle me-1"
          id="filSort"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="fa-solid fa-filter" />
          {types.includes(40) && ` ${t("預入")}`}
          {types.includes(41) && ` ${t("送付")}`}
        </button>
        <ul className="dropdown-menu dropdown-menu-end shadow-sm" aria-labelledby="filSort">
          <li>
            <button
              type="button"
              className={`dropdown-item ${types.includes(40) && "active"}`}
              onClick={(e) => {
                e.stopPropagation();
                dispatch_gets_type(40);
              }}
            >
              <i
                className="fa-solid fa-down-long fa-fw text-muted me-3"
                style={{
                  transform: "rotate(45deg)",
                }}
              />
              {t("預入")}
            </button>
          </li>
          <li>
            <button
              type="button"
              className={`dropdown-item ${types.includes(41) && "active"}`}
              onClick={(e) => {
                e.stopPropagation();
                dispatch_gets_type(41);
              }}
            >
              <i
                className="fa-solid fa-up-long fa-fw text-muted me-3"
                style={{
                  transform: "rotate(45deg)",
                }}
              />
              {t("送付")}
            </button>
          </li>
        </ul>
        <button
          type="button"
          className="btn btn-outline-secondary dropdown-toggle"
          id="filSort"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="fa-solid fa-arrow-down-wide-short" />
          {sort_keys.map((x) => sort_key_maps[x])}
          {/* {sort_keys.includes(0) && " 新しい順"} */}
          {/* {sort_keys.includes(1) && " 古い順"} */}
        </button>
        <ul className="dropdown-menu dropdown-menu-end shadow-sm" aria-labelledby="filSort">
          <li>
            <button
              type="button"
              className="dropdown-item"
              onClick={(e) => {
                e.stopPropagation();
                dispatch_gets_sort(0);
              }}
            >
              {t("新しい順")}
            </button>
          </li>

          <li>
            <button
              type="button"
              className="dropdown-item"
              onClick={(e) => {
                e.stopPropagation();
                dispatch_gets_sort(1);
              }}
            >
              {t("古い順")}
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};
