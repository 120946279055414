import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import { QRCodeSVG } from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import HeaderAdmin from "../../views_components/Component/HeaderAdmin";

import TransactionsPager from "../../views_components/PagerTransactions/TransactionsPager";
import TransactionsList from "../../views_components/PagerTransactions/TransactionsList";
import Footer from "../../views_components/Component/Footer";
import _modal_withdrawals from "./_modal_approve";

export default function Contents({ Transactions }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const { coin } = useParams();

  const [active_tab, set_active_tab] = useState("預入");

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_wallets = useSelector((state) => state.reduce_get_wallets);
  const maps = useSelector((state) => state.reduce_get_maps);

  const coin_decimals = maps.coin_decimals;
  const coins = maps.coins;

  useEffect(() => {}, []);
  const coin_fullname = coins[coin];

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  // const collection = useSelector((state) => state.reduce_get_collections.collection);
  // const v_collection = view_collection(collection);

  return (
    <>
      <div>
        <HeaderAdmin />
        <div className="container-main">
          <main className="main mt-4 mb-3">
            <div className="container main-toolbar align-items-center pb-0 pb-md-4">
              <h2 className="mb-0 text-truncate"> {t("出金承認")}</h2>
            </div>
            <hr className="border-bottom border-dark border-2 mb-2 d-md-none" />
            <div class="container activitys">
              <TransactionsPager Transactions={Transactions} />
              <TransactionsList Transactions={Transactions} />
            </div>
          </main>
        </div>
        {_modal_withdrawals(Transactions)}
      </div>
    </>
  );
}
