import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import { view_collection } from "../../stores/Collections/view";
import { view_user } from "../../stores/Users/view";
import { put_users_id } from "stores/Users/actions";
import { put_users_my_id } from "stores/User/actions";

import { getMessage } from "config/util";
import _more_options from "./_more_options";
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function Contents(Users) {
  const { propsUsers, set_propsUsers, dispatch_gets, user_id } = Users;

  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_users_my = useSelector((state) => state.reduce_get_users_my);
  // const loading_put_users_my_id = useSelector((state) => state.reduce_put_users_my_id.isLoading);

  const loading_get_users = useSelector((state) => state.reduce_get_users.isLoadingId);
  const reduce_get_users = useSelector((state) => state.reduce_get_users);

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const dispatch_put_users_my_id = (data, next, prev) => {
    dispatch(
      put_users_my_id({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;
  // const collection = useSelector((state) => state.reduce_get_items.collection);
  const collection = useSelector((state) => state.reduce_get_collections.collection);
  const v_collection = view_collection(collection);

  const user = reduce_get_users.user;
  const self = reduce_get_users_my.user;
  const v_user = view_user(user);
  const v_self = view_user(self);

  const link_twitter = v_self.link_twitter;
  const link_facebook = v_self.link_facebook;
  const link_instagram = v_self.link_instagram;
  const link_discord = v_self.link_discord;

  return (
    <>
      <div className="sidebar shadow-end">
        <div className="sidebar-sticky">
          <div className="me-md-3">
            <div className="position-relative">
              <div className="d-flex justify-content-between align-items-start my-3">
                <div className="media-object">
                  {/* <div className="verified"> */}
                  <div className="icon icon-pill">
                    <img
                      src={v_self.user_icon_uri}
                      className="icon-item"
                      width={70}
                      height={70}
                      alt=""
                    />
                  </div>
                  {/* </div> */}
                </div>
                <div
                  className="btn-group flex-column"
                  style={{
                    zIndex: 2,
                  }}
                >
                  {/* {_more_options(Items)} */}
                  {v_self.myself && (
                    <>
                      <a
                        className="btn btn-outline-dark rounded-pill text-nowrap px-3"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push({
                            pathname: `/account-image`,
                          });
                        }}
                      >
                        {t("プロフィール編集")}
                      </a>
                      <a
                        className="btn btn-link rounded-pill text-nowrap px-3"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(`/collections/create`);
                        }}
                      >
                        <i className="fa-solid fa-plus me-2" />
                        {t("コレクション作成")}
                      </a>
                    </>
                  )}
                  {!v_self.myself && (
                    <button
                      type="button"
                      className={`btn btn-outline-dark rounded-pill ${v_self.user_favorited_my &&
                        "active"}`}
                      data-bs-toggle="button"
                      autoComplete="off"
                      aria-pressed={v_self.user_favorited_my ? "true" : "false"}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch_put_users_my_id(
                          {
                            id: v_self.user_id,
                          },
                          () => {},
                          () => {}
                        );
                      }}
                    >
                      {v_self.user_favorited_my ? t("フォロー中") : t("フォロー")}
                    </button>
                  )}
                </div>
              </div>
              <div className="text-black d-block mb-2">
                <h2 className="product-title mb-1">
                  <a
                    className="stretched-link text-dark"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`/users/${v_self.user_id}`);
                    }}
                  >
                    {/* <span className="verified-text">{v_self.user_name}</span> */}
                    {v_self.user_name}
                  </a>
                </h2>
                {/* <span className="d-block">@{v_self.user_nmid}</span> */}
              </div>
            </div>

            <div
              className="list-group-item border-start-none border-end-none ps-0"
              style={{ border: "none" }}
            >
              <CopyToClipboard
                text={v_self.user_nmid}
                onCopy={() => {
                  alert(t("ユーザーIDをコピーしました。"));
                }}
              >
                <span data-bs-toggle="tooltip" data-bs-placement="top" title="">
                  <i className="fa-solid fa-clipboard-check fa-fw me-2" title="" />
                  {v_self.user_nmid}
                </span>
              </CopyToClipboard>
            </div>
            <p>{v_self.user_message_br}</p>
            {v_self.user_message2 && (
              <div className="alert alert-info alert-dismissible fade show" role="alert">
                <small className="d-block fw-bold">{t("お知らせ")}</small>
                <small>{v_self.user_message2_br}</small>
              </div>
            )}
          </div>
          <div className="list-group list-group-flush">
            <aside className="list-group-item ps-0 d-flex" role="link">
              {link_twitter && (
                <a
                  className="btn btn-link btn-sm rounded-pill me-2"
                  href
                  target
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`${link_twitter}`, "_blank", "noreferrer");
                  }}
                >
                  <i className="fab fa-twitter fa-fw" />
                </a>
              )}
              {link_facebook && (
                <a
                  className="btn btn-link btn-sm rounded-pill me-2"
                  href
                  target
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`${link_facebook}`, "_blank", "noreferrer");
                  }}
                >
                  <i className="fab fa-facebook-f fa-fw" />
                </a>
              )}
              {link_instagram && (
                <a
                  className="btn btn-link btn-sm rounded-pill me-2"
                  href
                  target
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`${link_instagram}`, "_blank", "noreferrer");
                  }}
                >
                  <i className="fab fa-instagram fa-fw" />
                </a>
              )}
              {link_discord && (
                <a
                  className="btn btn-link btn-sm rounded-pill me-2"
                  href
                  target
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`${link_discord}`, "_blank", "noreferrer");
                  }}
                >
                  <i className="fab fa-discord fa-fw" />
                </a>
              )}
            </aside>
            <div className="list-group-item ps-0 d-flex flex-nowrap">
              <span className="mb-0 me-3">
                <a
                  href="javascript:void(0);"
                  className="text-black"
                  role="button"
                  data-bs-toggle="modal"
                  data-bs-target="#userFollow"
                  onClick={() => {
                    dispatch_gets({ ...propsUsers.search, user_id, followings: 1, followers: 0 });
                  }}
                >
                  <span className="fw-bold">{v_self.user_favoriting_count}</span>
                  <span className="ms-1 text-gray">{t("フォロー")}</span>
                </a>
              </span>
              <span className="mb-0">
                <a
                  href="javascript:void(0);"
                  className="text-black"
                  role="button"
                  data-bs-toggle="modal"
                  data-bs-target="#userFollow"
                  onClick={() => {
                    dispatch_gets({ ...propsUsers.search, user_id, followings: 0, followers: 1 });
                  }}
                >
                  <span className="fw-bold">{v_self.user_favorited_count}</span>
                  <span className="ms-1 text-gray">{t("フォロワー")}</span>
                </a>
              </span>
            </div>
          </div>
          {/* <div className="accordion mb-3" id="newAccordion">
            <div className="accordion-item border-start-0 border-end-0">
              <div className="accordion-header" id="trophys-heading">
                <button
                  type="button"
                  className="accordion-button ps-0"
                  data-bs-toggle="collapse"
                  data-bs-target="#trophys"
                  aria-expanded="true"
                  aria-controls="trophys"
                  style={{
                    paddingTop: ".9rem",
                    paddingBottom: ".9rem",
                  }}
                >
                  <i className="fa-solid fa-trophy fa-fw me-2" />
                  トロフィー  
                </button>
              </div>
              <div
                id="trophys"
                className="accordion-collapse collapse show"
                aria-labelledby="trophys-heading"
              >
                <div className="accordion-body px-0">
                  <div className="d-flex flex-wrap gap-2">
                    <small className="badge bg-light text-muted">
                      <i
                        className="fa-solid fa-trophy fa-fw me-1"
                        style={{
                          color: "#d4ca83",
                        }}
                      />
                      100万のフォロワー
                    </small>
                    <small className="badge bg-light text-muted">
                      <i
                        className="fa-solid fa-trophy fa-fw me-1"
                        style={{
                          color: "#d4ca83",
                        }}
                      />
                      トップセラー
                    </small>
                    <small className="badge bg-light text-muted">
                      <i
                        className="fa-solid fa-trophy fa-fw me-1"
                        style={{
                          color: "#dec7c3",
                        }}
                      />
                      1Kアイテム達成
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item border-start-0 border-end-0">
              <div className="accordion-header" id="roles-heading">
                <button
                  type="button"
                  className="accordion-button ps-0 collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#roles"
                  aria-expanded="false"
                  aria-controls="roles"
                  style={{
                    paddingTop: ".9rem",
                    paddingBottom: ".9rem",
                  }}
                >
                  <i className="fa-solid fa-award me-2" />
                  ロール  
                </button>
              </div>
              <div
                id="roles"
                className="accordion-collapse collapse"
                aria-labelledby="roles-heading"
              >
                <div className="accordion-body px-0">–</div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
