import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import Header from "../../views_components/Component/Header";
import Menu from "../../views_components/Component/Menu";

import CustomImage from "./CustomImage";
import { hideModal, showModal } from "config/util";

export default function Contents({ Collection }) {
  const { propsCollection, set_propsCollection, dispatch_put_collections_id } = Collection;

  const props = propsCollection;
  const set_props = set_propsCollection;
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const maps = useSelector((state) => state.reduce_get_maps);

  return (
    <>
      <div>
        <Header />
        <div className="container container-main">
          <main className="main">
            <div action="created-collection-detail.html" entype="multpart/form-data">
              <div className="main-header pt-3 pb-2">
                <h2 className="h4 m-0 text-truncate">{t("コレクションを変更")}</h2>
                <div className="btn-group">
                  <a
                    className="btn btn-outline-secondary text-dark rounded-pill px-3 me-2 text-nowrap"
                    href="javascript:history.back()"
                  >
                    <i className="fa-solid fa-xmark" />
                    <span className="d-none d-sm-inline-block"> {t("キャンセル")}</span>
                  </a>
                  <button
                    type="button"
                    name="confirm"
                    className="btn btn-primary rounded-pill px-3 text-nowrap"
                    role="button"
                    // data-bs-toggle="modal"
                    // data-bs-target="#processDialog"
                    onClick={(e) => {
                      e.preventDefault();
                      // showModal("modalSuccessfully");

                      dispatch_put_collections_id(
                        {
                          id: props.collection_id,
                          category_id: props.category_id,
                          collection_icon: props.collection_icon,
                          collection_cover: props.collection_cover,
                          collection_nmid: props.collection_nmid,
                          name: props.collection_name,
                          collection_description: props.collection_description,
                          link_twitter: props.link_twitter,
                          link_facebook: props.link_facebook,
                          link_discord: props.link_discord,
                          link_instagram: props.link_instagram,
                          link_other: props.link_other,
                          active_flg: true,
                        },
                        () => {
                          history.push(`/collections/${props.collection_id}/items`);
                        },
                        () => {}
                      );
                    }}
                  >
                    {t("保存")}
                  </button>
                </div>
              </div>
              <div className="row g-3 p-xl-3">
                <div className="col-md-6 col-xl-8">
                  <div className="row row-cols-1 row-cols-xl-2 g-3">
                    <div className="col">
                      <h3 className="h5">{t("基本設定")}</h3>
                      <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-1 row-cols-xl-1 row-cols-xxl-2 g-2 mb-4">
                        <div className="col">
                          <CustomImage
                            id="collectionLogo"
                            title={t("コレクションアイコン")}
                            image_path={props.collection_icon_uri}
                            image={props.collection_icon}
                            mime_type={props.collection_icon_mime}
                            set_image_path={(base64, file) => {
                              set_props({
                                ...props,
                                collection_icon: file,
                                collection_icon_mime: file.type,
                                collection_icon_uri: base64,
                              });
                            }}
                          />
                        </div>
                        <div className="col">
                          <CustomImage
                            id="collectionCover"
                            title={t("カバー画像")}
                            image_path={props.collection_cover_uri}
                            image={props.collection_cover}
                            mime_type={props.collection_cover_mime}
                            set_image_path={(base64, file) => {
                              set_props({
                                ...props,
                                collection_cover: file,
                                collection_cover_mime: file.type,
                                collection_cover_uri: base64,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="mb-4">
                        <div className="form-floating mb-2">
                          <select
                            onChange={(e) => {
                              const val = e.target.value;
                              set_props({
                                ...props,
                                category_id: val,
                              });
                            }}
                            className="form-select"
                            id="collectionCategory"
                            aria-label=""
                            value={props.category_id}
                          >
                            {Object.keys(maps.nft_categories).map((k, idx) => {
                              return <option value={k}>{maps.nft_categories[k]}</option>;
                            })}
                          </select>
                          <label htmlFor="collectionCategory">{t("カテゴリ")}</label>
                        </div>
                        {/* <div className="form-floating mb-2">
                          <input
                            type="text"
                            className="form-control"
                            id="collectionId"
                            placeholder="\u30B3\u30EC\u30AF\u30B7\u30E7\u30F3ID"
                            inputMode="email"
                            onChange={(e) => {
                              const val = e.target.value;
                              set_props({
                                ...props,
                                collection_nmid: val,
                              });
                            }}
                            value={props.collection_nmid}
                          />
                          <label htmlFor="collectionId">カスタムURL</label>
                          <div id="emailHelp" className="form-text mb-3">
                            (例 : tmty.io/your_url/)
                          </div>
                        </div> */}
                        <div className="form-floating mb-2">
                          <input
                            type="text"
                            className="form-control"
                            id="collectionName"
                            placeholder=""
                            defaultValue=""
                            maxLength={40}
                            onChange={(e) => {
                              const val = e.target.value;
                              set_props({
                                ...props,
                                collection_name: val,
                              });
                            }}
                            value={props.collection_name}
                          />
                          <label htmlFor="collectionName">{t("コレクション名")}</label>
                        </div>
                        <div className="form-floating mb-2">
                          <textarea
                            type="text"
                            className="form-control"
                            id="collectionDisc"
                            placeholder="\u30B3\u30EC\u30AF\u30B7\u30E7\u30F3\u306E\u8AAC\u660E"
                            style={{
                              height: "200px",
                            }}
                            maxLength={300}
                            defaultValue={""}
                            onChange={(e) => {
                              const val = e.target.value;
                              set_props({
                                ...props,
                                collection_description: val,
                              });
                            }}
                            value={props.collection_description}
                          />
                          <label htmlFor="collectionDisc">{t("コレクションの説明")}</label>
                        </div>
                      </div>
                    </div>
                    <div className="col mb-4">
                      <h3 className="h5">{t("リンク設定")}</h3>
                      <div className="row row-cols-1 row-cols-md-2 row-cols-lg-1 g-2">
                        <div className="col">
                          <div className="form-floating">
                            <input
                              type="url"
                              className="form-control"
                              id="collectionLinkTwitter"
                              placeholder="Twitter"
                              onChange={(e) => {
                                const val = e.target.value;
                                set_props({
                                  ...props,
                                  link_twitter: val,
                                });
                              }}
                              value={props.link_twitter}
                            />
                            <label htmlFor="collectionLinkTwitter">Twitter</label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-floating">
                            <input
                              type="url"
                              className="form-control"
                              id="collectionLinkFacebbok"
                              placeholder="Facebook"
                              onChange={(e) => {
                                const val = e.target.value;
                                set_props({
                                  ...props,
                                  link_facebook: val,
                                });
                              }}
                              value={props.link_facebook}
                            />
                            <label htmlFor="collectionLinkFacebbok">Facebook</label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-floating">
                            <input
                              type="url"
                              className="form-control"
                              id="collectionLinkDiscord"
                              placeholder="Discord"
                              onChange={(e) => {
                                const val = e.target.value;
                                set_props({
                                  ...props,
                                  link_discord: val,
                                });
                              }}
                              value={props.link_discord}
                            />
                            <label htmlFor="collectionLinkDiscord">Discord</label>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-floating">
                            <input
                              type="url"
                              className="form-control"
                              id="collectionLinkInstagram"
                              placeholder="Instagram"
                              onChange={(e) => {
                                const val = e.target.value;
                                set_props({
                                  ...props,
                                  link_instagram: val,
                                });
                              }}
                              value={props.link_instagram}
                            />
                            <label htmlFor="collectionLinkInstagram">Instagram</label>
                          </div>
                        </div>
                        {/* <div className="col">
                          <div className="form-floating">
                            <input
                              type="url"
                              className="form-control"
                              id="collectionLinkWeb"
                              placeholder="\u305D\u306E\u4ED6URL"
                              onChange={(e) => {
                                const val = e.target.value;
                                set_props({
                                  ...props,
                                  link_other: val,
                                });
                              }}
                              value={props.link_other}
                            />
                            <label htmlFor="collectionLinkWeb">その他URL</label>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col">
                  <div className="mb-4">
                    <h3 className="h5">
                      プロパティ
                      <span
                        className="ms-1"
                        data-bs-toggle="tooltip"
                        data-bs-html="true"
                        title=""
                      >
                        <i className="fa-solid fa-circle-question" />
                      </span>
                    </h3>
                    <div className="row g-1 mb-2">
                      <div className="col-4">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="propertyName"
                            placeholder="\u30D7\u30ED\u30D1\u30C6\u30A3\u540D"
                            defaultValue="\u8272"
                          />
                          <label htmlFor="propertyName">プロパティ名</label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-floating mb-2">
                          <select
                            className="form-select"
                            id="propertyClass"
                            aria-label="\u30D7\u30ED\u30D1\u30C6\u30A3\u30BF\u30A4\u30D7"
                          >
                            <option selected>テキスト</option>
                            <option>数値</option>
                          </select>
                          <label htmlFor="propertyClass">プロパティタイプ</label>
                        </div>
                      </div>
                      <div className="col-auto my-auto">
                        <button
                          type="button"
                          className="btn btn-outline-danger border-0"
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          title=""
                        >
                          <i className="fa-solid fa-minus small" />
                        </button>
                      </div>
                    </div>
                    <div className="row g-1 mb-2">
                      <div className="col-4">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="propertyName"
                            placeholder="\u30D7\u30ED\u30D1\u30C6\u30A3\u540D"
                            defaultValue="\u30B3\u30F3\u30BB\u30D7\u30C8"
                          />
                          <label htmlFor="propertyName">プロパティ名</label>
                        </div>
                      </div>
                      <div className="col">
                        <div className="form-floating mb-2">
                          <select
                            className="form-select"
                            id="propertyClass"
                            aria-label="\u30D7\u30ED\u30D1\u30C6\u30A3\u30BF\u30A4\u30D7"
                          >
                            <option selected>テキスト</option>
                            <option>数値</option>
                          </select>
                          <label htmlFor="propertyClass">プロパティタイプ</label>
                        </div>
                      </div>
                      <div className="col-auto my-auto">
                        <button
                          type="button"
                          className="btn btn-outline-danger border-0"
                          data-bs-toggle="tooltip"
                          data-bs-html="true"
                          title=""
                        >
                          <i className="fa-solid fa-minus small" />
                        </button>
                      </div>
                    </div>
                    <div className="text-end">
                      <button
                        type="button"
                        className="btn btn-outline-primary rounded-pill ms-auto"
                      >
                        <i className="fa-solid fa-plus" />
                        <span className="ps-1">追加</span>
                      </button>
                    </div>
                  </div>
                  <div className="row row-cols-1 row-cols-md-2 row-cols-lg-1 g-3 g-sm-4 mb-4">
                    <div className="col">
                      <h3 className="h5 mb-3">公開制限</h3>
                      <div className="form-check-reverse form-switch mb-3">
                        <label className="form-check-label" htmlFor="regSee-1">
                          ウォッチリスト登録者
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="regSee-1"
                          defaultChecked
                        />
                      </div>
                      <div className="form-check-reverse form-switch mb-3">
                        <label className="form-check-label" htmlFor="regSee-2">
                          フォロワー
                        </label>
                        <input className="form-check-input" type="checkbox" id="regSee-2" />
                      </div>
                    </div>
                    <div className="col">
                      <h3 className="h5 mb-3">購入者制限</h3>
                      <div className="form-check-reverse form-switch mb-3">
                        <label className="form-check-label" htmlFor="regBuyer-1">
                          ウォッチリスト登録者
                        </label>
                        <input className="form-check-input" type="checkbox" id="regBuyer-1" />
                      </div>
                      <div className="form-check-reverse form-switch mb-3">
                        <label className="form-check-label" htmlFor="regBuyer-2">
                          フォロワー
                        </label>
                        <input className="form-check-input" type="checkbox" id="regBuyer-2" />
                      </div>
                    </div>
                  </div>
                  <div className="mb-4 mb-sm-4">
                    <h3 className="h5">予約公開</h3>
                    <p className="text-muted">予約公開の日時が未設定の場合すぐに公開されます。</p>
                    <div className="input-daterange hstack gap-2" id="datepicker">
                      <div className="form-floating w-100">
                        <input
                          type="text"
                          className="form-control"
                          id="itemDateStart"
                          name="start"
                          placeholder="\u516C\u958B\u65E5\u6642"
                        />
                        <label htmlFor="itemDateStart">公開日時</label>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              <div
                className="modal fade modal-alert py-5"
                id="processDialog"
                data-bs-keyboard="false"
                tabIndex={-1}
                role="dialog"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered" role="document">
                  <div className="modal-content rounded-4 shadow">
                    <div className="modal-body p-4 text-center">
                      <h5 className="mb-2">{t("確認")}</h5>
                      <p className="mb-0">{t("入力内容を保存してもいいですか？")}</p>
                    </div>
                    <div className="modal-footer flex-nowrap p-0">
                      <button
                        type="button"
                        className="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0"
                        data-bs-dismiss="modal"
                      >
                        {t("キャンセル")}
                      </button>
                      <button
                        type="button"
                        className="btn btn-lg btn-link fs-6 text-decoration-none col-6 m-0 rounded-0 border-right"
                        data-bs-target="#modalSuccessfully"
                        data-bs-toggle="modal"
                      >
                        <strong>{t("はい")}</strong>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {}
            <div
              className="modal fade"
              id="modalSuccessfully"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content rounded-6 shadow">
                  <div className="modal-body p-md-5">
                    <h2 className="fw-bold mb-0 text-center">{t("コレクションを変更しました!")}</h2>
                    <div className="row mt-4 mb-3">
                      <div className="col-4 mx-auto">
                        <div
                          htmlFor="collectionLogo"
                          className="ratio ratio-1x1 object-item icon icon-square"
                        >
                          <img
                            src="../example/collections/2-1-icon.webp"
                            className="icon-item"
                            alt
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 text-center">
                      <h5>{t("シェアしよう!")}</h5>
                      <aside className="mb-3" role="link">
                        <a
                          className="btn btn-dark btn-sm rounded-pill me-2"
                          href="#"
                          target="_blank"
                        >
                          <i className="fab fa-twitter text-white fa-fw" />
                        </a>
                        <a
                          className="btn btn-dark btn-sm rounded-pill me-2"
                          href="#"
                          target="_blank"
                        >
                          <i className="fab fa-facebook-f text-white fa-fw" />
                        </a>
                        <a
                          className="btn btn-dark btn-sm rounded-pill me-2"
                          href="#"
                          target="_blank"
                        >
                          <i className="fab fa-instagram text-white fa-fw" />
                        </a>
                        <a
                          className="btn btn-dark btn-sm rounded-pill me-2"
                          href="#"
                          target="_blank"
                        >
                          <i className="fab fa-discord text-white fa-fw" />
                        </a>
                        <a
                          className="btn btn-dark btn-sm rounded-pill me-2"
                          href="#"
                          target="_blank"
                        >
                          <i className="fab fa-linkedin-in text-white fa-fw" />
                        </a>
                        <a
                          className="btn btn-dark btn-sm rounded-pill me-2"
                          href="#"
                          target="_blank"
                        >
                          <i className="fa-brands fa-telegram fa-fw" />
                        </a>
                      </aside>
                    </div>
                    <a className="btn btn-lg rounded-pill btn-primary w-100" href="#">
                      {t("今すぐアイテムを作成")}
                    </a>
                    <a className="btn btn-lg rounded-pill btn-outline-primary mt-2 w-100" href="#">
                      {t("コレクション詳細へ")}
                    </a>
                    <a className="btn btn-lg rounded-pill btn-link mt-2 w-100" href="#">
                      {t("続けてコレクションを作成")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {}
          </main>
        </div>
      </div>
    </>
  );
}
