const env = {
  // REACT_APP_API_URL: "http://192.168.43.56:10001/api/v1/",
  // REACT_APP_SOCKET_URL:'ws://192.168.43.56:10002/socket/markets',

  // REACT_APP_API_URL: "http://192.168.11.14:10001/api/v1/",
  // REACT_APP_SOCKET_URL:'ws://192.168.11.14:10002/socket/markets',

  REACT_APP_API_URL: "https://www.tmty.io/api/v1/",
  REACT_APP_SOCKET_URL: "wss://www.tmty.io/socket/markets",

  // REACT_APP_API_URL: "http://localhost:10001/api/v1/",
  // REACT_APP_SOCKET_URL: "ws://localhost:10002/socket/notice",

  REACT_APP_API_URL: "https://admin.tmty.io/api/v1/",
  REACT_APP_SOCKET_URL: "wss://admin.tmty.io/socket/markets",

  // REACT_APP_API_URL: "http://localhost:10003/api/v1/",
  // REACT_APP_SOCKET_URL: "ws://localhost:10004/socket/notice",

  PROJECT_KEY: "8019d1cc-39c8-4c73-8c9d-9795d16e078d", //本番
  // PROJECT_KEY: "30d0f0b3-42b6-414f-a3fe-c6da97289b93", //staging

  REACT_APP_API_KEY: "817b8dc9-0a43-452a-aedb-9ebc21689634",
  // REACT_APP_API_KEY: "651448a6-99d3-11ec-b909-0242ac120002",

  mutable_token: false,
  ReCAPTCHA: "6Le2m2ogAAAAABvYI8RAl0sxkzPEU_Y6xm013N1K",
  SquareSandbox: true,
  SquareAPPLICATION_ID: "sandbox-sq0idb-7Oyd5x5HK94hOevWjCkXkA",
  SquareLOCATION_ID: "LBVZ6898CGMG3",
};

export default env;
