import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import Header from "../../views_components/Component/Header";
// import Sidebar from "./Sidebar";
import { view_collection } from "../../stores/Collections/view";
import { view_user } from "../../stores/Users/view";
import _sidebar from "./_sidebar";
import _modalfollow from "./_modalfollow";
import _mainheader from "./_mainheader";

import CollectionsPager from "../../views_components/PagerCollections/CollectionsPager";
import CollectionsList from "../../views_components/PagerCollections/CollectionsList";
import CustomCircle from "components/CustomCircle/CustomCircle.js";

import ItemsPager from "../../views_components/PagerItems/ItemsPager";
import ItemsList from "../../views_components/PagerItems/ItemsList";

import ItemOffersPager from "../../views_components/PagerItemOffers/ItemOffersPager";
import ItemOffersList from "../../views_components/PagerItemOffers/ItemOffersList";

import ActivitiesPager from "../../views_components/PagerActivities/ActivitiesPager";
import ActivitiesList from "../../views_components/PagerActivities/ActivitiesList";
import Footer from "../../views_components/Component/Footer";

export default function Contents({ Page, Collections, Items, ItemOffers, Activities, Users }) {
  const { type, dispatch_type } = Page;

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_users = useSelector((state) => state.reduce_get_users);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  // const collection = useSelector((state) => state.reduce_get_items.collection);

  const user = reduce_get_users.user;
  const v_user = view_user(user);

  // const collection = useSelector((state) => state.reduce_get_collections.collection);
  // const v_collection = view_collection(collection);

  const collection = useSelector((state) => state.reduce_get_items.collection);
  const v_collection = view_collection(collection);

  return (
    <>
      <div>
        <Header />
        <div className="container container-main flex-md-column flex-lg-row">
          {_sidebar(Users)}
          <main className="main">
            {_mainheader(Page)}
            {(type == "created" || type == "collections" || type == "favoritecollections") && (
              <>
                <CollectionsPager Collections={Collections} />
                <div className="ms-md-3 mt-0">
                  <div className="row row-cols-2 row-cols-sm-3 row-cols-md-2 row-cols-lg-3 g-2 g-md-3 mb-3">
                    <CollectionsList Collections={Collections} />
                  </div>
                </div>
              </>
            )}
            {type == "collection-items" && (
              <>
                <div className="main-toolbar mb-0 py-3 bg-light">
                  <h3 className="h5 mb-0 text-truncate">
                    {/* <a href="javascript:history.back();" */}
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch_type("collections");
                      }}
                    >
                      <CustomCircle
                        title={
                          <>
                            <i className="fa-solid fa-angle-left fa-fw pe-2" />
                            {v_collection.collection_name}
                          </>
                        }
                        loading={Items.loading}
                        size={20}
                      />
                    </a>
                  </h3>
                </div>
                <ItemsPager Items={Items} />
                <div className="ms-md-3 mt-0">
                  <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-xxl-4 g-2 g-sm-3">
                    <ItemsList Items={Items} />
                  </div>
                </div>
              </>
            )}
            {type == "items" && (
              <>
                <ItemsPager Items={Items} />
                <div className="ms-md-3 mt-0">
                  <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-xxl-4 g-2 g-sm-3">
                    <ItemsList Items={Items} />
                  </div>
                </div>
              </>
            )}
            {type == "favoriteitems" && (
              <>
                <ItemsPager Items={Items} />
                <div className="ms-md-3 mt-0">
                  <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-xxl-4 g-2 g-sm-3">
                    <ItemsList Items={Items} />
                  </div>
                </div>
              </>
            )}
            {type == "itemoffers" && (
              <>
                <ItemOffersPager ItemOffers={ItemOffers} />
                <div className="ms-md-3 mt-0">
                  <div className="activitys">
                    <ItemOffersList ItemOffers={ItemOffers} />
                  </div>
                </div>
              </>
            )}
            {type == "activities" && (
              <>
                <ActivitiesPager Activities={Activities} />
                <div className="ms-md-3 mt-0">
                  <div className="activitys">
                    <ActivitiesList Activities={Activities} />
                  </div>
                </div>
              </>
            )}
          </main>
        </div>
        {_modalfollow(Users)}
        <Footer />
      </div>
    </>
  );
}
