import React, { useState, useEffect, Component } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { toMomentString } from "config/util";
import { put_users_id } from "stores/Users/actions";
import { getMessage } from "config/util";

import { view_user } from "../../stores/Users/view";
import { HeaderItem, RowItem } from "./_item";
import { HeaderUser, RowUser } from "./_user";
import { HeaderSend, RowSend } from "./_send";

export default function Dashboard({ Users, Items }) {
  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();

  const maps = useSelector((state) => state.reduce_get_maps);

  const datalist = useSelector((state) => state.reduce_get_users.users);
  const loading = useSelector((state) => state.reduce_get_users.isLoading);
  const loadingId = useSelector((state) => state.reduce_get_users.isLoadingId);
  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const dispatch_put_users_id = (data, next, prev) => {
    dispatch(
      put_users_id({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };
  Users.dispatch_put_users_id = dispatch_put_users_id;
  Users.t = t;
  Users.history = history;

  if (loading) return <></>;

  return (
    <>
      {Users.list == "user" && (
        <>
          {HeaderUser(Users)}
          {datalist.map((prop, idx) => {
            const v_user = view_user(prop, maps, t);
            Users.v_user = v_user;
            return <>{RowUser(Users)}</>;
          })}
        </>
      )}
      {Users.list == "item" && (
        <>
          {/* <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-3"> */}
          {/* {HeaderItem(Users)} */}
          {datalist.map((prop, idx) => {
            const v_user = view_user(prop, maps, t);
            Users.v_user = v_user;

            return <>{RowItem(Users)}</>;
          })}
          {/* </div> */}
        </>
      )}
      {Users.list == "send" && (
        <>
          {/* <div className="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-3"> */}
          {/* {HeaderItem(Users)} */}
          {datalist.map((prop, idx) => {
            const v_user = view_user(prop, maps, t);
            Users.v_user = v_user;

            return <>{RowSend(Users, Items)}</>;
          })}
          {/* </div> */}
        </>
      )}
    </>
  );
}
