import React, { useState, useEffect, Component } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { toMomentString } from "config/util";
import { getMessage } from "config/util";

import { view_collection } from "../../stores/Collections/view";
import { put_collections_id_favorite } from "stores/Collections/actions";
import { HeaderItem, RowItem } from "./_item";
import { HeaderUser, RowUser } from "./_user";
import { HeaderExplore, RowExplore } from "./_explore";

export default function Dashboard({ Collections }) {
  const {
    propsCollections,
    set_propsCollections,
    dispatch_gets,
    dispatch_type,
    type,
    mypage,
    list,
    user_id,
  } = Collections;

  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();
  const maps = useSelector((state) => state.reduce_get_maps);

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const dispatch_put_collections_id_favorite = (data, next, prev) => {
    dispatch(
      put_collections_id_favorite({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const datalist_collections = useSelector((state) => state.reduce_get_collections.collections);
  const loading_collections = useSelector((state) => state.reduce_get_collections.isLoading);
  const loadingId = useSelector((state) => state.reduce_get_collections.isLoadingId);

  Collections.dispatch_put_collections_id_favorite = dispatch_put_collections_id_favorite;
  Collections.t = t;
  Collections.history = history;

  let datalist = datalist_collections;
  let loading = loading_collections;

  if (loading) return <></>;

  return (
    <>
      {Collections.list == "explore" && (
        <>
          {HeaderExplore(Collections)}
          {datalist.map((prop, idx) => {
            const v_collection = view_collection(prop, maps, t);
            Collections.v_collection = v_collection;
            return <>{RowExplore(Collections)}</>;
          })}
        </>
      )}
      {Collections.list == "user" && (
        <>
          {HeaderUser(Collections)}
          {datalist.map((prop, idx) => {
            const v_collection = view_collection(prop, maps, t);
            Collections.v_collection = v_collection;
            return <>{RowUser(Collections)}</>;
          })}
        </>
      )}
      {Collections.list == "item" && (
        <>
          {HeaderItem(Collections)}
          {datalist.map((prop, idx) => {
            const v_collection = view_collection(prop, maps, t);
            Collections.v_collection = v_collection;
            return <>{RowItem(Collections)}</>;
          })}
        </>
      )}
    </>
  );
}
