import React, { useState, useEffect, Component } from "react";
import {
  toMomentString,
  toLowUrl,
  toHighUrl,
  moment2obj,
  moment2countdown,
  countdown2label,
  zeroPadding,
  toNumber,
} from "config/util";

import { view_item } from "../../stores/Items/view";
import { view_collection } from "../../stores/Collections/view";

export const view_activity = (prop, maps, t) => {
  if (!prop) return {};
  if (!maps) return {};

  const coin_decimals = maps.coin_decimals;
  let resView = {
    ...prop,
    // activity_name: prop.name,
    // activity_type: prop.type,
    // activity_price: prop.price,

    // activity_created_at: toMomentString(prop.created_at, "YYYY-MM-DD HH:mm"),
    // activity_created_at_label: toMomentString(prop.created_at, "YYYY-MM-DD HH:mm"),
    // activity_user_name_from: prop.user_name_from,
    // activity_user_name_to: prop.user_name_to,
  };

  const created_at_countdown = moment2countdown(prop.created_at);
  resView.created_at_countdown_label = countdown2label(created_at_countdown, t);

  const item = prop.item;
  if (item) {
    resView = {
      ...resView,
      ...view_item(item, maps, t),
    };
  }

  const collection = prop.collection;
  if (collection) {
    resView = {
      ...resView,
      ...view_collection(collection),
    };
  }

  const transaction_hash = prop.data?.transaction_hash;
  const platform_id = prop.data?.platform_id;

  if (transaction_hash && platform_id) {
    resView.explorer_uri =
      platform_id == 2
        ? // ? `https://rinkeby.etherscan.io/tx/${transaction_hash}`
          `https://etherscan.io/tx/${transaction_hash}`
        : platform_id == 4
        ? `https://www.iostabc.com/tx/${transaction_hash}`
        : platform_id == 10
        ? `https://polygonscan.com/tx/${transaction_hash}`
        : "";
  }

  resView.activity_icon_uri =
    prop.activity_type == 10 ? resView.collection_icon_uri : resView.item_icon_uri;

  resView.activity_link =
    prop.activity_type == 10
      ? `/collections/${resView.collection_id}/items`
      : `/items/${resView.item_id}`;

  resView.activity_price_has = prop.activity_price && prop.activity_price != 0;
  // resView.activity_amount_has = prop.activity_amount && prop.activity_amount != 0;

  resView.activity_amount_label = prop.activity_amount == 0 ? "" : prop.activity_amount;
  resView.activity_price_label = prop.activity_price == 0 ? "" : prop.activity_price;

  resView.activity_price_usd_label = toNumber(prop.item_price_usd, coin_decimals["USDT"]);

  resView.coin_label =
    prop.coin_id == 1
      ? "BTC"
      : prop.coin_id == 2
      ? "ETH"
      : prop.coin_id == 4
      ? "IOST"
      : prop.coin_id == 5
      ? "USDT"
      : "";

  resView.coin_icon_tag =
    prop.coin_id == 1 ? (
      <i className="icomoon-brand-btc btc-color" />
    ) : prop.coin_id == 2 ? (
      <i className="icomoon-brand-eth eth-color" />
    ) : prop.coin_id == 4 ? (
      <i className="icomoon-brand-iost iost-color" />
    ) : prop.coin_id == 5 ? (
      <i className="icomoon-brand-usdt usdt-color" />
    ) : (
      <></>
    );

  resView.activity_from = prop.data?.address_from;
  resView.activity_to = prop.data?.address_to;
  return resView;
};

// import { view_activity } from "../../stores/Collections/view";
// const activity = useSelector((state) => state.reduce_get_activities.activity);
// const v_activity = view_activity(activity);
