import React, { useState, useEffect, Component } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { toMomentString } from "config/util";
import { getMessage } from "config/util";
import CustomToolTip from "components/CustomToolTip/CustomToolTip.js";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";

import { view_collection } from "../../stores/Collections/view";
import { put_collections_id_favorite } from "stores/Collections/actions";

export default function Dashboard(Collections) {
  const {
    propsCollections,
    set_propsCollections,
    dispatch_gets,
    dispatch_type,
    type,
    mypage,
    list,
    user_id,
  } = Collections;

  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();
  const maps = useSelector((state) => state.reduce_get_maps);

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const dispatch_put_collections_id_favorite = (data, next, prev) => {
    dispatch(
      put_collections_id_favorite({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };
  const datalist = useSelector((state) => state.reduce_get_collections.collections);

  const loading_collections = useSelector((state) => state.reduce_get_collections.isLoading);
  const loadingId = useSelector((state) => state.reduce_get_collections.isLoadingId);

  let loading = loading_collections;

  if (loading)
  return (
    <>
      <section className="arcade-content">
        <div className="arcade">
          <CustomLinearProgress color="primary" />
        </div>
      </section>
    </>
  );


  let col_all = [];

  let col_chuck_0 = [];
  let col_chuck_1 = [];
  let col_chuck_2 = [];
  let col_chuck_3 = [];

  if (datalist.length != 0) {
    while (col_all.length < 80) {
      col_all = col_all.concat(datalist);
    }

    const chunk = (ary, number) => {
      const length = Math.ceil(ary.length / number);
      return new Array(length).fill().map((_, i) => ary.slice(i * number, (i + 1) * number));
    };

    const col_chuck = chunk(col_all, 20);

    col_chuck_0 = col_chuck[0];
    col_chuck_1 = col_chuck[1];
    col_chuck_2 = col_chuck[2];
    col_chuck_3 = col_chuck[3];
  }

  const List = ({ v_collection }) => {
    return (
      <li className="d-flex icon icon-square position-relative">
        <CustomToolTip title={v_collection.collection_name}>
          <div
            className="object-item"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Collection title..."
          >
            <a
              className="stretched-link text-dark"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push(`/collections/${v_collection.collection_id}/items`);
              }}
            >
              <img
                // src={`/dist/tmty/example/collections/R/.jpg`}
                src={v_collection.collection_icon_uri}
                className="icon-item"
                alt="Collection title..."
              />
            </a>
          </div>
        </CustomToolTip>
      </li>
    );
  };

 

  return (
    <>
      <section className="arcade-content">
        <div className="arcade">
          <ul className="arcade-item d-flex p-0">
            {col_chuck_0.map((c) => {
              const v_collection = view_collection(c);
              return <List v_collection={v_collection} />;
            })}
          </ul>
          <ul className="arcade-item d-flex p-0">
            {col_chuck_0.map((c) => {
              const v_collection = view_collection(c);
              return <List v_collection={v_collection} />;
            })}
          </ul>
        </div>
        <div className="arcade arcade-end">
          <ul className="arcade-item d-flex p-0">
            {col_chuck_1.map((c) => {
              const v_collection = view_collection(c);
              return <List v_collection={v_collection} />;
            })}
          </ul>
          <ul className="arcade-item d-flex p-0">
            {col_chuck_1.map((c) => {
              const v_collection = view_collection(c);
              return <List v_collection={v_collection} />;
            })}
          </ul>
        </div>
        <div className="arcade">
          <ul className="arcade-item d-flex p-0">
            {col_chuck_2.map((c) => {
              const v_collection = view_collection(c);
              return <List v_collection={v_collection} />;
            })}
          </ul>
          <ul className="arcade-item d-flex p-0">
            {col_chuck_2.map((c) => {
              const v_collection = view_collection(c);
              return <List v_collection={v_collection} />;
            })}
          </ul>
        </div>
        <div className="arcade arcade-end">
          <ul className="arcade-item d-flex p-0">
            {col_chuck_3.map((c) => {
              const v_collection = view_collection(c);
              return <List v_collection={v_collection} />;
            })}
          </ul>
          <ul className="arcade-item d-flex p-0">
            {col_chuck_3.map((c) => {
              const v_collection = view_collection(c);
              return <List v_collection={v_collection} />;
            })}
          </ul>
        </div>
      </section>
    </>
  );
}
