import React, { useState, useEffect, Component } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "config/util";

export const HeaderItem = (Users) => {
  const { propsUsers, set_propsUsers, dispatch_gets, user_id } = Users;
  return <></>;
};

export const RowSend = (Users, Items) => {
  const { propsItems, set_propsItems, dispatch_put_items_id, loadingId } = Items;
  const { v_user, history, t, dispatch_post_send, set_item_send } = Users;

  return (
    <>
      <div className="col">
        <div className="card card-body text-start overflow-hidden position-relative p-3">
          <div className="media">
            <div className="media-object">
              {/* <div className="icon icon-pill verified"> */}
              <div className="icon icon-pill">
                <img
                  src={v_user.user_icon_uri}
                  className="icon-item"
                  width="100%"
                  height="auto"
                  alt="tomonity official"
                />
              </div>
            </div>
            <div className="media-body text-truncate overflow-hidden">
              <h3 className="media-title mb-0">
                <a
                  className="stretched-link text-dark"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(`/users/${v_user.user_id}/items`);
                  }}
                >
                  {/* <div className="d-flex verified-text"> */}
                  <span className="text-truncate">{v_user.user_name}</span>
                  {/* </div> */}
                </a>
              </h3>
              <span className="d-block text-muted text-truncate">@{v_user.user_nmid}</span>
            </div>
            <div
              className="flex-shrink-0 ms-3"
              style={{
                zIndex: 2,
              }}
            >
              {!v_user.myself && (
                <button
                  type="button"
                  className={`btn btn-outline-dark rounded-pill ${
                    v_user.user_favorited_my ? "" : ""
                  }`}
                  role="button"
                  // data-bs-toggle="button"
                  autoComplete="off"
                  // aria-pressed={v_user.user_favorited_my ? "true" : "false"}
                  // aria-pressed={v_user.user_favorited_my ? "false" : "false"}
                  aria-pressed={false}
                  onClick={(e) => {
                    e.preventDefault();
                    showModal("itemSendModal");

                    set_item_send({
                      item_id: v_user.user_id,
                      item_detail_id: propsItems.item_detail_id,
                      user_id: v_user.user_id,
                      user_icon_uri: v_user.user_icon_uri,
                      user_id: v_user.user_id,
                      user_name: v_user.user_name,
                      user_nmid: v_user.user_nmid,
                    });

                    // dispatch_post_send(
                    //   {
                    //     item_id: v_user.user_id,
                    //     item_detail_id: propsItems.item_detail_id,
                    //     user_id: v_user.user_id,
                    //   },
                    //   () => {
                    //     history.goBack();
                    //   },
                    //   () => {}
                    // );
                  }}
                >
                  {t("転送する")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
