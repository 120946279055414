import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import HeaderAdmin from "../../views_components/Component/HeaderAdmin";
import Footer from "../../views_components/Component/Footer";
import { getMessage } from "config/util";

import { dashboard, holderadmin, holderuser } from "Navigator/Router_material";
import ReactWOW from "react-wow";
import { post_authout } from "../../stores/Auth/actions";
import { view_collection } from "../../stores/Collections/view";

export default function Contents({ Home }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const [height, set_height] = useState();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  useEffect(() => {
    set_height(window.jQuery("#header1").height());
  }, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  return (
    <>
      <div>
        <HeaderAdmin />
        <div className="container-main">
          <main className="main mt-2 mt-sm-0 pb-0">
            <section className="bg-white pb-5">
              <div className="container py-5 text-center" >
                <h3 className="mb-4 mb-sm-5 text-center">{t("Admin")}</h3>
                <img src="/dist/tmty/img/Tomonity.jpg.optimal.jpg" alt="..." width={400}/>
              </div>
            </section>
          </main>
        </div>
      </div>
    </>
  );
}
