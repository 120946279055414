import React, { useState, useEffect, Component } from "react";
import {
  toMomentString,
  toLowUrl,
  toHighUrl,
  moment2obj,
  moment2countdown,
  countdown2label,
  zeroPadding,
  toNumber,
  toKM,
  createBr,
} from "config/util";

export const view_collection = (prop, maps, t) => {
  if (!prop) return {};

  const resView = {
    ...prop,
    active_flg_label: prop.active_flg ? "表示する" : "表示しない",
    // image_cover_path: toLowUrl(prop.image_cover_uri, prop.mime_type),
    // image_cover_path_low: toLowUrl(prop.image_cover_uri, prop.mime_type),
    // image_cover_path_high: toHighUrl(prop.image_cover_uri, prop.mime_type),
    // image_icon_path: toLowUrl(prop.image_icon_uri, prop.mime_type),
    // image_icon_path_low: toLowUrl(prop.image_icon_uri, prop.mime_type),
    // image_icon_path_high: toHighUrl(prop.image_icon_uri, prop.mime_type),
    collection_description_br: createBr(prop.collection_description),

    collection_user_icon_uri: prop.collection_user_icon_uri
      ? prop.collection_user_icon_uri
      : "/dist/tmty/assets/default/icon.webp",
    collection_user_icon_mime: prop.collection_user_icon_mime ? prop.collection_user_icon_mime : "",
    collection_user_id: prop.collection_user_id,
    collection_nmid: prop.collection_id,
    collection_icon_uri: prop.collection_icon_uri
      ? prop.collection_icon_uri
      : "/dist/tmty/assets/default/icon.webp",
    collection_icon_mime: prop.collection_icon_mime ? prop.collection_icon_mime : "",
    collection_cover_uri: prop.collection_cover_uri
      ? prop.collection_cover_uri
      : "/dist/tmty/assets/default/cover.webp",
    collection_cover_mime: prop.collection_cover_mime ? prop.collection_cover_mime : "",
  };

  resView.category_icon_tag =
    prop.category_id == "c801984a-f926-4ffc-a8c1-c48bc4307d0a" ? (
      <i className="fa-solid fa-brush me-1" />
    ) : prop.category_id == "1fc59b04-1a89-4644-a73a-7e82fcf8efbc" ? (
      <i className="fa-solid fa-lightbulb me-1" />
    ) : prop.category_id == "f31f2447-8800-4ff2-a36d-d82b475bba59" ? (
      <i className="fa-solid fa-at me-1" />
    ) : prop.category_id == "853c87ab-076a-417d-b2e0-a3a4d8c13492" ? (
      <i className="fa-solid fa-itunes-note me-1" />
    ) : prop.category_id == "6fc94309-7a9a-41a0-b520-cfc72dde88fc" ? (
      <i className="fa-solid fa-camera-retro me-1" />
    ) : prop.category_id == "9bd3e56a-6321-4a41-8801-51780bc8866b" ? (
      <i className="fa-solid fa-baseball-bat-ball me-1" />
    ) : prop.category_id == "a0934b49-6337-41ae-ad89-21e1356154cf" ? (
      <i className="fa-solid fa-gamepad me-1" />
    ) : prop.category_id == "c49aded5-4f2c-4140-9056-a926774600ee" ? (
      <i className="fa-solid fa-box-open me-1" />
    ) : prop.category_id == "6b99b864-f386-4b67-8130-3a82fb0bbe47" ? (
      <i className="fa-solid fa-vr-cardboard me-1" />
    ) : prop.category_id == "" ? (
      <i className="fa-solid fa-xxx me-1" />
    ) : (
      <></>
    );

  resView.category_icon_link =
    prop.category_id == "c801984a-f926-4ffc-a8c1-c48bc4307d0a"
      ? "/categories/id/c801984a-f926-4ffc-a8c1-c48bc4307d0a"
      : prop.category_id == "1fc59b04-1a89-4644-a73a-7e82fcf8efbc"
      ? "/categories/id/1fc59b04-1a89-4644-a73a-7e82fcf8efbc"
      : prop.category_id == "f31f2447-8800-4ff2-a36d-d82b475bba59"
      ? "/categories/id/f31f2447-8800-4ff2-a36d-d82b475bba59"
      : prop.category_id == "853c87ab-076a-417d-b2e0-a3a4d8c13492"
      ? "/categories/id/853c87ab-076a-417d-b2e0-a3a4d8c13492"
      : prop.category_id == "6fc94309-7a9a-41a0-b520-cfc72dde88fc"
      ? "/categories/id/6fc94309-7a9a-41a0-b520-cfc72dde88fc"
      : prop.category_id == "9bd3e56a-6321-4a41-8801-51780bc8866b"
      ? "/categories/id/9bd3e56a-6321-4a41-8801-51780bc8866b"
      : prop.category_id == "a0934b49-6337-41ae-ad89-21e1356154cf"
      ? "/categories/id/a0934b49-6337-41ae-ad89-21e1356154cf"
      : prop.category_id == "c49aded5-4f2c-4140-9056-a926774600ee"
      ? "/categories/id/c49aded5-4f2c-4140-9056-a926774600ee"
      : prop.category_id == "6b99b864-f386-4b67-8130-3a82fb0bbe47"
      ? "/categories/id/6b99b864-f386-4b67-8130-3a82fb0bbe47"
      : prop.category_id == ""
      ? "/categories"
      : "";

  if (maps) {
    const coin_decimals = maps.coin_decimals;

    resView.sum_price_usd_label = toNumber(prop.sum_price_usd, coin_decimals["USDT"]);
    // resView.min_price_usd_label = toNumber(prop.min_price_usd, coin_decimals["USDT"]);

    resView.sum_price_usd_label2 = toKM(prop.sum_price_usd, coin_decimals["USDT"]);
    // resView.min_price_usd_label2 = toKM(prop.min_price_usd, coin_decimals["USDT"]);

    // resView.sum_price_usd_label3 = toKM(12345000, coin_decimals["USDT"]);
  }

  return resView;
};

// import { view_collection } from "../../stores/Collections/view";
// const collection = useSelector((state) => state.reduce_get_collections.collection);
// const v_collection = view_collection(collection);
