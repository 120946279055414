import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import { view_item } from "../../stores/Items/view";
import ActivitiesPager from "../../views_components/PagerActivities/ActivitiesPager";
import ActivitiesList from "../../views_components/PagerActivities/ActivitiesList";

export default function Contents(Activities) {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  // const collection = useSelector((state) => state.reduce_get_collections.collection);
  // const v_collection = view_collection(collection);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const item = useSelector((state) => state.reduce_get_items.item);

  return (
    <>
      <div className="accordion mb-3 d-none d-lg-block" id="activityPanels">
        <div className="accordion-item">
          <h3 className="accordion-header" id="activity-heading">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#activity-collapse"
              aria-expanded="true"
              aria-controls="activity-collapse"
            >
              {t("アイテムのアクティビティ")}
            </button>
          </h3>
          <div
            id="activity-collapse"
            className="accordion-collapse collapse show"
            aria-labelledby="activity-heading"
          >
            <div className="accordion-body pb-0">
              <div className="row mb-2">
                <div className="col-md-4 ms-auto">
                  <div data-bs-toggle="tooltip" title="">
                    <select
                      id="filterGrpSelect"
                      className="selectpicker form-control"
                      multiple
                      data-style="btn-white border"
                      aria-label="\u30D5\u30A3\u30EB\u30BF"
                      title=""
                    >
                      <option data-icon="fa-solid fa-tag" selected>
                        {t("出品")}
                      </option>
                      <option data-icon="fa-solid fa-cart-shopping" selected>
                        {t("販売")}
                      </option>
                      <option data-icon="fa-solid fa-gavel">{t("入札")}</option>
                      <option data-icon="fa-solid fa-right-left">{t("転送")}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="activitys">
                <ActivitiesPager Activities={Activities} />
                <ActivitiesList Activities={Activities} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
