import { fork, take, takeEvery, put, join, select, call } from "redux-saga/effects";

import {
  api_post_authin,
  api_post_authin_code,
  api_post_register,
  api_post_register_code,
  api_post_register_resend,
  api_post_password,
  api_post_password_code,
  api_post_password_reset,
  api_get_maps,
  api_post_contact,
} from "./api";

import {
  get_maps,
  get_maps_data,
  get_maps_error,
  post_contact,
  post_contact_data,
  post_contact_error,
  post_authin,
  post_authin_data,
  post_authin_error,
  post_authout,
  post_authouthome,
  post_authin_code,
  post_authin_code_data,
  post_authin_code_error,
  post_register,
  post_register_data,
  post_register_error,
  post_register_code,
  post_register_code_data,
  post_register_code_error,
  post_register_resend,
  post_register_resend_data,
  post_register_resend_error,
  post_password,
  post_password_data,
  post_password_error,
  post_password_code,
  post_password_code_data,
  post_password_code_error,
  post_password_reset,
  post_password_reset_data,
  post_password_reset_error,
} from "./actions";

import { get_accounts } from "../Accounts/actions";
import { get_wallets } from "../Wallets/actions";
import { login } from "../../Navigator/Router_material";

export function* flow_post_authout() {
  while (true) {
    const { payload } = yield take(post_authout);
    setTimeout(() => {
      window.location.reload();
      window.location.pathname = login.replace("/", "");
    }, 2000);
    !payload.next || payload.next();
  }
}

export function* flow_post_authouthome() {
  while (true) {
    const { payload } = yield take(post_authouthome);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
    !payload.next || payload.next();
  }
}

export function* flow_post_authin() {
  while (true) {
    const { payload } = yield take(post_authin);

    const { data, error } = yield call(api_post_authin, payload.data);
    if (!error) {
      yield put(post_authin_data({ data }));
      if (login != "/adminin") {
        yield put(get_accounts({}));
        yield put(get_wallets({}));
      }

      !payload.next || payload.next(data);
    } else {
      yield put(post_authin_error({ error }));
      if (error.response && error.response.status === 401) yield put(post_authout({}));
      !payload.prev || payload.prev(error);
    }
  }
}

export function* flow_post_authin_code() {
  while (true) {
    const { payload } = yield take(post_authin_code);
    const { hash } = yield select((state) => state.reduce_post_authin);

    const { data, error } = yield call(api_post_authin_code, payload.data, hash);

    if (!error) {
      yield put(post_authin_data({ data }));
      yield put(get_accounts({}));
      yield put(get_wallets({}));
      // yield put(post_authin_code_data({ data }));
      !payload.next || payload.next(data);
    } else {
      yield put(post_authin_code_error({ error }));
      if (error.response && error.response.status === 401) yield put(post_authout({}));
      !payload.prev || payload.prev(error);
    }
  }
}

export function* flow_post_register() {
  while (true) {
    const { payload } = yield take(post_register);

    const { data, error } = yield call(api_post_register, payload.data);
    if (!error) {
      yield put(post_register_data({ data }));

      !payload.next || payload.next(data);
    } else {
      yield put(post_register_error({ error }));
      if (error.response && error.response.status === 401) yield put(post_authout({}));
      !payload.prev || payload.prev(error);
    }
  }
}

export function* flow_post_register_code() {
  while (true) {
    const { payload } = yield take(post_register_code);

    const { hash } = yield select((state) => state.reduce_post_register);

    const { data, error } = yield call(api_post_register_code, payload.data, hash);
    if (!error) {
      yield put(post_register_code_data({ data }));
      yield put(post_authin_data({ data }));

      !payload.next || payload.next(data);
    } else {
      yield put(post_register_code_error({ error }));
      if (error.response && error.response.status === 401) yield put(post_authout({}));
      !payload.prev || payload.prev(error);
    }
  }
}

export function* flow_post_register_resend() {
  while (true) {
    const { payload } = yield take(post_register_resend);

    const { hash } = yield select((state) => state.reduce_post_register);

    const { data, error } = yield call(api_post_register_resend, payload.data, hash);
    if (!error) {
      yield put(post_register_resend_data({ data }));
      yield put(post_authin_data({ data }));

      !payload.next || payload.next(data);
    } else {
      yield put(post_register_resend_error({ error }));
      if (error.response && error.response.status === 401) yield put(post_authout({}));
      !payload.prev || payload.prev(error);
    }
  }
}

export function* flow_post_password() {
  while (true) {
    const { payload } = yield take(post_password);

    const { data, error } = yield call(api_post_password, payload.data);
    if (!error) {
      yield put(post_password_data({ data }));

      !payload.next || payload.next(data);
    } else {
      yield put(post_password_error({ error }));
      if (error.response && error.response.status === 401) yield put(post_authout({}));
      !payload.prev || payload.prev(error);
    }
  }
}

export function* flow_post_password_code() {
  while (true) {
    const { payload } = yield take(post_password_code);

    const { hash } = yield select((state) => state.reduce_post_password);

    const { data, error } = yield call(api_post_password_code, payload.data, hash);
    if (!error) {
      yield put(post_password_code_data({ data }));

      !payload.next || payload.next(data);
    } else {
      yield put(post_password_code_error({ error }));
      if (error.response && error.response.status === 401) yield put(post_authout({}));
      !payload.prev || payload.prev(error);
    }
  }
}

export function* flow_post_password_reset() {
  while (true) {
    const { payload } = yield take(post_password_reset);

    const { hash } = yield select((state) => state.reduce_post_password_code);

    const { data, error } = yield call(api_post_password_reset, payload.data, hash);
    if (!error) {
      yield put(post_password_reset_data({ data }));

      !payload.next || payload.next(data);
    } else {
      yield put(post_password_reset_error({ error }));
      if (error.response && error.response.status === 401) yield put(post_authout({}));
      !payload.prev || payload.prev(error);
    }
  }
}

export function* flow_get_maps() {
  while (true) {
    const { payload } = yield take(get_maps);

    const { data, error } = yield call(api_get_maps, payload.data);
    if (!error) {
      yield put(get_maps_data({ data }));

      !payload.next || payload.next(data);
    } else {
      yield put(get_maps_error({ error }));
      if (error.response && error.response.status === 401) yield put(post_authout({}));
      !payload.prev || payload.prev(error);
    }
  }
}

export function* flow_post_contact() {
  while (true) {
    const { payload } = yield take(post_contact);

    const { data, error } = yield call(api_post_contact, payload.data);
    if (!error) {
      yield put(post_contact_data({ data }));

      !payload.next || payload.next(data);
    } else {
      yield put(post_contact_error({ error }));
      if (error.response && error.response.status === 401) yield put(post_authout({}));
      !payload.prev || payload.prev(error);
    }
  }
}

export default function* saga() {
  yield fork(flow_post_authout);
  yield fork(flow_post_authouthome);

  yield fork(flow_post_authin);
  yield fork(flow_post_authin_code);
  yield fork(flow_post_register);
  yield fork(flow_post_register_code);
  yield fork(flow_post_register_resend);
  yield fork(flow_post_password);
  yield fork(flow_post_password_code);
  yield fork(flow_post_password_reset);

  yield fork(flow_get_maps);
  yield fork(flow_post_contact);
}
