import React, { FC, useEffect, useRef, useState, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";
import { initReactI18next, useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomLoading from "components/CustomLoading/CustomLoading.js";
import { DisplayContext } from "../../Navigator/Router_material";
import { useParams, useLocation, useHistory } from "react-router-dom";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { getMessage, toMomentString } from "config/util";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import moment from "moment";

import { getSearch, setSearch } from "config/util";
import MainFixed from "../../components/CustomLoadingScreen/MainFixed";

export default function Dashboard(TopCollections) {
  const { propsTopCollections, set_propsTopCollections, dispatch_gets, type } = TopCollections;
  const history = useHistory();
  const dispatch = useDispatch();

  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const location_search = useLocation().search;

  const datalist_collections = useSelector((state) => state.reduce_get_top_collections.collections);
  const count_collections = useSelector((state) => state.reduce_get_top_collections.count);
  const loading_collections = useSelector((state) => state.reduce_get_top_collections.isLoading);

  let datalist = datalist_collections;
  let count = count_collections;
  let loading = loading_collections;

  useEffect(() => {
    // dispatch_gets(propsTopCollections.search, 1);
  }, []);

  const [t, i18n] = useTranslation();

  const theme = useTheme();
  const primary = theme.palette.primary.main;

  const { windowHeight, tableHeight } = useContext(DisplayContext);

  const location = useLocation();
  const nonce = location.nonce;
  const [type_button, set_type_button] = useState("month");

  const [mount, set_mount] = React.useState(true);
  const [load, set_load] = useState(true);
  const ref_load = useRef(load);

  useEffect(() => {
    ref_load.current = load;
  }, [load]);

  useEffect(() => {
    if (ref_load.current) {
      set_load(loading);
    }
  }, [loading]);

  useEffect(() => {
    set_mount(false);
    set_load(true);
  }, [nonce]);

  useEffect(() => {
    set_mount(true);
  }, [mount]);

  if (!mount) {
    return <></>;
  }

  if (!datalist) {
    return <></>;
  }

  const height = tableHeight;

  return (
    <>
      {/* <MainFixed loading={loading && ref_load.current} /> */}
      <Snackbar
        place="tc"
        color={color}
        icon={AddAlert}
        message={message}
        open={snack}
        closeNotification={() => {
          set_snack(false);
        }}
        close
      />
      {/* <div className="btn-group me-2" role="group" aria-label="First group">
        <button type="button" className="btn btn-white-link py-3 disabled">
          <i className="fa-solid fa-angle-left me-2" />1 – 100
        </button>
        <button type="button" className="btn btn-white-link">
          101 – 200
          <i className="fa-solid fa-angle-right ms-2" />
        </button>
      </div> */}
      <div>
        <TablePagination
          // rowsPerPageOptions={[10, 100, 1000]}
          rowsPerPageOptions={[10]}
          labelRowsPerPage={t("")}
          // labelDisplayedRows={({ from, to, count }) =>
          //   t(`${count} 件中 ${from} ~ ${to} 件表示`, count, from, to)
          // }
          // labelDisplayedRows={({ from, to, count }) =>
          //   loading ? "" : `${from} ~ ${to} of ${count}`
          // }
          component="div"
          count={count}
          page={propsTopCollections.search.page - 1}
          onPTopageChange={(event, newPage) => {
            dispatch_gets(propsTopCollections.search, newPage + 1);
          }}
          onRowsPerPageChange={(event) => {
            const search = {
              ...propsTopCollections.search,
              limit: parseInt(event.target.value, 10),
            };
            dispatch_gets(search, 1);
          }}
          rowsPerPage={propsTopCollections.search.limit ? propsTopCollections.search.limit : 0}
        />
      </div>
    </>
  );
}
