import React, { FC, useEffect, useRef, useState, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";
import { initReactI18next, useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomLoading from "components/CustomLoading/CustomLoading.js";
import { DisplayContext } from "../../Navigator/Router_material";
import { useParams, useLocation, useHistory } from "react-router-dom";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { getMessage, toMomentString, toggleAry } from "config/util";

import { getSearch, setSearch } from "config/util";
import MainFixed from "../../components/CustomLoadingScreen/MainFixed";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
// import { SearchUser } from "./_user";
// import { SearchItem } from "./_item";
import { SearchWallet } from "./_wallet";

export default function Dashboard({ Transactions }) {
  const { propsTransactions, set_propsTransactions, dispatch_gets } = Transactions;
  const history = useHistory();
  const dispatch = useDispatch();

  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };
  const [t, i18n] = useTranslation();
  const count = useSelector((state) => state.reduce_get_transactions.count);

  const types = propsTransactions.search.type ?? [];
  const platforms = propsTransactions.search.platform ?? [];

  const dispatch_gets_type = (val) => {
    const search = {
      ...propsTransactions.search,
      type: toggleAry(types, val),
      // type:[val],
    };
    dispatch_gets(search, 1);
  };

  const dispatch_gets_platform = (val) => {
    const search = {
      ...propsTransactions.search,
      platform: toggleAry(platforms, val),
      // platform:[val],
    };
    dispatch_gets(search, 1);
  };

  const loading = useSelector((state) => state.reduce_get_transactions.isLoading);
  const datalist = useSelector((state) => state.reduce_get_transactions.transactions);

  return (
    <>
      <div className="main-toolbar">
        <TablePagination
          // rowsPerPageOptions={[10, 100, 1000]}
          rowsPerPageOptions={[10]}
          labelRowsPerPage={t("")}
          // labelDisplayedRows={({ from, to, count }) =>
          //   t(`${count} 件中 ${from} ~ ${to} 件表示`, count, from, to)
          // }
          // labelDisplayedRows={({ from, to, count }) =>
          //   loading ? "" : `${from} ~ ${to} of ${count}`
          // }
          component="div"
          count={count}
          page={propsTransactions.search.page - 1}
          onPageChange={(event, newPage) => {
            dispatch_gets(propsTransactions.search, newPage + 1);
          }}
          onRowsPerPageChange={(event) => {
            const search = {
              ...propsTransactions.search,
              limit: parseInt(event.target.value, 10),
            };
            dispatch_gets(search, 1);
          }}
          rowsPerPage={propsTransactions.search.limit ? propsTransactions.search.limit : 0}
        />
        {/* {Transactions.list == "user" && <>{SearchUser(Transactions)}</>}
        {Transactions.list == "item" && <>{SearchItem(Transactions)}</>}
        {Transactions.list == "wallet" && <>{SearchWallet(Transactions)}</>} */}
      </div>
      {loading && (
        <div className="main-toolbar">
          <CustomLinearProgress color="primary" />
        </div>
      )}
    </>
  );
}
