import { fork, take, put, join, select, takeLatest, takeEvery, call } from "redux-saga/effects";

import { post_authout } from "../Auth/actions";

import {
  api_get_top_collections,
  api_get_top_items,
  api_get_top_buyers,
  api_get_top_sellers,
  api_put_top_collections_id_favorite,
  api_put_top_items_id_favorite,
} from "./api";

import {
  get_top_collections,
  get_top_collections_data,
  get_top_collections_error,
  get_top_items,
  get_top_items_data,
  get_top_items_error,
  get_top_buyers,
  get_top_buyers_data,
  get_top_buyers_error,
  get_top_sellers,
  get_top_sellers_data,
  get_top_sellers_error,
  put_top_collections_id_favorite,
  put_top_collections_id_favorite_data,
  put_top_collections_id_favorite_error,
  put_top_items_id_favorite,
  put_top_items_id_favorite_data,
  put_top_items_id_favorite_error,
} from "./actions";

export function* run_get_top_collections({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_get_top_collections, payload.data, token);
  if (!error) {
    yield put(get_top_collections_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_top_collections_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* flow_get_top_collections() {
  yield takeLatest(get_top_collections, run_get_top_collections);
}

export function* run_get_top_items({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_get_top_items, payload.data, token);
  if (!error) {
    yield put(get_top_items_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_top_items_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* flow_get_top_items() {
  yield takeLatest(get_top_items, run_get_top_items);
}

export function* run_get_top_buyers({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_get_top_buyers, payload.data, token);
  if (!error) {
    yield put(get_top_buyers_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_top_buyers_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* flow_get_top_buyers() {
  yield takeLatest(get_top_buyers, run_get_top_buyers);
}

export function* run_get_top_sellers({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_get_top_sellers, payload.data, token);
  if (!error) {
    yield put(get_top_sellers_data({ data }));
    !payload.next || payload.next(data);
  } else {
    yield put(get_top_sellers_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_put_top_collections_id_favorite({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_put_top_collections_id_favorite, payload.data, token);
  if (!error) {
    yield put(put_top_collections_id_favorite_data({ data }));
    yield put(get_top_collections(payload));
  } else {
    yield put(put_top_collections_id_favorite_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}

export function* run_put_top_items_id_favorite({ payload }) {
  const { token } = yield select((stores) => stores.reduce_post_authin);

  const { data, error } = yield call(api_put_top_items_id_favorite, payload.data, token);
  if (!error) {
    yield put(put_top_items_id_favorite_data({ data }));
    yield put(get_top_items(payload));
  } else {
    yield put(put_top_items_id_favorite_error({ error }));
    if (error.response && error.response.status === 401) yield put(post_authout({}));
    !payload.prev || payload.prev(error);
  }
}
export function* flow_put_top_collections_id_favorite() {
  yield takeLatest(put_top_collections_id_favorite, run_put_top_collections_id_favorite);
}

export function* flow_put_top_items_id_favorite() {
  yield takeLatest(put_top_items_id_favorite, run_put_top_items_id_favorite);
}

export function* flow_get_top_sellers() {
  yield takeLatest(get_top_sellers, run_get_top_sellers);
}

export default function* rootSaga() {
  yield fork(flow_get_top_collections);
  yield fork(flow_get_top_items);
  yield fork(flow_get_top_buyers);
  yield fork(flow_get_top_sellers);
  yield fork(flow_put_top_items_id_favorite);
  yield fork(flow_put_top_collections_id_favorite);
}
