import React, { FC, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import HomeView from "./HomeAdminView";
import { initReactI18next, useTranslation } from "react-i18next";

import { ChainLink } from "../../components/ChainLink";
import { get_collections } from "stores/Collections/actions";
import { put_top_collections_id_favorite } from "stores/Top/actions";

import {
  get_top_collections,
  get_top_items,
  get_top_sellers,
  get_top_buyers,
} from "stores/Top/actions";

import { getMessage } from "config/util";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

export default function Contents({ branch, children }) {
  const [t, i18n] = useTranslation();
  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const dispatch = useDispatch();

  const [propsHome, set_propsHome] = useState({});

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const newchildren = React.cloneElement(children, {
    Home: {
      propsHome,
      set_propsHome,
      branch,
    },
  });

  return (
    <>
      <ChainLink
        hrefs={
          [
            // "/dist/tmty/dist/js/vendor/mobile-side-navigation-zeynepjs/zeynep.min.css",
            // "/dist/tmty/dist/css/base.css",
            // "/dist/tmty/dist/css/ja-jp.css",
          ]
        }
      >
        <Helmet>
          <meta name="viewport" content="width=device-width,initial-scale=1.0,minimum-scale=1.0" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <title>tomonity</title>
          {/* <script src="dist/material/js/particle.js"></script> */}
        </Helmet>
        {newchildren}
      </ChainLink>
    </>
  );
}
