import React, { FC, useEffect, useRef, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import {
  toMomentString,
  toLowUrl,
  toHighUrl,
  moment2obj,
  moment2countdown,
  zeroPadding,
} from "config/util";
import { getSearch, setSearch } from "config/util";
import { initReactI18next, useTranslation } from "react-i18next";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { get_items, get_items_id, delete_items_id } from "stores/Items/actions";
import {
  get_collections,
  get_collections_id,
  delete_collections_id,
} from "stores/Collections/actions";
import { ChainLink } from "components/ChainLink";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { getMessage } from "config/util";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import socketIOClient from "socket.io-client";
import env from "../../stores/env";
import moment from "moment/moment";
import { post_nfts } from "stores/Nfts/actions";

let socket = null;

const App = ({ branch, children }) => {
  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const [t, i18n] = useTranslation();

  const dispatch = useDispatch();

  const maps = useSelector((state) => state.reduce_get_maps);

  const location_search = useLocation().search;
  const propsSearch = getSearch(location_search) ?? {};

  const loading_post_nfts = useSelector((state) => state.reduce_post_nfts.isLoading);

  const { collection_id } = useParams();

  const defaultProps = {
    search: {
      active_flg: true,
      collection_id,
      page: 1,
      limit: 10,
      sort: [],
      ...branch?.search,
      ...propsSearch?.search,
    },
  };

  if (maps.nfts) {
    defaultProps.symbol = Object.keys(maps.nfts)[0];
  }

  const [propsItems, set_propsItems] = useState(defaultProps);

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const dispatch_get_collections_id = (data, next, prev) => {
    dispatch(
      get_collections_id({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_get_items_id = (data, next, prev) => {
    dispatch(
      get_items_id({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_get_items = (data, next, prev) => {
    dispatch(
      get_items({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_post_nfts = (data, next, prev) => {
    dispatch(
      post_nfts({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  useEffect(() => {
    if (collection_id) {
      dispatch_get_collections_id(
        {
          id: collection_id,
        },
        (data) => {}
      );
    }
  }, []);

  const newchildren = React.cloneElement(children, {
    Items: {
      propsItems,
      set_propsItems,
      // dispatch_get_id: (id, idx, callback) => {
      //   dispatch_get_items_id(
      //     {
      //       id,
      //     },
      //     (data) => {
      //       set_propsItems({
      //         ...data.item,
      //         search: propsItems.search,
      //         id: id,
      //         idx: idx,
      //       });
      //       !callback || callback();
      //     }
      //   );
      // },
      // dispatch_gets: (search, page = 1) => {
      //   dispatch_get_items({
      //     search: { ...search, page },
      //   });
      //   set_propsItems({
      //     search: { ...search, page },
      //   });
      // },

      dispatch_post_nfts,
      set_image_path: (base64, file) => {
        set_propsItems({
          ...propsItems,
          thumbnail: file,
          thumbnail_mime_type: file.type,
          thumbnail_path: base64,
        });
      },
      branch,
    },
  });

  return (
    <>
      <Helmet>
        <title>tomonity</title>
      </Helmet>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10000 }}
        open={loading_post_nfts}
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        place="tc"
        color={color}
        icon={AddAlert}
        message={message}
        open={snack}
        closeNotification={() => {
          set_snack(false);
        }}
        close
      />
      {newchildren}
    </>
  );
};

export default App;
