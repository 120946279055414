import React, { useState, useEffect, Component } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { toMomentString } from "config/util";

import { view_transaction } from "../../stores/Transactions/view";
// import { HeaderUser, RowUser } from "./_user";
// import { HeaderItem, RowItem } from "./_item";
import { HeaderWallet, RowWallet } from "./_wallet";

export default function Dashboard({ Transactions }) {
  const [t, i18n] = useTranslation();
  const history = useHistory();

  Transactions.history = history;
  Transactions.t = t;

  const maps = useSelector((state) => state.reduce_get_maps);

  // const datalist = useSelector((state) => state.reduce_get_transactions.transactions);

  const datalist = useSelector((state) => state.reduce_get_transactions.transactions) ?? [];
  const loading = useSelector((state) => state.reduce_get_transactions.isLoading);
  const loadingId = useSelector((state) => state.reduce_get_transactions.isLoadingId);

  if (loading) return <></>;

  return (
    <>
      {Transactions.list == "wallet" && (
        <>
          {HeaderWallet(Transactions)}
          {datalist.map((prop, idx) => {
            const v_transaction = view_transaction(prop, maps, t);
            Transactions.v_transaction = v_transaction;
            return <>{RowWallet(Transactions)}</>;
          })}
        </>
      )}
    </>
  );
}
