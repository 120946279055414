import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import { view_item } from "../../stores/Items/view";

export default function Contents(Item, ItemOffers, ItemBids) {
  const { propsItem, set_propsItem, dispatch_post_orders } = Item;
  const { propsItemOffers, set_propsItemOffers } = ItemOffers;
  const { propsItemBids, set_propsItemBids } = ItemBids;

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  // const collection = useSelector((state) => state.reduce_get_collections.collection);
  // const v_collection = view_collection(collection);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;
  const maps = useSelector((state) => state.reduce_get_maps);

  const item = useSelector((state) => state.reduce_get_items.item);
  const v_item = view_item(item, maps, t);

  return (
    <>
      {v_item.販売中 && (
        <div className="bg-brand-light rounded-3 p-3 mb-4 mb-lg-3 d-none d-md-block d-lg-none">
          <div className="card border-0 bg-brand-light">
            <div className="card-header border-0 d-md-flex mb-3 py-0 px-md-3 ps-md-2 pt-md-2">
              <span className="d-block mt-auto fw-bold fs-15">
                {v_item.通常販売中 && t("販売終了まで")}
                {v_item.オークション販売中 && t("オークション終了まで")}
              </span>
              <div className="ms-auto">
                <span className="lead fw-bold lh-1">
                  {v_item.item_sale_closed_at_countdown_label}
                </span>
              </div>
            </div>
            <div className="card-body bg-white rounded-3 p-3">
              <div className="row g-3 g-lg-3">
                {v_item.販売中 && (
                  <dl className="col-sm text-end mb-0">
                    <dt className="mb-2">{t("現在の価格")}</dt>
                    <dd className="text-end text-nowrap">
                      <span
                        className="display-6 lh-1 fw-bold"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={v_item.item_sale_coin}
                      >
                        <sub className="me-1">{v_item.item_sale_coin_tag_color}</sub>
                        {v_item.item_sale_price}
                      </span>
                      <span className="d-block mt-1">
                        <sub>${v_item.item_sale_price_usd_label}</sub>
                      </span>
                    </dd>
                  </dl>
                )}

                <div className="col-sm col-lg-12">
                  {!v_item.所有者 && v_item.通常販売中 && (
                    <button
                      type="button"
                      className="btn btn-primary rounded-pill btn-lg w-100 mb-2"
                      data-bs-toggle="modal"
                      data-bs-target="#itemOrderModal"
                      onClick={(e) => {
                        e.preventDefault();
                        set_propsItem({
                          ...propsItem,
                          item_id: v_item.item_id,
                          item_detail_id: v_item.item_detail_id,
                          // coin: "ETH",
                          pay_id: 0,
                        });
                      }}
                    >
                      <i className="fa-solid fa-cart-shopping fa-fw me-2" />
                      {t("購入する")}
                    </button>
                  )}
                  {!v_item.所有者 && v_item.オークション販売中 && (
                    <button
                      type="button"
                      className="btn btn-primary rounded-pill btn-lg w-100 mb-2"
                      data-bs-toggle="modal"
                      data-bs-target="#itemBidModal"
                      onClick={(e) => {
                        e.preventDefault();
                        set_propsItemBids({
                          ...propsItemBids,
                          item_id: v_item.item_id,
                          item_detail_id: v_item.item_detail_id,
                          coin: "ETH",
                        });
                      }}
                    >
                      <i className="fa-solid fa-cart-shopping fa-fw me-2" />
                      {t("入札する")}
                    </button>
                  )}
                  {!v_item.所有者 && !v_item.オークション販売中 && (
                    <button
                      type="button"
                      className="btn btn-link w-100"
                      data-bs-toggle="modal"
                      data-bs-target="#itemOfferModal"
                      onClick={() => {
                        set_propsItemOffers({
                          ...propsItemOffers,
                          item_id: v_item.item_id,
                          item_detail_id: v_item.item_detail_id,
                          coin: "ETH",
                        });
                      }}
                    >
                      <i className="fa-solid fa-hand fa-fw me-2" />
                      {t("オファーする")}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!v_item.販売中 && !v_item.所有者 && !v_item.オークション販売中 && (
        <div className="bg-brand-light rounded-3 p-3 mb-4 mb-lg-3 d-none d-md-block d-lg-none">
          <div className="card border-0 bg-brand-light">
            <div className="card-header border-0 d-md-flex mb-3 py-0 px-md-3 ps-md-2 pt-md-2">
              <span className="d-block mt-auto fw-bold fs-15"></span>
              <div className="ms-auto">
                <span className="lead fw-bold lh-1"></span>
              </div>
            </div>
            <div className="card-body bg-white rounded-3 p-3">
              <div className="row g-3 g-lg-3">
                <div className="col-sm col-lg-12">
                  <button
                    type="button"
                    className="btn btn-link w-100"
                    data-bs-toggle="modal"
                    data-bs-target="#itemOfferModal"
                    onClick={() => {
                      set_propsItemOffers({
                        ...propsItemOffers,
                        item_id: v_item.item_id,
                        item_detail_id: v_item.item_detail_id,
                        coin: "ETH",
                      });
                    }}
                  >
                    <i className="fa-solid fa-hand fa-fw me-2" />
                    {t("オファーする")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
