import React, { useState, useEffect, Component } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { initReactI18next, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { toMomentString } from "config/util";
import { getMessage } from "config/util";

import { view_top_item } from "../../stores/Top/view";
import { view_item } from "../../stores/Items/view";
import { put_top_items_id_favorite } from "stores/Top/actions";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import { HeaderUser, RowUser } from "../PagerItems/_user";

export default function Dashboard(TopItems) {
  const {
    propsTopItems,
    set_propsTopItems,
    dispatch_gets,
    dispatch_type,
    type,
    mypage,
    list,
    user_id,
  } = TopItems;

  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();

  const history = useHistory();
  const maps = useSelector((state) => state.reduce_get_maps);

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const dispatch_put_top_items_id_favorite = (data, next, prev) => {
    dispatch(
      put_top_items_id_favorite({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const datalist = useSelector((state) => state.reduce_get_top_items.items);
  const loading_items = useSelector((state) => state.reduce_get_top_items.isLoading);
  // const loadingId = useSelector((state) => state.reduce_top_get_items.isLoadingId);

  let loading = loading_items;

  // if (loading)
  //   return (
  //     <>
  //       <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-2 g-xl-5 mb-3">
  //         <CustomLinearProgress color="primary" />
  //       </div>
  //     </>
  //   );
  
  if (!datalist) return <></>;
  if (datalist.length == 0) return <></>;

  const chunk = (ary, number) => {
    const length = Math.ceil(ary.length / number);
    return new Array(length).fill().map((_, i) => ary.slice(i * number, (i + 1) * number));
  };

  const datalist_ary = chunk(datalist, 5);

  const datalist_ary_0 = datalist_ary[0] ?? [];

  const Items = {};
  Items.propsItems = propsTopItems;
  Items.t = t;
  Items.history = history;
  Items.dispatch_put_items_id_favorite = dispatch_put_top_items_id_favorite;
  
  return (
    <>
      <div className="ms-md-3 mt-0">
        <div className="row row-cols-2 row-cols-sm-3 row-cols-lg-4 row-cols-xl-5 g-2 g-sm-3">
          {HeaderUser(Items)}
          {datalist_ary_0.map((prop, idx) => {
            const v_item = view_item(prop, maps, t);
            Items.v_item = v_item;
            return <>{RowUser(Items)}</>;
          })}
        </div>
      </div>
    </>
  );
}
