import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import Menu from "./Menu";
import Logout from "./Logout";
import { view_user } from "../../stores/Users/view";
import LiLanguage from "components/Li/LiLanguage";

export default function Contents() {
  const dispatch = useDispatch();
  const history2 = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  const user = reduce_get_accounts.user;
  const v_user = view_user(user);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts?.user?.mail_address;
  const user_name = reduce_get_accounts?.user?.username;

  const history = {
    push: (arg) => {
      const globalNav = document.getElementById("globalNav");
      const modal = window.bootstrap.Offcanvas.getInstance(globalNav);
      if (modal) {
        modal.hide();
      }

      history2.push(arg);
    },
  };

  return (
    <>
      <nav
        className="offcanvas offcanvas-start flex-shrink-0 flex-grow-0"
        tabIndex={-1}
        id="globalNav"
        aria-labelledby="globalNavLabel"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="globalNavLabel">
            <a
              className="tmty-brand tmty-brand-full d-md-none"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/admin`,
                });
              }}
            >
              <img
                src="/dist/tmty/assets/brand/tomonity-typography.webp"
                width="auto"
                height="auto"
                alt="tomonity"
              />
            </a>
          </h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          />
        </div>
        <div className="offcanvas-body align-items-center p-0">
          <div className="navbar-nav text-nowrap">
            {!token && (
              <div className="btn-group px-3 mb-3 d-md-none">
                <a
                  className="btn btn-outline-primary rounded-pill w-100"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push({
                      pathname: `/signin`,
                    });
                  }}
                >
                  {t("ログイン")}
                </a>
              </div>
            )}
            <a
              className="nav-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/transactions`,
                });
              }}
            >
              {t("出金承認")}
            </a>
          </div>
        </div>
      </nav>
    </>
  );
}
