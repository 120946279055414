import React, { FC, useEffect, useRef, useState, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";
import { initReactI18next, useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomLoading from "components/CustomLoading/CustomLoading.js";
import { DisplayContext } from "../../Navigator/Router_material";
import { useParams, useLocation, useHistory } from "react-router-dom";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { getMessage, toMomentString } from "config/util";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import moment from "moment";

import { getSearch, setSearch } from "config/util";
import MainFixed from "../../components/CustomLoadingScreen/MainFixed";

export default function Dashboard(TopCollections) {
  const { propsTopCollections, set_propsTopCollections, dispatch_gets, type } = TopCollections;
  const history = useHistory();
  const dispatch = useDispatch();

  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const location_search = useLocation().search;

  const datalist_collections = useSelector((state) => state.reduce_get_top_collections.collections);
  const count_collections = useSelector((state) => state.reduce_get_top_collections.count);
  const loading_collections = useSelector((state) => state.reduce_get_top_collections.isLoading);

  let datalist = datalist_collections;
  let count = count_collections;
  let loading = loading_collections;

  useEffect(() => {
    // dispatch_gets(propsTopCollections.search, 1);
  }, []);

  const [t, i18n] = useTranslation();

  const theme = useTheme();
  const primary = theme.palette.primary.main;

  const { windowHeight, tableHeight } = useContext(DisplayContext);

  const location = useLocation();
  const nonce = location.nonce;
  const [term, set_term] = useState("30日間");
  const [category, set_category] = useState("すべてのカテゴリ");
  const [priority, set_priority] = useState("取引量");

  const [mount, set_mount] = React.useState(true);
  const [load, set_load] = useState(true);
  const ref_load = useRef(load);

  useEffect(() => {
    ref_load.current = load;
  }, [load]);

  useEffect(() => {
    if (ref_load.current) {
      set_load(loading);
    }
  }, [loading]);

  useEffect(() => {
    set_mount(false);
    set_load(true);
  }, [nonce]);

  useEffect(() => {
    set_mount(true);
  }, [mount]);

  if (!mount) {
    return <></>;
  }

  if (!datalist) {
    return <></>;
  }

  const height = tableHeight;

  return (
    <>
      {/* <MainFixed loading={loading && ref_load.current} /> */}
      <Snackbar
        place="tc"
        color={color}
        icon={AddAlert}
        message={message}
        open={snack}
        closeNotification={() => {
          set_snack(false);
        }}
        close
      />
      <div className="row g-2">
        <div className="col">
          <button
            className="btn btn-outline-secondary dropdown-toggle py-sm-3 px-sm-4 w-100"
            type="button"
            id="topCollections"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span className="h6">{t(term)}</span>
          </button>
          <ul className="dropdown-menu" aria-labelledby="topCollections">
            <li>
              <button
                className={`dropdown-item ${term == "24時間" ? "active" : ""}`}
                type="button"
                onClick={() => {
                  set_term("24時間");
                  const search = {
                    ...propsTopCollections.search,
                    from: moment()
                      .subtract(24, "h")
                      .format(),
                    to: moment().format(),
                  };
                  dispatch_gets(search, 1);
                }}
              >
                {t("24時間")}
              </button>
            </li>
            <li>
              <button
                className={`dropdown-item ${term == "7日間" ? "active" : ""}`}
                type="button"
                onClick={() => {
                  set_term("7日間");
                  const search = {
                    ...propsTopCollections.search,
                    from: moment()
                      .subtract(7, "d")
                      .format(),
                    to: moment().format(),
                  };
                  dispatch_gets(search, 1);
                }}
              >
                {t("7日間")}
              </button>
            </li>
            <li>
              <button
                className={`dropdown-item ${term == "14日間" ? "active" : ""}`}
                type="button"
                onClick={() => {
                  set_term("14日間");
                  const search = {
                    ...propsTopCollections.search,
                    from: moment()
                      .subtract(14, "d")
                      .format(),
                    to: moment().format(),
                  };
                  dispatch_gets(search, 1);
                }}
              >
                {t("14日間")}
              </button>
            </li>
            <li>
              <button
                className={`dropdown-item ${term == "30日間" ? "active" : ""}`}
                type="button"
                onClick={() => {
                  set_term("30日間");
                  const search = {
                    ...propsTopCollections.search,
                    from: moment()
                      .subtract(30, "d")
                      .format(),
                    to: moment().format(),
                  };
                  dispatch_gets(search, 1);
                }}
              >
                {t("30日間")}
              </button>
            </li>
            <li>
              <button
                className={`dropdown-item ${term == "60日間" ? "active" : ""}`}
                type="button"
                onClick={() => {
                  set_term("60日間");
                  const search = {
                    ...propsTopCollections.search,
                    from: moment()
                      .subtract(60, "d")
                      .format(),
                    to: moment().format(),
                  };
                  dispatch_gets(search, 1);
                }}
              >
                {t("60日間")}
              </button>
            </li>
            <li>
              <button
                className={`dropdown-item ${term == "90日間" ? "active" : ""}`}
                type="button"
                onClick={() => {
                  set_term("90日間");
                  const search = {
                    ...propsTopCollections.search,
                    from: moment()
                      .subtract(90, "d")
                      .format(),
                    to: moment().format(),
                  };
                  dispatch_gets(search, 1);
                }}
              >
                {t("90日間")}
              </button>
            </li>
            <li>
              <button
                // className="dropdown-item active"
                className={`dropdown-item ${term == "すべての期間" ? "active" : ""}`}
                type="button"
                onClick={() => {
                  set_term("すべての期間");
                  const search = {
                    ...propsTopCollections.search,
                    from: null,
                    to: null,
                  };
                  dispatch_gets(search, 1);
                }}
              >
                {t("すべての期間")}
              </button>
            </li>
          </ul>
        </div>
        {/* <div className="col">
          <button
            className="btn btn-outline-secondary dropdown-toggle py-sm-3 px-sm-4 w-100"
            type="button"
            id="topCollections"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span className="h6">{category}</span>
          </button>
          <ul className="dropdown-menu" aria-labelledby="topCollections">
            <li>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => {
                  set_category("アート");
                  const search = {
                    ...propsTopCollections.search,
                    category_id: "c801984a-f926-4ffc-a8c1-c48bc4307d0a",
                  };
                  dispatch_gets(search, 1);
                }}
              >
                <i className="fa-fw me-2 fa-solid fa-brush" />
                アート
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => {
                  set_category("コレクター");
                  const search = {
                    ...propsTopCollections.search,
                    category_id: "1fc59b04-1a89-4644-a73a-7e82fcf8efbc",
                  };
                  dispatch_gets(search, 1);
                }}
              >
                <i className="fa-fw me-2 fa-solid fa-lightbulb" />
                コレクター
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => {
                  set_category("ドメイン");
                  const search = {
                    ...propsTopCollections.search,
                    category_id: "f31f2447-8800-4ff2-a36d-d82b475bba59",
                  };
                  dispatch_gets(search, 1);
                }}
              >
                <i className="fa-fw me-2 fa-solid fa-at" />
                ドメイン
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => {
                  set_category("音楽");
                  const search = {
                    ...propsTopCollections.search,
                    category_id: "853c87ab-076a-417d-b2e0-a3a4d8c13492",
                  };
                  dispatch_gets(search, 1);
                }}
              >
                <i className="fa-fw me-2 fa-brands fa-itunes-note" />
                音楽
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => {
                  set_category("写真");
                  const search = {
                    ...propsTopCollections.search,
                    category_id: "6fc94309-7a9a-41a0-b520-cfc72dde88fc",
                  };
                  dispatch_gets(search, 1);
                }}
              >
                <i className="fa-fw me-2 fa-solid fa-camera-retro" />
                写真
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => {
                  set_category("スポーツ");
                  const search = {
                    ...propsTopCollections.search,
                    category_id: "9bd3e56a-6321-4a41-8801-51780bc8866b",
                  };
                  dispatch_gets(search, 1);
                }}
              >
                <i className="fa-fw me-2 fa-solid fa-baseball-bat-ball" />
                スポーツ
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => {
                  set_category("トレーディングカード");
                  const search = {
                    ...propsTopCollections.search,
                    category_id: "a0934b49-6337-41ae-ad89-21e1356154cf",
                  };
                  dispatch_gets(search, 1);
                }}
              >
                <i className="fa-fw me-2 fa-solid fa-gamepad" />
                トレーディングカード
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => {
                  set_category("ユーティリティ");
                  const search = {
                    ...propsTopCollections.search,
                    category_id: "c49aded5-4f2c-4140-9056-a926774600ee",
                  };
                  dispatch_gets(search, 1);
                }}
              >
                <i className="fa-fw me-2 fa-solid fa-box-open" />
                ユーティリティ
              </button>
            </li>
            <li>
              <button
                className="dropdown-item"
                type="button"
                onClick={() => {
                  set_category("メタバース");
                  const search = {
                    ...propsTopCollections.search,
                    category_id: "6b99b864-f386-4b67-8130-3a82fb0bbe47",
                  };
                  dispatch_gets(search, 1);
                }}
              >
                <i className="fa-fw me-2 fa-solid fa-vr-cardboard" />
                メタバース
              </button>
            </li>
            <li>
              <button
                className="dropdown-item active"
                type="button"
                onClick={() => {
                  set_category("すべてのカテゴリ");
                  const search = {
                    ...propsTopCollections.search,
                    category_id: null,
                  };
                  dispatch_gets(search, 1);
                }}
              >
                すべてのカテゴリ
              </button>
            </li>
          </ul>
        </div> */}
        <div className="col">
          <button
            className="btn btn-outline-secondary dropdown-toggle py-sm-3 px-sm-4 w-100"
            type="button"
            id="topCollections"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span className="h6">{t(priority)}</span>
          </button>
          <ul className="dropdown-menu" aria-labelledby="topCollections">
            <li>
              <button
                className={`dropdown-item ${priority == "up" ? "active" : ""}`}
                type="button"
                onClick={() => {
                  set_priority("上昇率");
                  const search = {
                    ...propsTopCollections.search,
                    priority: "up",
                  };
                  dispatch_gets(search, 1);
                }}
              >
                {t("上昇率")}
              </button>
            </li>
            {/* <li>
              <button className="dropdown-item" type="button">
                Polygon
              </button>
            </li> */}
            <li>
              <button
                className={`dropdown-item ${priority == "sum" ? "active" : ""}`}
                type="button"
                onClick={() => {
                  set_priority("取引量");
                  const search = {
                    ...propsTopCollections.search,
                    priority: "sum",
                  };
                  dispatch_gets(search, 1);
                }}
              >
                {t("取引量")}
              </button>
            </li>
          </ul>
        </div>
        {/* <div className="col">
                  <button
                    className="btn btn-outline-secondary dropdown-toggle py-sm-3 px-sm-4 w-100"
                    type="button"
                    id="topCollections"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="h6">すべてのチェーン</span>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="topCollections">
                    <li>
                      <button className="dropdown-item" type="button">
                        IOST
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item" type="button">
                        Polygon
                      </button>
                    </li>
                    <li>
                      <button className="dropdown-item active" type="button">
                        すべてのチェーン
                      </button>
                    </li>
                  </ul>
                </div> */}
      </div>
      {/* <div>
        <TablePagination
          // rowsPerPageOptions={[10, 100, 1000]}
          rowsPerPageOptions={[10]}
          labelRowsPerPage={t("")}
          // labelDisplayedRows={({ from, to, count }) =>
          //   t(`${count} 件中 ${from} ~ ${to} 件表示`, count, from, to)
          // }
          // labelDisplayedRows={({ from, to, count }) =>
          //   loading ? "" : `${from} ~ ${to} of ${count}`
          // }
          component="div"
          count={count}
          page={propsTopCollections.search.page - 1}
          onPTopageChange={(event, newPage) => {
            dispatch_gets(propsTopCollections.search, newPage + 1);
          }}
          onRowsPerPageChange={(event) => {
            const search = {
              ...propsTopCollections.search,
              limit: parseInt(event.target.value, 10),
            };
            dispatch_gets(search, 1);
          }}
          rowsPerPage={propsTopCollections.search.limit ? propsTopCollections.search.limit : 0}
        />
      </div> */}
    </>
  );
}
