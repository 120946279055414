import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import Header from "../../views_components/Component/Header";
import _sidebar from "./_sidebar";
import { view_collection } from "../../stores/Collections/view";
import ItemsPager from "../../views_components/PagerItems/ItemsPager";
import ItemsList from "../../views_components/PagerItems/ItemsList";
import Collections from "@material-ui/icons/Collections";
import _mainheader from "./_mainheader";
import Footer from "../../views_components/Component/Footer";

import ActivitiesPager from "../../views_components/PagerActivities/ActivitiesPager";
import ActivitiesList from "../../views_components/PagerActivities/ActivitiesList";

export default function Contents({ Page, Items, Activities }) {
  const { type, dispatch_type } = Page;

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const maps = useSelector((state) => state.reduce_get_maps);

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  // const collection = useSelector((state) => state.reduce_get_items.collection);
  const collection = useSelector((state) => state.reduce_get_collections.collection);
  const v_collection = view_collection(collection, maps);

  return (
    <>
      <div>
        <Header />
        <div className="container container-main">
          {_sidebar(Items)}
          <main className="main">
            {_mainheader(Page, Items)}
            {/* <ItemsPager Items={Items} />
            <div className="ms-md-3 mt-0">
              <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-xxl-4 g-2 g-sm-3">
                <ItemsList Items={Items} />
              </div>
            </div> */}

            {type == "items" && (
              <>
                <ItemsPager Items={Items} />
                <div className="ms-md-3 mt-0">
                  <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-xxl-4 g-2 g-sm-3">
                    <ItemsList Items={Items} />
                  </div>
                </div>
              </>
            )}

            {type == "activities" && (
              <>
                <ActivitiesPager Activities={Activities} />
                <div className="ms-md-3 mt-0">
                  <div className="activitys">
                    <ActivitiesList Activities={Activities} />
                  </div>
                </div>
              </>
            )}


          </main>
        </div>
        <Footer />
      </div>
    </>
  );
}
