import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import { view_item } from "../../stores/Items/view";

export default function Contents(Item) {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const maps = useSelector((state) => state.reduce_get_maps);

  // const collection = useSelector((state) => state.reduce_get_collections.collection);
  // const v_collection = view_collection(collection);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const item = useSelector((state) => state.reduce_get_items.item);
  const v_item = view_item(item, maps, t);

  return (
    <>
      <nav>
        <div className="nav nav-tabs" id="nav-tab" role="tablist">
          <button
            className="nav-link fs-14 px-md-2 active"
            id="itemDesc-tab"
            data-bs-toggle="tab"
            data-bs-target="#itemDesc"
            type="button"
            role="tab"
            aria-controls="itemDesc"
            aria-selected="true"
          >
            {t("アイテム説明")}
          </button>
          {/* <button
            className="nav-link fs-14"
            id="itemPropaty-tab"
            data-bs-toggle="tab"
            data-bs-target="#itemPropaty"
            type="button"
            role="tab"
            aria-controls="itemPropaty"
            aria-selected="false"
          >
            プロパティ
          </button> */}
          <button
            className="nav-link fs-14"
            id="itemLicence-tab"
            data-bs-toggle="tab"
            data-bs-target="#itemLicence"
            type="button"
            role="tab"
            aria-controls="itemLicence"
            aria-selected="false"
          >
            {t("ライセンス")}
          </button>
        </div>
      </nav>
      <div className="tab-content mb-3" id="nav-tabContent">
        <div
          className="tab-pane fade px-2 py-3 show active"
          id="itemDesc"
          role="tabpanel"
          aria-labelledby="itemDesc-tab"
        >
          <p>
            {v_item.item_description_br}
            {/* 日本が誇るサムライアーティスト「スーパーブラシ」さん書き下ろし、『塔の上のラプンツェル』のヒロイン(ラプンツェル)作品の第４弾。ここでしか手に入らないディズニー公式作品となっています。
            <br />
            <br />
            English:
            <br />
            This is the fourth piece of artwork of the heroine (Rapunzel) of "Rapunzel on the
            Tower", written by "Super Brush", a samurai artist who is the pride of Japan. This is an
            official Disney work available only here. */}
          </p>
          {/* <span className="d-block">
            <a className="btn btn-link btn-sm rounded-pill px-0">
              <i className="fa-solid fa-arrow-up-right-from-square me-2" />
              アイテムの紹介URL
            </a>
          </span> */}
        </div>
        <div
          className="tab-pane fade py-3"
          id="itemPropaty"
          role="tabpanel"
          aria-labelledby="itemPropaty-tab"
        >
          <ul className="list-group list-group-flush rounded-0 mb-0 fs-15">
            <li className="list-group-item p-0">
              <dl className="row g-0 align-items-center m-0 p-2 list-group-item-action">
                <dt className="col-3 col-md-4 m-0 text-truncate text-body">シリーズ</dt>
                <dd className="col m-0 overflow-hidden">
                  <div className="d-flex align-items-center position-relative">
                    <a className="stretched-link text-body text-truncate me-3 mb-2" href="#">
                      塔の上のラプンツェル
                    </a>
                    <small className="flex-shrink-0 ms-auto text-muted text-end mb-2">6%</small>
                    <div
                      className="position-absolute bottom-0 start-0 translate-middle-y w-100 progress"
                      style={{
                        height: "2px",
                      }}
                    >
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: "6%",
                        }}
                        aria-valuenow={6}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                  </div>
                </dd>
              </dl>
              <dl className="row g-0 align-items-center m-0 p-2 list-group-item-action">
                <dt className="col-3 col-md-4 m-0 text-truncate text-body">キャラクター</dt>
                <dd className="col m-0 overflow-hidden">
                  <div className="d-flex align-items-center position-relative">
                    <a className="stretched-link text-body text-truncate me-3 mb-2" href="#">
                      ラプンツェル
                    </a>
                    <small className="flex-shrink-0 ms-auto text-muted text-end mb-2">0.1%</small>
                    <div
                      className="position-absolute bottom-0 start-0 translate-middle-y w-100 progress"
                      style={{
                        height: "2px",
                      }}
                    >
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: "1%",
                        }}
                        aria-valuenow={1}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                  </div>
                </dd>
              </dl>
              <dl className="row g-0 align-items-center m-0 p-2 list-group-item-action">
                <dt className="col-3 col-md-4 m-0 text-truncate text-body">タッチ</dt>
                <dd className="col m-0 overflow-hidden">
                  <div className="d-flex align-items-center position-relative">
                    <a className="stretched-link text-body text-truncate me-3 mb-2" href="#">
                      スケッチ
                    </a>
                    <small className="flex-shrink-0 ms-auto text-muted text-end mb-2">100%</small>
                    <div
                      className="position-absolute bottom-0 start-0 translate-middle-y w-100 progress"
                      style={{
                        height: "2px",
                      }}
                    >
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: "100%",
                        }}
                        aria-valuenow={100}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                  </div>
                </dd>
              </dl>
              <dl className="row g-0 align-items-center m-0 p-2 list-group-item-action">
                <dt className="col-3 col-md-4 m-0 text-truncate text-body">カラー</dt>
                <dd className="col m-0 overflow-hidden">
                  <div className="d-flex align-items-center position-relative mb-2">
                    <a className="stretched-link text-body text-truncate me-3 mb-2" href="#">
                      ホワイト
                    </a>
                    <small className="flex-shrink-0 ms-auto text-muted text-end mb-2">50%</small>
                    <div
                      className="position-absolute bottom-0 start-0 translate-middle-y w-100 progress"
                      style={{
                        height: "2px",
                      }}
                    >
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: "50%",
                        }}
                        aria-valuenow={50}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                  </div>
                  <div className="d-flex align-items-center position-relative">
                    <a className="stretched-link text-body text-truncate me-3 mb-2" href="#">
                      グレースケール
                    </a>
                    <small className="flex-shrink-0 ms-auto text-muted text-end mb-2">75%</small>
                    <div
                      className="position-absolute bottom-0 start-0 translate-middle-y w-100 progress"
                      style={{
                        height: "2px",
                      }}
                    >
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: "75%",
                        }}
                        aria-valuenow={75}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                  </div>
                </dd>
              </dl>
            </li>
          </ul>
        </div>
        <div
          className="tab-pane fade py-3"
          id="itemLicence"
          role="tabpanel"
          aria-labelledby="itemLicence-tab"
        >
          <ul className="list-group list-group-flush fs-14 overflow-hidden">
            <li className="list-group-item d-flex justify-content-between align-items-center p-2">
              {t("コントラクトアドレス")}
              <span className="text-muted">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`${v_item.contract_uri}`, "_blank", "noreferrer");
                  }}
                >
                  {v_item.contract_address}
                </a>
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center p-2">
              {t("コントラクトネーム")}
              <span className="text-muted">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`${v_item.contract_uri}`, "_blank", "noreferrer");
                  }}
                >
                  {v_item.contract_name}
                </a>
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center p-2">
            {t("トークンID")}  <span className="text-muted">{v_item.nft_token_id2}</span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center p-2">
            {t("トークン規格")} <span className="text-muted">{v_item.standard_label}</span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center p-2">
            {t("ブロックチェーン")} <span className="text-muted">{v_item.coin_platform_label}</span>
            </li>
            {/* <li className="list-group-item d-flex justify-content-between align-items-center p-2">
              クリエイターID
              <span className="text-muted">
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title=""
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <i
                    className="fa-solid fa-clipboard-check fa-fw me-1"
                    title=""
                  />
                  0c000123456
                </span>
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center p-2">
              コレクションID
              <span className="text-muted">
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title=""
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <i
                    className="fa-solid fa-clipboard-check fa-fw me-1"
                    title=""
                  />
                  0c000654321
                </span>
              </span>
            </li>
            <li className="list-group-item d-flex justify-content-between align-items-center p-2">
              アイテムID
              <span className="text-muted">
                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title=""
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <i
                    className="fa-solid fa-clipboard-check fa-fw me-1"
                    title=""
                  />
                  0c000123123
                </span>
              </span>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
}
