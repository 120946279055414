import React, { FC, useEffect, useRef, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { getSearch, setSearch } from "config/util";
import { initReactI18next, useTranslation } from "react-i18next";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { getMessage } from "config/util";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import { ChainLink } from "components/ChainLink";
import { get_transactions, put_transactions_id } from "stores/Transactions/actions";

const App = ({ branch, children }) => {
  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const [t, i18n] = useTranslation();

  const dispatch = useDispatch();

  const maps = useSelector((state) => state.reduce_get_maps);

  const [propsTransactions, set_propsTransactions] = useState({
    search: {
      page: 1,
      limit: 10,
      sort: [],
      ...branch?.search,
      approved_flg: 0,
      name: "TYPE_COIN_FROM_HOT",
    },
  });

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const dispatch_get_transactions = (data, next, prev) => {
    dispatch(
      get_transactions({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const dispatch_gets_transactions = (search, page = 1) => {
    dispatch_get_transactions({
      search: { ...search, page },
    });
    set_propsTransactions({
      search: { ...search, page },
    });
  };

  useEffect(() => {
    dispatch_gets_transactions(propsTransactions.search, 1);
  }, []);

  const dispatch_put_transactions_id = (data, next, prev) => {
    dispatch(
      put_transactions_id({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const newchildren = React.cloneElement(children, {
    Transactions: {
      propsTransactions,
      set_propsTransactions,
      dispatch_gets: dispatch_gets_transactions,
      dispatch_put_transactions_id,
      list: "wallet",
      branch,
    },
  });

  return (
    <>
      <Helmet>
        <title>tomonity</title>
      </Helmet>
      <Snackbar
        place="tc"
        color={color}
        icon={AddAlert}
        message={message}
        open={snack}
        closeNotification={() => {
          set_snack(false);
        }}
        close
      />
      {newchildren}
    </>
  );
};

export default App;
