import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

// import { view_user } from "../../stores/Users/view";
import { view_user } from "../../stores/Accounts/view";
import { view_wallets } from "../../stores/Wallets/view";
import { toNumber } from "config/util";

export default function Contents({ myhome, suffix }) {
  const dispatch = useDispatch();
  const history2 = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_wallets = useSelector((state) => state.reduce_get_wallets);
  const maps = useSelector((state) => state.reduce_get_maps);

  const user = reduce_get_accounts.user;
  const v_user = view_user(user);

  const wallets = reduce_get_wallets?.wallets;
  const v_wallets = view_wallets(wallets, maps, t);

  useEffect(() => {
    window.Zeynep1();
    window.Zeynep2();
  }, []);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts?.user?.mail_address;

  const history = {
    push: (arg) => {
      if (!myhome) {
        const accountsNav = document.getElementById("accountsNav");
        const modal = window.bootstrap.Offcanvas.getInstance(accountsNav);
        modal.hide();
      }

      history2.push(arg);
    },
  };

  return (
    <>
      {myhome && (
        <div className="px-md-3 py-3">
          <div className="media position-relative">
            <div className="media-object">
              <div className="object-item user-icon">
                <img
                  src={v_user.user_icon_uri}
                  // src="/dist/tmty/example/users/2-icon.webp"
                  width="100%"
                  height="auto"
                  alt=""
                />
              </div>
            </div>
            <div className="media-body">
              <div className="media-action">
                <h2 className="media-title">
                  <a
                    className="stretched-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();

                      // const accountsNav = document.getElementById("accountsNav");
                      // const modal = window.bootstrap.Offcanvas.getInstance(accountsNav);
                      // modal.hide();

                      history.push({
                        pathname: `/users/${v_user.user_id}/created`,
                      });
                    }}
                  >
                    {/* ステファニー・シャンク<span>@stefanieshank</span> */}
                    {v_user.user_name}
                    <span>@{v_user.user_nmid}</span>
                  </a>
                </h2>
              </div>
            </div>
          </div>
        </div>
      )}
      {!myhome && (
        <div className="p-3 p-md-2 pt-0">
          <div className="media mb-2 position-relative">
            <div className="media-object">
              <div className="object-item user-icon">
                <img
                  src={v_user.user_icon_uri}
                  // src="/dist/tmty/example/users/2-icon.webp"
                  width="100%"
                  height="auto"
                  alt=""
                />
              </div>
            </div>
            <div className="media-body text-truncate">
              <div className="media-action">
                <h3 className="media-title text-truncate">
                  <a
                    className="stretched-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/users/${v_user.user_id}`,
                      });
                    }}
                  >
                    {v_user.user_name}
                    <span>@{v_user.user_nmid}</span>
                  </a>
                </h3>
              </div>
            </div>
          </div>
          <a
            className="d-block bg-dark rounded-3 p-3 text-white"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              history.push({
                pathname: `/wallets`,
              });
            }}
          >
            <small className="d-block mb-1 text-secondary">{t("ウォレット残高(総合)")}</small>
            <p className="lead mb-0">
              <small>$</small>
              {v_wallets.sum_usd_label}
            </p>

            <small className="d-block">(¥{v_wallets.sum_jpy_label})</small>
          </a>
        </div>
      )}

      <div className="zeynep opened zeynep-light" data-menu-name="navbar">
        <ul className="navbar-nav">
          {myhome && (
            <li>
              <hr className="zeynep-divider" />
            </li>
          )}

          <li className="nav-item">
            <a
              className="nav-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/users/${v_user.user_id}/items`,
                });
              }}
            >
              {t("アイテム")}
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/users/${v_user.user_id}/collections`,
                });
              }}
            >
              {t("コレクション")}
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/users/${v_user.user_id}/created`,
                });
              }}
            >
              {t("クリエイト")}
            </a>
          </li>

          <li>
            <hr className="zeynep-divider" />
          </li>

          <li className="nav-item">
            <a
              className="nav-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/users/${v_user.user_id}/favoritecollections`,
                });
              }}
            >
              {t("ウォッチリスト")}
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/users/${v_user.user_id}/favoriteitems`,
                });
              }}
            >
              {t("お気に入り")}
            </a>
          </li>
          <li>
            <hr className="zeynep-divider" />
          </li>

          <li className="nav-item">
            <a
              className="nav-link d-flex justify-content-between align-items-center"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/users/${v_user.user_id}/itemoffers`,
                });
              }}
            >
              {t("オファー")}
              {/* <span className="badge rounded-pill bg-danger text-white">+99</span> */}
            </a>
          </li>
          <li>
            <a
              className="nav-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/users/${v_user.user_id}/activities`,
                });
              }}
            >
              {t("アクティビティ")}
            </a>
          </li>

          {/* <li className="nav-item">
            <a
              className="nav-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/users/${v_user.user_id}/followings`,
                });
              }}
            >
              フォロー
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/users/${v_user.user_id}/followers`,
                });
              }}
            >
              フォロワー
            </a>
          </li> */}
          <li>
            <hr className="zeynep-divider" />
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/wallets`,
                });
              }}
            >
              {t("ウォレット")}
            </a>
          </li>
          <li className="nav-item has-submenu">
            <a className="nav-link" href="javascript:void(0)" data-submenu={"settings" + suffix}>
              {/* 設定とプライバシー */}
              {t("設定")}
            </a>
            <div id={"settings" + suffix} className="submenu">
              <div className="submenu-header">
                <a href="javascript:void(0)" data-submenu-close={"settings" + suffix}>
                  {/* 設定とプライバシー */}
                  {t("設定")}
                </a>
              </div>
              <ul className="navbar-nav">
                {/* <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/account-extra`,
                      });
                    }}
                  >
                    ニックネームとプロフィール
                  </a>
                </li> */}
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/account-image`,
                      });
                    }}
                  >
                    {t("プロフィール変更")}
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/account-kyc`,
                      });
                    }}
                  >
                    KYC申請
                  </a>
                </li> */}
                {/* <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/account-user`,
                      });
                    }}
                  >
                    ユーザーネーム変更
                  </a>
                </li> */}
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/account-mail`,
                      });
                    }}
                  >
                    {t("メールアドレス変更")}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/account-phone`,
                      });
                    }}
                  >
                    {t("携帯電話番号変更")}
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/account-password`,
                      });
                    }}
                  >
                    {t("パスワード変更")}
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/account-totp`,
                      });
                    }}
                  >
                    {t("GoogleAuthenticatorの登録")}
                  </a>
                </li> */}
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/account-security`,
                      });
                    }}
                  >
                    {t("セキュリティ設定")}
                  </a>
                </li>
                {/* <li className="nav-item has-submenu">
                  <a
                    className="nav-link"
                    href="javascript:void(0)"
                    data-submenu={"account" + suffix}
                  >
                    アカウント設定
                  </a>
                  <div id={"account" + suffix} className="submenu">
                    <div className="submenu-header">
                      <a href="javascript:void(0)" data-submenu-close={"account" + suffix}>
                        アカウント設定
                      </a>
                    </div>
                    <ul className="navbar-nav">
                    
                    </ul>
                  </div>
                </li> */}
                {/* <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/notification`,
                      });
                    }}
                  >
                    通知
                  </a>
                </li>
                <li className="nav-item has-submenu">
                  <a
                    className="nav-link"
                    href="javascript:void(0)"
                    data-submenu={"safety" + suffix}
                  >
                    プライバシー設定
                  </a>
                  <div id={"safety" + suffix} className="submenu">
                    <div className="submenu-header">
                      <a href="javascript:void(0)" data-submenu-close={"safety" + suffix}>
                        プライバシー設定
                      </a>
                    </div>
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push({
                              pathname: `/safetyrating`,
                            });
                          }}
                        >
                          レイティング設定
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push({
                              pathname: `/safetyblocked`,
                            });
                          }}
                        >
                          ブロックユーザ
                        </a>
                      </li>
                    </ul>
                  </div>
                </li> */}
              </ul>
            </div>
          </li>
          {/* <li>
            <hr className="zeynep-divider" />
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              プレミアム機能
            </a>
          </li> */}
          <li>
            <hr className="zeynep-divider" />
          </li>
          {!myhome && (
            <>
              <li className="nav-item d-md-none">
                <a
                  className="nav-link"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push({
                      pathname: `/collections`,
                    });
                  }}
                >
                  {t("カテゴリ")}
                </a>
              </li>
              <li className="nav-item d-md-none">
                <a
                  className="nav-link"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push({
                      pathname: `/rankings`,
                    });
                  }}
                >
                  {t("ランキング")}
                </a>
              </li>
              <li className="nav-item d-md-none">
                <a
                  className="nav-link"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push({
                      pathname: `/activities`,
                    });
                  }}
                >
                  {t("アクティビティ")}
                </a>
              </li>
              <li className="d-md-none">
                <hr className="zeynep-divider" />
              </li>
            </>
          )}
          {/* <li className="nav-item has-submenu">
            <a className="nav-link" href="javascript:void(0)" data-submenu={"resource" + suffix}>
              その他リソース
            </a>
            <div id={"resource" + suffix} className="submenu">
              <div className="submenu-header">
                <a href="javascript:void(0)" data-submenu-close={"resource" + suffix}>
                  その他リソース
                </a>
              </div>
              <ul className="navbar-nav">
                <li className="nav-item">
                  <a className="nav-link disabled d-flex justify-content-between" href="#">
                    バージョン<span>0.1</span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push({
                        pathname: `/legal`,
                      });
                    }}
                  >
                    ヘルプセンター
                  </a>
                </li>
                <li className="nav-item has-submenu">
                  <a className="nav-link" href="javascript:void(0)" data-submenu={"legal" + suffix}>
                    法的表示
                  </a>
                  <div id={"legal" + suffix} className="submenu">
                    <div className="submenu-header">
                      <a href="javascript:void(0)" data-submenu-close={"legal" + suffix}>
                        法的表示
                      </a>
                    </div>
                    <ul className="navbar-nav">
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push({
                              pathname: `/tos`,
                            });
                          }}
                        >
                          利用規約
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push({
                              pathname: `/privacy`,
                            });
                          }}
                        >
                          プライバシーポリシー
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li> */}
          {/* <li>
            <hr className="zeynep-divider" />
          </li> */}
          <li className="nav-item">
            <a
              className="nav-link"
              href="javascript:void(0)"
              role="button"
              data-bs-toggle="modal"
              data-bs-target="#signOut"
            >
              {t("ログアウト")}
            </a>
          </li>
          {myhome && (
            <li>
              <hr className="zeynep-divider" />
            </li>
          )}
        </ul>
      </div>
    </>
  );
}

// <li className="nav-item has-submenu">
// <a
//   className="nav-link"
//   href="javascript:void(0)"
//   data-submenu={"profile" + suffix}
// >
//   プロフィール変更
// </a>
// <div id={"profile" + suffix} className="submenu">
//   <div className="submenu-header">
//     <a href="javascript:void(0)" data-submenu-close={"profile" + suffix}>
//       プロフィール変更
//     </a>
//   </div>
//   <ul className="navbar-nav">
//     {/* <li className="nav-item">
//       <a
//         className="nav-link"
//         href="#"
//         onClick={(e) => {
//           e.preventDefault();
//           history.push({
//             pathname: `/account-extra`,
//           });
//         }}
//       >
//         ニックネームとプロフィール
//       </a>
//     </li>
//     <li className="nav-item">
//       <a
//         className="nav-link"
//         href="#"
//         onClick={(e) => {
//           e.preventDefault();
//           history.push({
//             pathname: `/account-image`,
//           });
//         }}
//       >
//         プロフィール変更
//       </a>
//     </li>
//     <li className="nav-item">
//       <a
//         className="nav-link"
//         href="#"
//         onClick={(e) => {
//           e.preventDefault();
//           history.push({
//             pathname: `/account-kyc`,
//           });
//         }}
//       >
//         KYC申請
//       </a>
//     </li> */}
//     {/* <li className="nav-item">
//       <a
//         className="nav-link"
//         href="#"
//         onClick={(e) => {
//           e.preventDefault();
//           history.push({
//             pathname: `/biolinks`,
//           });
//         }}
//       >
//         ソーシャルリンク
//       </a>
//     </li> */}
//   </ul>
// </div>
// </li>
