import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import { dashboard, holderadmin, holderuser } from "Navigator/Router_material";

import { home } from "../../Navigator/Router_material";

export default function Contents({ SingIn }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const { propsSingIn, set_propsSingIn, dispatch_post_authin, branch } = SingIn;

  const props = propsSingIn;
  const set_props = set_propsSingIn;

  // const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  // const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  let pathname = dashboard;
  if (sessionStorage.getItem("pathname") && sessionStorage.getItem("pathname") != "/") {
    pathname = sessionStorage.getItem("pathname");
  }

  // const la = sessionStorage.getItem("AcceptLanguage");
  // const token = reduce_post_authin.token;
  // const mail_address = reduce_get_accounts.mail_address;

  return (
    <>
      <div style={{ height: "100vh" }}>
        <main className="main sign bg-animation-brand">
          <div
            className="modal-content rounded-5 shadow"
            style={{
              maxWidth: "450px",
            }}
          >
            <div className="modal-header border-bottom-0">
              <a
                href="#"
                className="navbar-brand modal-title"
                onClick={(e) => {
                  e.preventDefault();
                  history.push({
                    pathname: home,
                  });
                }}
              >
                <h1 className="m-0">
                  <span className="tmty-brand">
                    <img
                      src="/dist/tmty/assets/brand/tomonity-typography.webp"
                      width={118}
                      height="auto"
                      alt="tomonity"
                    />
                  </span>
                </h1>
              </a>
              {}
            </div>
            <div className="modal-body pt-0 p-sm-5 pt-sm-0">
              <h2 className="h5 fw-bold mb-3">{t("tomonityにログイン")}</h2>
              <div action="sign-in-pw.html">
                <div className="form-floating mb-3">
                  <input
                    type="text"
                    className="form-control rounded-4"
                    id="floatingInput"
                    placeholder="name@example.com"
                    inputMode="email"
                    required
                    autoFocus
                    value={props.mail_address}
                    onChange={(e) => {
                      const val = e.target.value;
                      set_props({
                        ...props,
                        mail_address: val,
                        username: val,
                      });
                    }}
                  />
                  <label htmlFor="floatingInput">{t("メールアドレスまたはアカウント名")}</label>
                </div>
                <div className="form-floating mb-3">
                  <input
                    type="password"
                    className="form-control rounded-4"
                    id="floatingPassword"
                    placeholder="\u30D1\u30B9\u30EF\u30FC\u30C9\u3092\u5165\u529B"
                    required
                    value={props.password}
                    onChange={(e) => {
                      const val = e.target.value;
                      set_props({
                        ...props,
                        password: val,
                      });
                    }}
                  />
                  <label htmlFor="floatingPassword">{t("パスワード")}</label>
                </div>
                <button
                  className="btn btn-primary rounded-pill btn-lg w-100 mb-4"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault(e);

                    const request = {
                      recaptcha: props.recaptcha,
                      password: props.password,
                    };

                    if (!props.mail_address) {
                      return;
                    }

                    if (props.mail_address.includes("@")) {
                      request.mail_address = props.mail_address;
                      request.type = 2;
                    } else {
                      request.username = props.username;
                      request.type = 1;
                    }

                    dispatch_post_authin(
                      request,
                      (data) => {
                        if (data.hash) {
                          history.push({
                            pathname: `/signincode`,
                          });
                          // window.location.reload();
                          // window.scroll(0, 0);
                        } else {
                          history.push({
                            pathname: pathname,
                            // nonce: moment().valueOf(),
                          });
                          // window.location.reload();

                          sessionStorage.removeItem("pathname");

                          // window.scroll(0, 0);
                        }
                      },
                      () => {}
                    );
                  }}
                >
                  {t("次へ")}
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
