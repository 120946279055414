import React, { FC, useEffect, useRef, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { getSearch, setSearch } from "config/util";
import { initReactI18next, useTranslation } from "react-i18next";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { getMessage } from "config/util";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.js";
import { ChainLink } from "components/ChainLink";
import { get_activities } from "stores/Activities/actions";
import {
  get_top_collections,
  get_top_items,
  get_top_sellers,
  get_top_buyers,
} from "stores/Top/actions";

import moment from "moment";

const App = ({ branch, children }) => {
  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const [t, i18n] = useTranslation();

  const dispatch = useDispatch();

  const maps = useSelector((state) => state.reduce_get_maps);

  const [propsActivities, set_propsActivities] = useState({
    search: {
      page: 1,
      limit: 10,
      sort: [],
      ...branch?.search,
    },
  });

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const dispatch_get_activities = (data, next, prev) => {
    dispatch(
      get_activities({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  useEffect(() => {
    const page = 1;
    dispatch_get_activities({
      search: { ...propsActivities.search, page },
    });
    set_propsActivities({
      search: { ...propsActivities.search, page },
    });
  }, []);

  const [propsTopCollections, set_propsTopCollections] = useState({
    search: {
      active_flg: true,
      page: 1,
      limit: 15,
      from: moment()
        // .subtract(24, "h")
        .subtract(30, "d")
        .format(),
      to: moment().format(),
      priority: "sum",
      // from: moment("2022-05-09T00:00:00.000Z").format(),
      // to: moment("2022-05-09T00:00:00.000Z").format(),
      sort: [],
      ...branch?.search,
    },
  });

  const dispatch_get_top_collections = (data, next, prev) => {
    dispatch(
      get_top_collections({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_top_collections = (search, page = 1) => {
    dispatch_get_top_collections({
      search: { ...search, page },
    });
    set_propsTopCollections({
      search: { ...search, page },
    });
  };

  useEffect(() => {
    set_get_top_collections(propsTopCollections.search);
  }, []);

  const [propsTopSellers, set_propsTopSellers] = useState({
    search: {
      active_flg: true,
      page: 1,
      limit: 15,
      // from: moment("2022-05-09T00:00:00.000Z").format(),
      // to: moment("2022-05-09T00:00:00.000Z").format(),
      sort: [],
      ...branch?.search,
    },
  });

  const dispatch_get_top_sellers = (data, next, prev) => {
    dispatch(
      get_top_sellers({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_top_sellers = (search, page = 1) => {
    dispatch_get_top_sellers({
      search: { ...search, page },
    });
    set_propsTopSellers({
      search: { ...search, page },
    });
  };

  useEffect(() => {
    set_get_top_sellers(propsTopSellers.search);
  }, []);

  const [propsTopBuyers, set_propsTopBuyers] = useState({
    search: {
      active_flg: true,
      page: 1,
      limit: 15,
      // from: moment("2022-05-09T00:00:00.000Z").format(),
      // to: moment("2022-05-09T00:00:00.000Z").format(),
      sort: [],
      ...branch?.search,
    },
  });

  const dispatch_get_top_buyers = (data, next, prev) => {
    dispatch(
      get_top_buyers({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_top_buyers = (search, page = 1) => {
    dispatch_get_top_buyers({
      search: { ...search, page },
    });
    set_propsTopBuyers({
      search: { ...search, page },
    });
  };

  useEffect(() => {
    set_get_top_buyers(propsTopBuyers.search);
  }, []);

  const newchildren = React.cloneElement(children, {
    Activities: {
      propsActivities,
      set_propsActivities,
      dispatch_gets: (search, page = 1) => {
        dispatch_get_activities({
          search: { ...search, page },
        });
        set_propsActivities({
          search: { ...search, page },
        });
      },
      branch,
    },
    TopCollections: {
      propsTopCollections,
      set_propsTopCollections,
      dispatch_gets: set_get_top_collections,
      list: 4,
      branch,
    },
    TopSellers: {
      propsTopSellers,
      set_propsTopSellers,
      dispatch_gets: set_get_top_sellers,
      list: 4,
      branch,
    },
    TopBuyers: {
      propsTopBuyers,
      set_propsTopBuyers,
      dispatch_gets: set_get_top_buyers,
      list: 4,
      branch,
    },
  });

  return (
    <>
      <Helmet>
        <title>tomonity</title>
      </Helmet>
      <Snackbar
        place="tc"
        color={color}
        icon={AddAlert}
        message={message}
        open={snack}
        closeNotification={() => {
          set_snack(false);
        }}
        close
      />
      {newchildren}
    </>
  );
};

export default App;
