import React, { useState, useEffect, Component } from "react";
import {
  toMomentString,
  toLowUrl,
  toHighUrl,
  moment2obj,
  moment2countdown,
  countdown2label,
  zeroPadding,
  toNumber,
} from "config/util";

import { view_item } from "../Items/view";
import { view_collection } from "../Collections/view";

export const view_transaction = (prop, maps, t) => {
  if (!prop) return {};
  if (!maps) return {};

  const coin_decimals = maps.coin_decimals;
  let resView = {
    ...prop,
    // transaction_name: prop.name,
    // transaction_type: prop.type,
    // transaction_price: prop.price,

    // transaction_created_at: toMomentString(prop.created_at, "YYYY-MM-DD HH:mm"),
    // transaction_created_at_label: toMomentString(prop.created_at, "YYYY-MM-DD HH:mm"),
    // transaction_user_name_from: prop.user_name_from,
    // transaction_user_name_to: prop.user_name_to,
  };

  const created_at_countdown = moment2countdown(prop.created_at);
  resView.created_at_countdown_label = countdown2label(created_at_countdown, t);

  const item = prop.item;
  if (item) {
    resView = {
      ...resView,
      ...view_item(item, maps, t),
    };
  }

  const collection = prop.collection;
  if (collection) {
    resView = {
      ...resView,
      ...view_collection(collection),
    };
  }

  const transaction_hash = prop.data?.transaction_hash;
  const platform_id = prop.data?.platform_id;

  if (transaction_hash && platform_id) {
    resView.explorer_uri =
      platform_id == 2
        ? // ? `https://rinkeby.etherscan.io/tx/${transaction_hash}`
          `https://etherscan.io/tx/${transaction_hash}`
        : platform_id == 4
        ? `https://www.iostabc.com/tx/${transaction_hash}`
        : platform_id == 10
        ? `https://polygonscan.com/tx/${transaction_hash}`
        : "";
  }

  resView.transaction_icon_uri =
    prop.transaction_type == 10 ? resView.collection_icon_uri : resView.item_icon_uri;

  resView.transaction_link =
    prop.transaction_type == 10
      ? `/collections/${resView.collection_id}/items`
      : `/items/${resView.item_id}`;

  resView.transaction_price_has = prop.transaction_price && prop.transaction_price != 0;
  // resView.transaction_amount_has = prop.transaction_amount && prop.transaction_amount != 0;

  resView.transaction_amount_label = prop.transaction_amount == 0 ? "" : prop.transaction_amount;
  resView.transaction_price_label = prop.transaction_price == 0 ? "" : prop.transaction_price;

  // resView.transaction_price_usd_label = toNumber(prop.item_price_usd, coin_decimals["USDT"]);

  resView.coin_label = prop.data?.coin_name;

  const coin_id = prop.data?.coin_id;
  resView.coin_icon_tag =
    coin_id == 1 ? (
      <i className="icomoon-brand-btc btc-color" />
    ) : coin_id == 2 ? (
      <i className="icomoon-brand-eth eth-color" />
    ) : coin_id == 4 ? (
      <i className="icomoon-brand-iost iost-color" />
    ) : coin_id == 5 ? (
      <i className="icomoon-brand-usdt usdt-color" />
    ) : (
      <></>
    );

  resView.transaction_from = prop.data?.address_from;
  resView.transaction_to = prop.data?.address_to;
  return resView;
};
