import React, { useState, useEffect, Component } from "react";
import moment from "moment/moment";
import {
  toMomentString,
  toLowUrl,
  toHighUrl,
  moment2obj,
  moment2countdown,
  countdown2label,
  zeroPadding,
  toNumber,
  createBr,
} from "config/util";

import { view_item } from "../../stores/Items/view";

export const view_item_offer = (prop, maps, t) => {
  if (!t) return {};
  if (!prop) return {};
  if (!maps) return {};

  let resView = {
    ...prop,
  };

  const item = prop.item;
  if (item) {
    resView = {
      ...resView,
      ...view_item(item, maps, t),
    };
  }

  const collection = prop.collection;

  if (collection) {
    resView.collection_cover_uri = collection.collection_cover_uri;
    resView.collection_icon_uri = collection.collection_icon_uri;
    resView.collection_description = collection.collection_description;
    resView.collection_description_br = createBr(collection.collection_description);

    resView.category_name = collection.category_name;
    resView.collection_id = collection.collection_id;
    resView.collection_name = collection.collection_name;
  }

  const coin_decimals = maps.coin_decimals;

  resView.item_offer_price_usd_label = toNumber(prop.item_offer_price_usd, coin_decimals["USDT"]);
  const closed_at_countdown = moment2countdown(prop.closed_at);
  resView.closed_at_countdown_label = countdown2label(closed_at_countdown, t);

  const created_at_countdown = moment2countdown(prop.created_at);
  resView.created_at_countdown_label = countdown2label(created_at_countdown, t);

  resView.coin_label =
    prop.coin_id == 1
      ? "BTC"
      : prop.coin_id == 2
      ? "ETH"
      : prop.coin_id == 4
      ? "IOST"
      : prop.coin_id == 5
      ? "USDT"
      : "";

  resView.coin_icon_tag =
    prop.coin_id == 1 ? (
      <i className="icomoon-brand-btc btc-color" />
    ) : prop.coin_id == 2 ? (
      <i className="icomoon-brand-eth eth-color" />
    ) : prop.coin_id == 4 ? (
      <i className="icomoon-brand-iost iost-color" />
    ) : prop.coin_id == 5 ? (
      <i className="icomoon-brand-usdt usdt-color" />
    ) : (
      <></>
    );

  return resView;
};
