import { createAction, createReducer } from 'redux-act';

export const get_top_collections = createAction("GET_TOP_COLLECTIONS");
export const get_top_collections_data = createAction("GET_TOP_COLLECTIONS_DATA");
export const get_top_collections_error = createAction("GET_TOP_COLLECTIONS_ERROR");

// export const get_top_collections_id = createAction("GET_TOP_COLLECTIONS_ID");
// export const get_top_collections_id_data = createAction("GET_TOP_COLLECTIONS_ID_DATA");
// export const get_top_collections_id_error = createAction("GET_TOP_COLLECTIONS_ID_ERROR");

export const get_top_items = createAction("GET_TOP_ITEMS");
export const get_top_items_data = createAction("GET_TOP_ITEMS_DATA");
export const get_top_items_error = createAction("GET_TOP_ITEMS_ERROR");

export const get_top_buyers = createAction("GET_TOP_BUYERS");
export const get_top_buyers_data = createAction("GET_TOP_BUYERS_DATA");
export const get_top_buyers_error = createAction("GET_TOP_BUYERS_ERROR");

export const get_top_sellers = createAction("GET_TOP_SELLERS");
export const get_top_sellers_data = createAction("GET_TOP_SELLERS_DATA");
export const get_top_sellers_error = createAction("GET_TOP_SELLERS_ERROR");


export const put_top_collections_id_favorite = createAction("PUT_TOP_COLLECTIONS_ID_FAVORITE");
export const put_top_collections_id_favorite_data = createAction("PUT_TOP_COLLECTIONS_ID_FAVORITE_DATA");
export const put_top_collections_id_favorite_error = createAction("PUT_TOP_COLLECTIONS_ID_FAVORITE_ERROR");

export const put_top_items_id_favorite = createAction("PUT_TOP_ITEMS_ID_FAVORITE");
export const put_top_items_id_favorite_data = createAction("PUT_TOP_ITEMS_ID_FAVORITE_DATA");
export const put_top_items_id_favorite_error = createAction("PUT_TOP_ITEMS_ID_FAVORITE_ERROR");


