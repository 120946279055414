import React, { FC, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import HomeView from "./HomeView";
import { initReactI18next, useTranslation } from "react-i18next";

import { ChainLink } from "../../components/ChainLink";
import { get_collections } from "stores/Collections/actions";
import { put_top_collections_id_favorite } from "stores/Top/actions";

import {
  get_top_collections,
  get_top_items,
  get_top_sellers,
  get_top_buyers,
} from "stores/Top/actions";

import { getMessage } from "config/util";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

export default function Contents({ branch, children }) {
  const [t, i18n] = useTranslation();
  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const dispatch = useDispatch();

  const [propsCollections, set_propsCollections] = useState({
    search: {
      active_flg: true,
      page: 1,
      limit: 80,
      sort: [],
      ...branch?.search,
    },
  });

  const dispatch_get_collections = (data, next, prev) => {
    dispatch(
      get_collections({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_collections = (search, page = 1) => {
    dispatch_get_collections({
      search: { ...search, page },
    });
    set_propsCollections({
      search: { ...search, page },
    });
  };
  useEffect(() => {
    set_get_collections(propsCollections.search);
  }, []);

  const [propsTopCollections, set_propsTopCollections] = useState({
    search: {
      active_flg: true,
      page: 1,
      limit: 15,
      // from: moment("2022-05-09T00:00:00.000Z").format(),
      // to: moment("2022-05-09T00:00:00.000Z").format(),
      from: moment().add(-1, 'M').format(),
      to: moment().format(),
      sort: [],
      ...branch?.search,
    },
  });

  const dispatch_get_top_collections = (data, next, prev) => {
    dispatch(
      get_top_collections({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_top_collections = (search, page = 1) => {
    dispatch_get_top_collections({
      search: { ...search, page },
    });
    set_propsTopCollections({
      search: { ...search, page },
    });
  };

  useEffect(() => {
    set_get_top_collections(propsTopCollections.search);
  }, []);

  const [propsTopItems, set_propsTopItems] = useState({
    search: {
      active_flg: true,
      page: 1,
      limit: 15,
      from: moment().add(-1, 'M').format(),
      to: moment().format(),
      sort: [],
      ...branch?.search,
    },
  });

  const dispatch_get_top_items = (data, next, prev) => {
    dispatch(
      get_top_items({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_top_items = (search, page = 1) => {
    dispatch_get_top_items({
      search: { ...search, page },
    });
    set_propsTopItems({
      search: { ...search, page },
    });
  };

  useEffect(() => {
    set_get_top_items(propsTopItems.search);
  }, []);

  const [propsTopSellers, set_propsTopSellers] = useState({
    search: {
      active_flg: true,
      page: 1,
      limit: 15,
      from: moment().add(-1, 'M').format(),
      to: moment().format(),
      sort: [],
      ...branch?.search,
    },
  });

  const dispatch_get_top_sellers = (data, next, prev) => {
    dispatch(
      get_top_sellers({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_top_sellers = (search, page = 1) => {
    dispatch_get_top_sellers({
      search: { ...search, page },
    });
    set_propsTopSellers({
      search: { ...search, page },
    });
  };

  useEffect(() => {
    set_get_top_sellers(propsTopSellers.search);
  }, []);

  const [propsTopBuyers, set_propsTopBuyers] = useState({
    search: {
      active_flg: true,
      page: 1,
      limit: 15,
      from: moment().add(-1, 'M').format(),
      to: moment().format(),
      sort: [],
      ...branch?.search,
    },
  });

  const dispatch_get_top_buyers = (data, next, prev) => {
    dispatch(
      get_top_buyers({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };

  const set_get_top_buyers = (search, page = 1) => {
    dispatch_get_top_buyers({
      search: { ...search, page },
    });
    set_propsTopBuyers({
      search: { ...search, page },
    });
  };


  const dispatch_put_top_collections_id_favorite = (data, next, prev) => {
    dispatch(
      put_top_collections_id_favorite({
        data,
        prev: (err) => {
          toast(err);
          !prev || prev(err);
        },
        next,
      })
    );
  };


  useEffect(() => {
    set_get_top_buyers(propsTopBuyers.search);
  }, []);

  const [propsHome, set_propsHome] = useState({});

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };

  const newchildren = React.cloneElement(children, {
    Home: {
      propsHome,
      set_propsHome,
      branch,
    },
    Collections: {
      propsCollections,
      set_propsCollections,
      dispatch_gets: set_get_collections,
      list: 4,
      branch,
    },
    TopCollections: {
      propsTopCollections,
      set_propsTopCollections,
      dispatch_gets: set_get_top_collections,
      dispatch_put_top_collections_id_favorite,
      list: 4,
      branch,
    },
    TopItems: {
      propsTopItems,
      set_propsTopItems,
      dispatch_gets: set_get_top_items,
      list: 4,
      branch,
    },
    TopSellers: {
      propsTopSellers,
      set_propsTopSellers,
      dispatch_gets: set_get_top_sellers,
      list: 4,
      branch,
    },
    TopBuyers: {
      propsTopBuyers,
      set_propsTopBuyers,
      dispatch_gets: set_get_top_buyers,
      list: 4,
      branch,
    },
  });

  return (
    <>
      <ChainLink
        hrefs={
          [
            // "/dist/tmty/dist/js/vendor/mobile-side-navigation-zeynepjs/zeynep.min.css",
            // "/dist/tmty/dist/css/base.css",
            // "/dist/tmty/dist/css/ja-jp.css",
          ]
        }
      >
        <Helmet>
          <meta name="viewport" content="width=device-width,initial-scale=1.0,minimum-scale=1.0" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <title>tomonity</title>
          {/* <script src="dist/material/js/particle.js"></script> */}
        </Helmet>
        {newchildren}
      </ChainLink>
    </>
  );
}
