import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";

import { QRCodeSVG } from "qrcode.react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { hideModal, showModal } from "config/util";
import { view_wallets } from "../../stores/Wallets/view";

export default function Contents(Transactions) {
  const { propsTransactions, set_propsTransactions, dispatch_put_transactions_id } = Transactions;

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const { coin } = useParams();

  const [active_tab, set_active_tab] = useState("預入");

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const reduce_get_wallets = useSelector((state) => state.reduce_get_wallets);
  const reduce_get_withdraws = useSelector((state) => state.reduce_get_withdraws);

  const maps = useSelector((state) => state.reduce_get_maps);

  const coin_decimals = maps.coin_decimals;
  const coins = maps.coins;

  useEffect(() => {}, []);
  const coin_fullname = coins[coin];

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const wallets = reduce_get_wallets?.wallets;
  const v_wallets = view_wallets(wallets, maps, t, coin);
  const withdraws = reduce_get_withdraws?.withdraws;

  return (
    <>
      <div
        className="modal fade"
        id="withdrawalsModal"
        tabIndex={-1}
        aria-labelledby="withdrawalsModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-dialog-centered modal-dialog-scrollable modal-fullscreen-sm-down">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="withdrawalsModalLabel">
                {t("承認")}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <dl className="row g-2">
                <dt className="col-sm-4">{t("ユーザー")}</dt>
                <dd className="col-sm-8">{propsTransactions.user}</dd>
                <dt className="col-sm-4">{t("コイン")}</dt>
                <dd className="col-sm-8">{propsTransactions.coin}</dd>
                <dt className="col-sm-4">{t("送付額")}</dt>
                <dd className="col-sm-8">{propsTransactions.amount}</dd>
              </dl>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                {t("キャンセル")}
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  dispatch_put_transactions_id(
                    {
                      id: propsTransactions.transaction_id,
                      search: propsTransactions.search,
                    },
                    () => {
                      hideModal("withdrawalsModal");
                    },
                    () => {}
                  );
                }}
              >
                {t("承認")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
