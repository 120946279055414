import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { view_collection } from "../../stores/Collections/view";
import _more_options from "./_more_options";

export default function Contents(Items) {
  const { dispatch_put_collections_id_favorite } = Items;

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  // const collection = useSelector((state) => state.reduce_get_items.collection);
  const collection = useSelector((state) => state.reduce_get_collections.collection);
  const v_collection = view_collection(collection);

  const link_twitter = collection.data?.link_twitter;
  const link_facebook = collection.data?.link_facebook;
  const link_instagram = collection.data?.link_instagram;
  const link_discord = collection.data?.link_discord;
  const link_linkedin = collection.data?.link_linkedin;

  return (
    <>
      <div className="sidebar shadow-end">
        <div className="sidebar-sticky">
          <div className="me-md-3 position-relative">
            <div className="cover ratio ratio-1x3">
              <img
                src={v_collection.collection_cover_uri}
                className="img-fluid cover-item"
                width="100%"
                alt="SUSHI COL"
              />
            </div>
            <div className="media my-3">
              <div className="media-object">
                {/* <div className="object-item icon icon-square verified"> */}
                <div className="object-item icon icon-square">
                  <img
                    src={v_collection.collection_icon_uri}
                    className="icon-item"
                    width={70}
                    height={70}
                    alt=""
                  />
                </div>
              </div>
              <div className="media-body text-end">
                <div
                  className="btn-group flex-column"
                  style={{
                    zIndex: 2,
                  }}
                >
                  {v_collection.collection_owner && (
                    <>
                      <a
                        className="btn btn-outline-dark rounded-pill text-nowrap px-3"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(`/collections/edit/${v_collection.collection_id}`);
                        }}
                      >
                        {t("コレクション編集")}
                      </a>
                      <a
                        className="btn btn-link rounded-pill text-nowrap px-3"
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          history.push(`/items/${v_collection.collection_id}/create`);
                        }}
                      >
                        <i className="fa-solid fa-plus me-2" />
                        {t("アイテム追加")}
                      </a>
                    </>
                  )}
                  {!v_collection.collection_owner && (
                    <button
                      type="button"
                      className={`btn btn-outline-dark rounded-pill text-nowrap px-3 ${v_collection.collection_favorited_my &&
                        "active"}`}
                      data-bs-toggle="button"
                      autoComplete="off"
                      aria-pressed={v_collection.collection_favorited_my ? "true" : "false"}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch_put_collections_id_favorite({
                          id: v_collection.collection_id,
                          // search: propsCollections.search,
                        });
                      }}
                    >
                      {v_collection.collection_favorited_my
                        ? t("ウォッチリスト中")
                        : t("ウォッチリスト")}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="text-black d-block mb-2">
              <h2 className="product-title mb-0">
                <a
                  className="stretched-link text-dark"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(`/collections/${v_collection.collection_id}/items`);
                  }}
                >
                  {/* <span className="verified-text">{v_collection.collection_name}</span> */}
                  <span className="">{v_collection.collection_name}</span>
                </a>
              </h2>
              {/* <span className="d-block">@{v_collection.collection_nmid}</span> */}
            </div>
          </div>
          <div
            className="list-group-item border-start-none border-end-none ps-0"
            style={{ border: "none" }}
          >
            <CopyToClipboard
              text={v_collection.collection_nmid}
              onCopy={() => {
                alert("コレクションIDをコピーしました。");
              }}
            >
              <span data-bs-toggle="tooltip" data-bs-placement="top" title="">
                <i className="fa-solid fa-clipboard-check fa-fw me-2" title="" />
                {v_collection.collection_nmid}
              </span>
            </CopyToClipboard>
          </div>
          <div class="mb-3">
            <span className="d-block">
              <a
                className="btn btn-white-link btn-sm ps-0 mb-1"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`${v_collection.category_icon_link}`);
                }}
              >
                {v_collection.category_icon_tag}
                {t(v_collection.category_name)}
              </a>
            </span>
          </div>
          <div className="me-md-3">
            <p>
              {v_collection.collection_description_br}
              {/* <a href="collections.html?%23#%E3%83%87%E3%82%A3%E3%82%BA%E3%83%8B%E3%83%BC">
                #ディズニー
              </a>{" "}
              <a href="collections.html?%23%E3%83%87%E3%82%A3%E3%82%BA%E3%83%8B%E3%83%BC%E3%83%97%E3%83%AA%E3%83%B3%E3%82%BB%E3%82%B9">
                #ディズニープリンセス
              </a> */}
            </p>
            <aside className="btn-group" role="link">
              {link_twitter && (
                <a
                  className="btn btn-link btn-sm rounded-pill me-2"
                  href
                  target
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`${link_twitter}`, "_blank", "noreferrer");
                  }}
                >
                  <i className="fab fa-twitter fa-fw" />
                </a>
              )}
              {link_facebook && (
                <a
                  className="btn btn-link btn-sm rounded-pill me-2"
                  href
                  target
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`${link_facebook}`, "_blank", "noreferrer");
                  }}
                >
                  <i className="fab fa-facebook-f fa-fw" />
                </a>
              )}
              {link_instagram && (
                <a
                  className="btn btn-link btn-sm rounded-pill me-2"
                  href
                  target
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`${link_instagram}`, "_blank", "noreferrer");
                  }}
                >
                  <i className="fab fa-instagram fa-fw" />
                </a>
              )}

              {link_discord && (
                <a
                  className="btn btn-link btn-sm rounded-pill me-2"
                  href
                  target
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(`${link_discord}`, "_blank", "noreferrer");
                  }}
                >
                  <i className="fab fa-discord fa-fw" />
                </a>
              )}
            </aside>
          </div>
          <div className="list-group list-group-flush">
            <div className="list-group-item ps-0 d-flex flex-nowrap">
              <span className="mb-0">
                <a
                  href="javascript:void(0);"
                  className="text-black"
                  role="button"
                  data-bs-toggle="modal"
                  data-bs-target="#collectionFavorite"
                >
                  <span className="fw-bold">{v_collection.collection_favorited_count}</span>
                  <span className="ms-1 text-gray">{t("ウォッチリスト")}</span>
                </a>
              </span>
            </div>

            <div className="list-group-item ps-0">
              <div className="media position-relative">
                <div className="media-object">
                  {/* <div className="verified"> */}
                  <div className="">
                    <div className="object-item icon icon-pill">
                      <img
                        src={v_collection.collection_user_icon_uri}
                        className="icon-item"
                        width="100%"
                        height="auto"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="media-body pt-1">
                  <h3 className="media-title">
                    <a
                      className="stretched-link text-dark"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`/users/${v_collection.collection_user_id}`);
                      }}
                    >
                      {/* <span className="verified-text">{v_collection.collection_user_name}</span> */}
                      <span className="">{v_collection.collection_user_name}</span>
                    </a>
                    <span className="d-block">@{v_collection.collection_user_id}</span>
                  </h3>
                </div>
              </div>
              {/* <div className="media">
                <div className="media-object" />
                <div className="media-body">
                  <button
                    type="button"
                    className="btn btn-white btn-sm ps-0"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title=""
                    style={{
                      zIndex: 2,
                    }}
                  >
                    <i
                      className="fa-solid fa-clipboard-check fa-fw me-2"
                      title=""
                    />
                    0u000123456
                  </button>
                </div>
              </div> */}
            </div>
          </div>
          <ul className="list-group list-group-flush mb-4">
            <li className="list-group-item ps-0">
              <h3 className="h5 m-0">
                {t("手数料")}{" "}
                <a className="btn btn-link btn-sm ms-2" href>
                  {t("手数料について")}
                  <i className="fa-solid fa-arrow-up-right-from-square ms-1" />
                </a>
              </h3>
            </li>
            {/* <li className="list-group-item d-flex ps-0">
              サービス手数料
              <spna className="ms-auto">2.5%</spna>
            </li> */}
            <li className="list-group-item d-flex ps-0">
              {t("クリエイター報酬")}
              <spna className="ms-auto">{v_collection.collection_creator_reward}%</spna>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}
