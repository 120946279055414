import axios from "axios";
import qs from "qs";
import env from "./env";

axios.interceptors.request.use(function(config) {
  if (typeof config.params === "undefined") {
    config.params = {};
  }

  if (config.method === "get") {
    if (typeof config.params === "object") {
      if (typeof URLSearchParams === "function" && config.params instanceof URLSearchParams)
        config.params.append("_", Date.now());
      else config.params._ = Date.now();

      config.paramsSerializer = (params) => {
        return qs.stringify(params);
      };
    }
  }

  // if (typeof config.params === "object") {
  //   if (
  //     typeof URLSearchParams === "function" &&
  //     config.params instanceof URLSearchParams
  //   )
  //     config.params.append("_", Date.now());
  //   else config.params._ = Date.now();

  //   config.paramsSerializer = (params) => {
  //     return qs.stringify(params);
  //   };
  // }
  // config.headers.common['Accept-Language'] = 'en';
  config.headers.common["Accept-Language"] = sessionStorage.getItem("AcceptLanguage");
  config.headers.common["pjkey"] = env.PROJECT_KEY;

  return config;
});

axios.interceptors.response.use(
  function(res) {
    window.url = res.config.url;
    return res;
  },
  function(error) {
    if (error.response) {
      if (error.response.status == 401) {
      } else if (error.response.status == 403) {
      }
    } else {
      error.message = "response error";
    }

    return Promise.reject(error);
  }
);

const axiosEx = (options) => {
  if (options.formData) {
    const data = options.data;

    const formData = new FormData();

    for (var key in data) {
      const value = data[key];
      if (value == null) continue;
      if (value == undefined) continue;

      if (Array.isArray(value)) {
        value.forEach((v, i) => {
          formData.append(key + "[]", v);
        });
        continue;
      }
      if (value instanceof File) {
        formData.append(key, value);
        continue;
      }

      // if (typeof value === "object") continue;
      if (typeof value === "object") {
        formData.append(key, JSON.stringify(value));
        continue;
      }

      formData.append(key, value);
    }

    options.data = formData;
  }

  return axios(options)
    .then((res) => {
      return res.data;
    })
    .then((data) => {
      return { data };
    })
    .catch((error) => {
      return { error };
    });
};

export default axiosEx;
