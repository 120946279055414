import React, { FC, useEffect, useRef, useState } from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initReactI18next, useTranslation } from "react-i18next";
import { view_item } from "../../stores/Items/view";
import _more_options from "./_more_options";
import CircularProgress from "@mui/material/CircularProgress";

export default function Contents(Item) {
  const { dispatch_put_items_id_favorite } = Item;

  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef();
  const [t, i18n] = useTranslation();

  const reduce_get_accounts = useSelector((state) => state.reduce_get_accounts);
  const reduce_post_authin = useSelector((state) => state.reduce_post_authin);
  const maps = useSelector((state) => state.reduce_get_maps);

  // const collection = useSelector((state) => state.reduce_get_collections.collection);
  // const v_collection = view_collection(collection);

  useEffect(() => {}, []);

  const la = sessionStorage.getItem("AcceptLanguage");
  const token = reduce_post_authin.token;
  const mail_address = reduce_get_accounts.mail_address;

  const item = useSelector((state) => state.reduce_get_items.item);
  const v_item = view_item(item, maps, t);


  return (
    <>
      <div className="main-header container pt-3 pb-2 mb-xl-4">
        <h2 className="h4 m-0 text-truncate">{v_item.item_name}</h2>
        <div className="btn-group">
          {/* {_more_options(Item)} */}
          <button
            type="button"
            className="btn btn-white rounded-pill px-3 me-2"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title=""
            onClick={() => {
              window.location.reload();
            }}
          >
            <i className="fa-solid fa-rotate" />
          </button>

          {v_item.item_process_flg && (
            <div className="d-flex align-items-center justify-content-center bg-secondary rounded-pill px-3">
              <CircularProgress color="inherit" style={{ width: 16, height: 16 }} />
              <span className="ms-2">{t("処理中")}</span>
            </div>
          )}

          {v_item.出品する && (
            <>
              <a
                className="btn btn-outline-primary rounded-pill px-sm-3 text-nowrap"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/items/${v_item.item_id}/sell`);
                }}
              >
                <i className="fa-solid fa-cart-shopping" />
                {/* <span className="ms-1">{t("出品")}</span> */}
                <span className="ms-1 d-sm-none">{t("出品")}</span>
                <span className="ms-1 d-none d-sm-inline-block">{t("出品する")}</span>
              </a>
            </>
          )}
          {v_item.出品する && (
            <>
              <a
                className="btn btn-outline-primary rounded-pill px-sm-3 text-nowrap ms-2"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/items/${v_item.item_id}/send`);
                }}
              >
                <i className="fa-solid fa-right-left" />
                <span className="ms-1 d-sm-none">{t("転送")}</span>
                <span className="ms-1 d-none d-sm-inline-block">{t("転送する")}</span>
                {/* <span className="d-none d-sm-inline-block">する</span> */}
              </a>
            </>
          )}
          {v_item.編集する && (
            <>
              <a
                className="btn btn-outline-primary rounded-pill px-sm-3 text-nowrap"
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`/items/${v_item.item_id}/sell`);
                }}
              >
                <i className="fa-solid fa-cart-shopping" />
                {/* <span className="ms-1">{t("編集")}</span> */}
                <span className="ms-1 d-sm-none">{t("編集")}</span>
                <span className="ms-1 d-none d-sm-inline-block">{t("編集する")}</span>
                {/* <span className="d-none d-sm-inline-block">する</span> */}
              </a>
            </>
          )}
          {!v_item.item_process_flg && !v_item.item_detail_owner && (
            <button
              type="button"
              className={`btn btn-outline-dark rounded-pill text-nowrap px-3 ${v_item.item_favorited_my &&
                "active"}`}
              role="button"
              data-bs-toggle="button"
              autoComplete="off"
              aria-pressed={v_item.item_favorited_my ? "true" : "false"}
              onClick={(e) => {
                e.preventDefault();
                dispatch_put_items_id_favorite({
                  id: v_item.item_id,
                });
              }}
            >
              <i className="fa-solid fa-heart d-md-none" />
              <span className="d-none d-md-block">
                {v_item.item_favorited_my ? t("お気に入り済") : t("お気に入り")}
              </span>
            </button>
          )}
        </div>
      </div>
    </>
  );
}
