import React, { FC, useEffect, useRef, useState, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";
import { initReactI18next, useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomLoading from "components/CustomLoading/CustomLoading.js";
import { DisplayContext } from "../../Navigator/Router_material";
import { useParams, useLocation, useHistory } from "react-router-dom";
import Snackbar from "components/Snackbar/Snackbar.js";
import AddAlert from "@material-ui/icons/AddAlert";
import { getMessage, toMomentString } from "config/util";

import MainFixed from "../../components/CustomLoadingScreen/MainFixed";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";

// import LinearProgress from "./Line";

export default function Dashboard({ Items }) {
  const { propsItems, set_propsItems, dispatch_gets } = Items;
  const history = useHistory();
  const dispatch = useDispatch();

  const [snack, set_snack] = React.useState(false);
  const [message, set_message] = React.useState(false);
  const [color, set_color] = React.useState("danger");

  const toast = (error) => {
    const message = getMessage(error);
    dispatch_message(message, "danger");
  };

  const dispatch_message = (message, color) => {
    set_color(color);
    set_message(message);
    set_snack(true);
    setTimeout(function() {
      set_snack(false);
    }, 6000);
  };
  useEffect(() => {
    // dispatch_gets(propsItems.search, 1);
  }, []);
  const [t, i18n] = useTranslation();

  const theme = useTheme();
  const primary = theme.palette.primary.main;

  const count = useSelector((state) => state.reduce_get_items.count);
  const { windowHeight, tableHeight } = useContext(DisplayContext);

  const location = useLocation();
  const nonce = location.nonce;

  const [mount, set_mount] = React.useState(true);
  const [load, set_load] = useState(true);
  const ref_load = useRef(load);

  useEffect(() => {
    ref_load.current = load;
  }, [load]);

  const loading = useSelector((state) => state.reduce_get_items.isLoading);
  const datalist = useSelector((state) => state.reduce_get_items.items);

  useEffect(() => {
    if (ref_load.current) {
      set_load(loading);
    }
  }, [loading]);

  useEffect(() => {
    set_mount(false);
    set_load(true);
  }, [nonce]);

  useEffect(() => {
    set_mount(true);
  }, [mount]);

  if (!mount) {
    return <></>;
  }

  if (!datalist) {
    return <></>;
  }

  const height = tableHeight;

  return (
    <>
      {/* <MainFixed loading={loading && ref_load.current} /> */}
      <Snackbar
        place="tc"
        color={color}
        icon={AddAlert}
        message={message}
        open={snack}
        closeNotification={() => {
          set_snack(false);
        }}
        close
      />
      <div className="main-toolbar">
        <div>
          <TablePagination
            // rowsPerPageOptions={[10, 100, 1000]}
            rowsPerPageOptions={[10]}
            labelRowsPerPage={t("")}
            // labelDisplayedRows={({ from, to, count }) =>
            //   t(`${count} 件中 ${from} ~ ${to} 件表示`, count, from, to)
            // }
            // labelDisplayedRows={({ from, to, count }) =>
            //   loading ? "" : `${from} ~ ${to} of ${count}`
            // }
            component="div"
            count={count}
            page={propsItems.search.page - 1}
            onPageChange={(event, newPage) => {
              dispatch_gets(propsItems.search, newPage + 1);
            }}
            onRowsPerPageChange={(event) => {
              const search = {
                ...propsItems.search,
                limit: parseInt(event.target.value, 10),
              };
              dispatch_gets(search, 1);
            }}
            rowsPerPage={propsItems.search.limit ? propsItems.search.limit : 0}
          />
        </div>
        {/* <div className="btn-toolbar ms-auto" role="toolbar" aria-label="\u691C\u7D22\u6761\u4EF6">
          <div className="filter-only-xs me-sm-1">
            <button
              className="btn btn-outline-secondary"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#searchFilter"
              aria-controls="searchFilter"
            >
              <i className="fa-solid fa-filter" />
            </button>
          </div>
          <div
            className="dropdown me-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title=""
          >
            <button
              type="button"
              className="btn btn-outline-secondary dropdown-toggle"
              id="filSort"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fa-solid fa-arrow-down-wide-short" />
            </button>
            <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="filSort">
              <li>
                <button type="button" className="dropdown-item">
                  出品順
                </button>
              </li>
              <li>
                <button type="button" className="dropdown-item">
                  作成順
                </button>
              </li>
              <li>
                <button type="button" className="dropdown-item">
                  出品順
                </button>
              </li>
              <li>
                <button type="button" className="dropdown-item">
                  オファー順
                </button>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <button type="button" className="dropdown-item">
                  まもなく終了
                </button>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <button type="button" className="dropdown-item">
                  価格の安い順
                </button>
              </li>
              <li>
                <button type="button" className="dropdown-item">
                  価格の高い順
                </button>
              </li>
              <li>
                <button type="button" className="dropdown-item">
                  最高出品額順
                </button>
              </li>
              <li>
                <hr className="dropdown-divider" />
              </li>
              <li>
                <button type="button" className="dropdown-item">
                  閲覧数順
                </button>
              </li>
              <li>
                <button type="button" className="dropdown-item">
                  人気順
                </button>
              </li>
            </ul>
          </div>
          <button
            type="button"
            className="btn btn-outline-secondary"
            role="button"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title=""
          >
            <i className="fa-solid fa-grip-lines" />
          </button>
          {}
        </div> */}
      </div>
      {loading && (
        <div className="main-toolbar">
          <CustomLinearProgress color="primary" />
        </div>
      )}
    </>
  );
}

//  {/* <GridContainer justifyContent="flex-start"> */}
//  <GridContainer justifyContent="space-between">
//  <GridItem>
//    <TablePagination
//      // rowsPerPageOptions={[10, 100, 1000]}
//      rowsPerPageOptions={[10]}
//      labelRowsPerPage={t("")}
//      // labelDisplayedRows={({ from, to, count }) =>
//      //   t(`${count} 件中 ${from} ~ ${to} 件表示`, count, from, to)
//      // }
//      // labelDisplayedRows={({ from, to, count }) =>
//      //   loading ? "" : `${from} ~ ${to} of ${count}`
//      // }
//      component="div"
//      count={count}
//      page={propsItems.search.page - 1}
//      onPageChange={(event, newPage) => {
//        dispatch_gets(propsItems.search, newPage + 1);
//      }}
//      onRowsPerPageChange={(event) => {
//        const search = {
//          ...propsItems.search,
//          limit: parseInt(event.target.value, 10),
//        };
//        dispatch_gets(search, 1);
//      }}
//      rowsPerPage={propsItems.search.limit ? propsItems.search.limit : 0}
//    />
//  </GridItem>
//  <GridItem></GridItem>
// </GridContainer>
